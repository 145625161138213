import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { THEMES } from '../constants/themes';
import { LANG, PATH_LOCATION } from '../constants/constants';
import HangSengHeader from '../components/HangSengHeader';
import CichkHeader from './CichkHeader';
import { setLang, setLoading } from '../reducers/systemSlice';
import moment from 'moment';

import { SiteInfo } from '../components/SiteInfo';
import { GuluLogo } from '../components/GuluLogo';

function Header({
  site,
  showGuluLogo,
  showLanguageButtons,
  showSiteInfoGuluLogo = false,
  showSiteInfoLanguageButtons = false,
  showPoweredBy = false,
}) {
  const theme = useSelector((state) => state.system.theme);
  const dispatch = useDispatch();
  const params = useParams();
  const { url } = useRouteMatch();
  const history = useHistory();

  function handleLangClick(newLang) {
    dispatch(setLoading(true));
    switch (newLang) {
      case LANG.EN:
        moment.locale('en');
        dispatch(setLang(LANG.EN));
        redirectToNewLang(LANG.EN);
        break;
      case LANG.SC:
        moment.locale('zb-cn');
        redirectToNewLang(LANG.SC);
        dispatch(setLang(LANG.SC));
        break;
      default:
        moment.locale('zh-hk');
        redirectToNewLang(LANG.TC);
        dispatch(setLang(LANG.TC));
    }
  }

  const redirectToNewLang = (newLang) => {
    const newPath = getRedirectLangPath(newLang);
    history.push(newPath);
  };

  const getRedirectLangPath = (newLang) => {
    const currentLang = params.language;
    const redirectPath = url.replace(`/${currentLang}`, `/${newLang}`);
    return redirectPath;
  };

  return (
    <div className="header-wrapper">
      {theme === THEMES.HANG_SENG && (
        <HangSengHeader showLang handleLangClick={handleLangClick} />
      )}
      {theme === THEMES.CICHK && (
        <CichkHeader showLang handleLangClick={handleLangClick} />
      )}
      {theme !== THEMES.HANG_SENG && theme !== THEMES.CICHK && (
        <GuluLogo
          showPoweredBy={showPoweredBy}
          show={showGuluLogo}
          showLang={showLanguageButtons}
          handleLangClick={handleLangClick}
        />
      )}
      {site && (
        <div className="background-site-info background-site-info-head">
          <SiteInfo
            site={site}
            show={showSiteInfoGuluLogo}
            showLang={showSiteInfoLanguageButtons}
            location={PATH_LOCATION.SITE_DETAIL_MAP}
            handleLangClick={handleLangClick}
          />
        </div>
      )}
    </div>
  );
}

export default Header;
