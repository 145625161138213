import { getStringByTheme } from "../utils/utils"
import { QueueTitle } from "./QueueTitle"
import { useSelector } from 'react-redux';

export const SelectTableTypeList = ({title, tableTypeList, requestParams, handleTableTypeClick}) => {

  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  return (
    <>
      <QueueTitle title={title?title:getStringByTheme('selectTableType', lang, theme)} />
      <div className="table-size-wrapper">

        <div className="options">
          {
            tableTypeList.map((tableType, index) => {
              return <div key={tableType.refTableTypeId} className={`option ${tableType.refTableTypeId === requestParams.refTableTypeId ? 'selected' : ''}`} onClick={() => handleTableTypeClick(tableType)}>{tableType.tableTypeName}</div>
            })
          }
        </div>
      </div>
    </>
  )
}
