import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { mergeQueueConfig } from '../utils/queueUtils';
import { setQueueConfig, setRequestParams } from '../reducers/queueSlice';
import { getAvaliableSectionList } from '../utils/queueUtils';
import { getStringByTheme } from '../utils/utils';
import { QUEUE_STEPS, STATUS } from '../constants/constants';
import { PATH } from '../constants/paths';

import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';
import Header from '../components/Header';

import '../styles/queue-time-section.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';

const QueueTimeSection = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  const [timeSectionList, setTimeSectionList] = useState([]);

  useEffect(() => {
    document.title = getStringByTheme('queueTimeSection', lang, theme);
  }, [lang, theme]);

  useEffect(() => {
    if (!lang) {
      return;
    }

    if (
      !queueConfig ||
      (requestParams.selectedDate && !queueConfig.filterResult)
    ) {
      const newParams = _.cloneDeep(requestParams);
      newParams.siteId = params.siteId;
      post(
        API_URLS.QUEUE,
        newParams,
        (payload) => {
          const newConfig = mergeQueueConfig(queueConfig, payload);
          dispatch(setQueueConfig(newConfig));
        },
        undefined,
        store
      );
    }
  }, [params.siteId, dispatch, store, queueConfig, lang]);

  useEffect(() => {
    if (queueConfig && queueConfig.filterResult) {
      const avaliableSectionList = getAvaliableSectionList(
        queueConfig.steps,
        QUEUE_STEPS.TIME_SECTION,
        requestParams,
        queueConfig.filterResult
      );
      const tempMap = new Map();
      avaliableSectionList.forEach((section) => {
        if (!tempMap.has(section.timeSectionId)) {
          tempMap.set(section.timeSectionId, {
            timeSectionId: section.timeSectionId,
            timeSectionLabel: section.timeSectionLabel,
            status: section.status,
          });
        }
      });
      setTimeSectionList(
        Array.from(tempMap.values()).sort((a, b) => {
          return a.timeSectionId.localeCompare(b.timeSectionId);
        })
      );
    }
  }, [queueConfig]);

  useEffect(() => {
    if (timeSectionList.length < 1) return;
    const availableList = timeSectionList.filter(
      (item) => item.status === STATUS.ACTIVE
    );
    if (availableList?.length === 1 && availableList[0].hasQuota && !availableList[0].readOnly) {
      if (availableList[0].timeSectionId !== requestParams.timeSectionId)
        handleTimeSection(availableList[0].timeSectionId, STATUS.ACTIVE);
    }
  }, [timeSectionList]);

  function handleTimeSection(timeSectionId, status) {
    if (status === STATUS.ACTIVE) {
      const newParams = _.cloneDeep(requestParams);
      newParams.timeSectionId = timeSectionId;
      dispatch(setRequestParams(newParams));
    }
  }

  function handleSiteDetailButtonClick() {
    history.push(`/${lang}` + PATH.SITE_DETAIL + params.siteId);
  }

  const getLabelByTheme = () => {
    return getStringByTheme('selectTimeSection', lang, theme);
  };

  const getLabelPrefix = () => {
    return '';
  };

  const getTimeSectionLabelByTheme = (string) => {
    return string.replace(
      '%replace%',
      getStringByTheme('timeSection', lang, theme)
    );
  };

  return (
    <LanguageWrapper>
      {queueConfig && (
        <>
          <div className="queue-wrapper">
            <Header site={queueConfig.siteInfo} showSiteInfoGuluLogo={false} />
            <div className="queue-wrapper-content queue-time-section-wrapper">
              {timeSectionList?.length > 0 ? (
                <>
                  <QueueTitle title={getLabelByTheme()} />
                  {timeSectionList.map((timeSection, index) => {
                    const disabled = timeSection.status !== STATUS.ACTIVE;
                    return (
                      <div
                        key={index}
                        className={
                          'time-section-radio-wrapper' +
                          (disabled ? ' disable' : '')
                        }
                        onClick={() =>
                          handleTimeSection(
                            timeSection.timeSectionId,
                            timeSection.status
                          )
                        }
                      >
                        <input
                          type="radio"
                          id={timeSection.timeSectionId}
                          name="timeSectionId"
                          checked={
                            timeSection.timeSectionId ===
                            requestParams.timeSectionId
                          }
                          readOnly
                          disabled={disabled}
                        />
                        <div>
                          {getLabelPrefix()}
                          {timeSection.timeSectionLabel}
                        </div>
                        {disabled && (
                          <div className="disabled-label">
                            {getStringByTheme('disabledLabel', lang, theme)}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="queue-title">
                  {`${getTimeSectionLabelByTheme(
                    getStringByTheme('currentNoAvailable', lang, theme)
                  )}`}
                </div>
              )}
            </div>
            <Header showGuluLogo />
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.TIME_SECTION} />
        </>
      )}
      {!queueConfig && (
        <div className="queue-single-button-wrapper">
          <div className="single-button" onClick={handleSiteDetailButtonClick}>
            {getStringByTheme('returnToSiteDetail', lang, theme)}
          </div>
        </div>
      )}
    </LanguageWrapper>
  );
};

export default QueueTimeSection;
