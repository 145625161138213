import { createSlice } from '@reduxjs/toolkit'

export const pickupSlice = createSlice({
  name: 'pickup',
  initialState: {
    pickup: [],
    image: '',
    latestTag: ''
  },
  reducers: {
    setPickup(state, action) {
      state.pickup = action.payload;
    },
    setImage(state, action) {
      state.image = action.payload;
    },
    setLatestTag(state, action) {
      state.latestTag = action.payload;
    },
  }
})

export const { setPickup, setImage, setLatestTag } = pickupSlice.actions

export default pickupSlice.reducer