import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';

import { setRequestParams } from '../reducers/queueSlice';
import { getAvaliableSectionList } from '../utils/queueUtils';
import {
  tableColor,
  getStringByTheme,
  showElementByTheme,
} from '../utils/utils';
import { QUEUE_STEPS, ELEMENT, DEFAULT_TABLE_TYPE_COLOR } from '../constants/constants';
import { PATH } from '../constants/paths';

import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';
import { PreviewEditBtn } from '../components/PreviewEditBtn';
import Header from '../components/Header';
import { ReactComponent as CheckIcon } from '../images/check.svg';

import '../styles/preview.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';

const QueuePreview = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  const [avaliableSectionList, setAvaliableSectionList] = useState([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    document.title = getStringByTheme('preview', lang, theme);
  }, [lang]);

  useEffect(() => {
    if (queueConfig && queueConfig.filterResult) {
      const tempAvaliableSectionList = getAvaliableSectionList(
        queueConfig.steps,
        QUEUE_STEPS.PREVIEW,
        requestParams,
        queueConfig.filterResult
      );
      if (tempAvaliableSectionList && tempAvaliableSectionList.length === 1) {
        setAvaliableSectionList({...tempAvaliableSectionList[0], tableTypeForegroundColor: tempAvaliableSectionList[0].tableTypeForegroundColor || DEFAULT_TABLE_TYPE_COLOR});
        setReady(true);
      }
    }
  }, [queueConfig]);

  function handleTncClick() {
    const newParams = _.cloneDeep(requestParams);
    newParams.ticketTncAgreed = !newParams.ticketTncAgreed;
    dispatch(setRequestParams(newParams));
  }

  function handlePersonalInfoChange() {
    const newParams = _.cloneDeep(requestParams);
    newParams.personalData.tncAgreed = !newParams.personalData.tncAgreed;
    dispatch(setRequestParams(newParams));
  }

  function handleSiteDetailButtonClick() {
    history.push(`/${lang}` + PATH.SITE_DETAIL + params.siteId);
  }

  const getTableTypeLabelByTheme = () => {
    return getStringByTheme('tableType', lang, theme);
  };

  const getTimeSectionLabelByTheme = () => {
    return getStringByTheme('timeSection', lang, theme);
  };

  const getLabelPrefix = () => {
    return getStringByTheme('activity', lang, theme);
  };

  const getTimeSectionLabelPrefix = () => {
    return '';
  };

  return (
    <LanguageWrapper>
      {ready && (
        <>
          <div className="queue-wrapper">
            <Header site={queueConfig.siteInfo} showSiteInfoGuluLogo={false} />
            <div className="queue-wrapper-content preview-wrapper">
              <QueueTitle
                title={getStringByTheme('selectPreview', lang, theme)}
              />
              <div className="preview-content">
                {queueConfig.steps.map((preview, index) => {
                  let content = <></>;
                  switch (preview) {
                    case QUEUE_STEPS.SIZE:
                      content = (
                        <div key={index} className="step-info">
                          <div className="preview-title">
                            {getStringByTheme('tableSize', lang, theme)}
                          </div>
                          <div className="preview-text">
                            <div className="table-name">
                              {/* <div className={`text-${tableColor(avaliableSectionList.tableType)} background-dark-${tableColor(avaliableSectionList.tableType)} background`}>{avaliableSectionList.tableTypeLabel ? '' : avaliableSectionList.tableType}</div> */}
                              <div
                                className={`text-${tableColor(
                                  avaliableSectionList.tableType
                                )}`}
                                style={{color: avaliableSectionList.tableTypeForegroundColor}}
                              >
                                {requestParams.size}
                              </div>
                            </div>
                            <PreviewEditBtn editStep={preview} />
                          </div>
                        </div>
                      );
                      break;
                    case QUEUE_STEPS.TABLE_TYPE:
                      content = (
                        <div key={index} className="step-info">
                          <div className="preview-title">
                            {getTableTypeLabelByTheme()}
                          </div>
                          <div className="preview-text">
                            <div className="table-name">
                              <div
                                className={`text-${avaliableSectionList.tableType}`}
                                style={{color: avaliableSectionList.tableTypeForegroundColor}}
                              >
                                {getLabelPrefix()}
                                {avaliableSectionList.tableTypeLabel
                                  ? avaliableSectionList.tableTypeLabel
                                  : avaliableSectionList.tableType}
                              </div>
                            </div>
                            <PreviewEditBtn editStep={preview} />
                          </div>
                        </div>
                      );
                      break;
                    case QUEUE_STEPS.DATE:
                      content = (
                        <div key={index} className="step-info">
                          <div className="preview-title">
                            {getStringByTheme('date', lang, theme)}
                          </div>
                          <div className="preview-text">
                            {requestParams.selectedDate}
                            <PreviewEditBtn editStep={preview} />
                          </div>
                        </div>
                      );
                      break;
                    case QUEUE_STEPS.TIME_SECTION:
                      content = (
                        <div key={index} className="step-info">
                          <div className="preview-title">
                            {getTimeSectionLabelByTheme()}
                          </div>
                          <div className="preview-text">
                            {getTimeSectionLabelPrefix()}
                            {avaliableSectionList.timeSectionLabel}
                            <PreviewEditBtn editStep={preview} />
                          </div>
                        </div>
                      );
                      break;
                    case QUEUE_STEPS.TICKET_TYPE:
                      content = (
                        <div key={index} className="step-info">
                          <div className="preview-title">
                            {getStringByTheme('ticketType', lang, theme)}
                          </div>
                          <div className="preview-text">
                            {avaliableSectionList.ticketTypeLabel}
                            <PreviewEditBtn editStep={preview} />
                          </div>
                        </div>
                      );
                      break;
                    case QUEUE_STEPS.TAGS:
                      content = (
                        <div key={index} className="step-info">
                          <div className="preview-title">
                            {getStringByTheme('tags', lang, theme)}
                          </div>
                          <div className="preview-text">
                            <div>
                              {requestParams.tags.length > 0
                                ? requestParams.tags.forEach((item) => {
                                    return <div>{item}</div>;
                                  })
                                : getStringByTheme('noTag', lang, theme)}
                            </div>
                            <PreviewEditBtn editStep={preview} />
                          </div>
                        </div>
                      );
                      break;
                    case QUEUE_STEPS.PERSONAL_DATA:
                      content = (
                        <div key={index} className="step-info">
                          <div className="preview-title">
                            {getStringByTheme('personalData', lang, theme)}
                          </div>
                          <div className="personal-data-wrapper">
                            <div className="personal-data-contant">
                              {requestParams.personalData.email && (
                                <div className="preview-text">
                                  {requestParams.personalData.email}
                                </div>
                              )}
                              {requestParams.personalData.mobile &&
                                requestParams.personalData.countryCode && (
                                  <div className="personal-data-phone">
                                    <div className="preview-text">
                                      {'+' +
                                        requestParams.personalData.countryCode}
                                    </div>
                                    <div className="preview-text phone">
                                      {requestParams.personalData.mobile}
                                    </div>
                                  </div>
                                )}
                            </div>
                            <PreviewEditBtn editStep={preview} />
                          </div>
                        </div>
                      );
                      break;
                    default:
                      content = <div key={index}></div>;
                  }
                  return content;
                })}
                <div className="tnc-wrapper">
                  <QueueTitle title={getStringByTheme('tnc', lang, theme)} />
                  <div
                    className="tnc-content"
                    dangerouslySetInnerHTML={{
                      __html: queueConfig.termsAndConditions,
                    }}
                  ></div>
                  <div className="tnc-title" onClick={handleTncClick}>
                    <div className="checkbox-wrapper">
                      {requestParams.ticketTncAgreed && (
                        <CheckIcon className="check-icon" />
                      )}
                    </div>
                    {getStringByTheme('agree', lang, theme) +
                      getStringByTheme('tnc', lang, theme)}
                  </div>
                </div>
                {showElementByTheme(ELEMENT.PROMOTION_TNC, theme) && (
                  <div
                    className="personal-data"
                    onClick={handlePersonalInfoChange}
                  >
                    <div className="checkbox-wrapper">
                      {requestParams.personalData.tncAgreed && (
                        <CheckIcon className="check-icon" />
                      )}
                    </div>
                    {getStringByTheme('personalInfoTnc', lang, theme)}
                  </div>
                )}
              </div>
            </div>
            <Header showGuluLogo />
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.PREVIEW} />
        </>
      )}
      {!ready && (
        <div className="queue-single-button-wrapper">
          <div className="single-button" onClick={handleSiteDetailButtonClick}>
            {getStringByTheme('returnToSiteDetail', lang, theme)}
          </div>
        </div>
      )}
    </LanguageWrapper>
  );
};

export default QueuePreview;
