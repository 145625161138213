import React from 'react';
import '../styles/banner-list.scss';

export const BannerList = ({ bannerList, displayUnderLine = false }) => {

  return (
    <>
      {
        bannerList && bannerList.length > 0 &&
        <>
          <div className="banner-list">
            {
              bannerList.map((banner, index) => {
                return (
                  <img key={index} className="detail-image" src={banner} alt="banner" />
                )
              })
            }
          </div>
          {
            displayUnderLine &&
            <div className="queue-title-underline" />
          }
        </>
      }
    </>
  )
};
