import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';

import { ReactComponent as ArrowButtonIcon } from '../images/arrow-button.svg';
import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { setRequestTicketSuccessfully } from '../reducers/queueSlice';
import { getQueuePageByStep } from '../utils/queueUtils';
import { PATH } from '../constants/paths';
import { getAvaliableSectionList } from '../utils/queueUtils';
import { getStringByTheme, validateEmail, validateMobile } from '../utils/utils';

import '../styles/queue-bottom-btn.scss';
import {
  QUEUE_STEPS,
  DELIVERY_TYPE
} from '../constants/constants';
import { setMessage } from '../reducers/systemSlice';

export const QueueBottomBtn = ({ currentStep, defaultValid = undefined }) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useStore();
  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const requestTicketSuccessfully = useSelector(
    (state) => state.queue.requestTicketSuccessfully
  );
  const token = useSelector((state) => state.system.token);
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  const [stepIndex, setStepIndex] = useState(-1);
  const [valid, setValid] = useState(
    defaultValid !== undefined ? defaultValid : false
  );

  useEffect(() => {
    const index = queueConfig.steps.findIndex((step) => {
      return step === currentStep;
    });
    setStepIndex(index);
  }, [queueConfig.steps, currentStep]);

  useEffect(() => {
    let tempValid = false;
    switch (currentStep) {
      case QUEUE_STEPS.DATE:
        if (requestParams.selectedDate) {
          tempValid = true;
        }
        break;
      case QUEUE_STEPS.SIZE:
        if (requestParams.size) {
          tempValid = true;
        }
        break;
      case QUEUE_STEPS.TABLE_TYPE:
        if (requestParams.tableType) {
          tempValid = true;
        }
        break;
      case QUEUE_STEPS.TIME_SECTION:
        if (requestParams.timeSectionId) {
          tempValid = true;
        }
        break;
      case QUEUE_STEPS.PERSONAL_DATA:
        switch (queueConfig.deliveryType) {
          case DELIVERY_TYPE.EMAIL:
            if (validateEmail(requestParams.personalData.email)) {
              tempValid = true;
            }
            break;
          case DELIVERY_TYPE.SMS:
            if (validateMobile(requestParams.personalData.mobile)) {
              tempValid = true;
            }
            break;
          case DELIVERY_TYPE.WHATSAPP:
            if (requestParams.personalData.mobile) {
              tempValid = true;
            }
            break;
          default:
            tempValid = true;
        }
        break;
      case QUEUE_STEPS.TICKET_TYPE:
        if (requestParams.ticketType) {
          tempValid = true;
        }
        break;
      case QUEUE_STEPS.PREVIEW:
        tempValid = requestParams.ticketTncAgreed;
        break;
      default:
    }
    setValid(tempValid);
  }, [requestParams, currentStep, queueConfig.personalDataRequired]);

  function handlePreviousClick() {
    history.goBack();
  }

  function handleNextClick() {
    if (valid) {
      if (stepIndex !== -1 && stepIndex < queueConfig.steps.length - 1) {
        const nextStep = queueConfig.steps[stepIndex + 1];
        const newParams = _.cloneDeep(requestParams);
        newParams.nextStep = nextStep;
        history.push('/' + params.language + getQueuePageByStep(nextStep) + params.siteId);
      } else if (stepIndex !== -1 && stepIndex === queueConfig.steps.length - 1) {
        if (requestTicketSuccessfully) {
          switch (queueConfig.deliveryType) {
            case DELIVERY_TYPE.EMAIL:
              dispatch(
                setMessage(
                  getStringByTheme('requestedTicket', lang, theme).replace(
                    '%deliveryType%',
                    getStringByTheme('email', lang, theme).toLowerCase()
                  )
                )
              );
              break;
            case DELIVERY_TYPE.SMS:
              dispatch(
                setMessage(
                  getStringByTheme('requestedTicket', lang, theme).replace(
                    '%deliveryType%',
                    getStringByTheme('sms', lang, theme)
                  )
                )
              );
              break;
            default:
              dispatch(
                setMessage(getStringByTheme('requestedTicketNone', lang, theme))
              );
            // history.push(PATH.TICKET + payload.id);
          }
        } else {
          const newParams = _.cloneDeep(requestParams);
          newParams.siteId = params.siteId;
          newParams.email = newParams.personalData.email;
          newParams.countryCode = newParams.personalData.countryCode;
          newParams.mobile = newParams.personalData.mobile;
          const sectionList = getAvaliableSectionList(
            queueConfig.steps,
            undefined,
            newParams,
            queueConfig.filterResult
          );
          if (sectionList && sectionList.length === 1) {
            if (!newParams.size) {
              newParams.size = sectionList[0].minSize;
            }
            if (!newParams.tableType) {
              newParams.tableType = sectionList[0].tableType;
            }
            if (!newParams.timeSectionId) {
              newParams.timeSectionId = sectionList[0].timeSectionId;
            }
            if (!newParams.ticketType && sectionList[0].ticketType !== null) {
              newParams.ticketType = sectionList[0].ticketType;
            }
            newParams.timeSectionUUId = sectionList[0].id;
            newParams.deliveryType = queueConfig.deliveryType;
            post(
              API_URLS.REQUEST_TICKET,
              newParams,
              (payload) => {
                dispatch(setRequestTicketSuccessfully(true));
                const { createTimestamp, ticket } = payload;
                if (token) {
                  window.location =
                    'thegulu://myticket?serviceType=QUEUE&refId=' + ticket.id;
                } else {
                  switch (queueConfig.deliveryType) {
                    case DELIVERY_TYPE.EMAIL:
                    case DELIVERY_TYPE.SMS:
                  case DELIVERY_TYPE.WHATSAPP:
                      history.push('/' + lang + 
                        PATH.QUEUE_REQUEST_TICKET_SUCCESS + params.siteId
                      );
                      break;
                    default:
                      history.push(`/${lang}` + PATH.TICKET + ticket.id);
                  }
                }
              },
              undefined,
              store
            );
          }
        }
      }
    }
  }

  function generateNextButton() {
    switch (theme) {
      default:
        return <ArrowButtonIcon className="arrow next" />;
    }
  }
  return (
    <div className="queue-bottom-btn-wrapper">
      {stepIndex > 0 && (
        <div onClick={handlePreviousClick} className="queue-button previous">
          <ArrowButtonIcon className="arrow prev" />
        </div>
      )}
      <div
        onClick={handleNextClick}
        className={'queue-button next' + (!valid ? ' disable' : '')}
      >
        {generateNextButton()}
      </div>
    </div>
  );
};
