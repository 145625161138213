import { createSlice } from '@reduxjs/toolkit';

import { RESERVATION_REQUEST_PARAMS } from '../constants/initialStates';

export const reservationSlice = createSlice({
  name: 'reservation',
  initialState: {
    reservationConfig: undefined,
    requestParams: RESERVATION_REQUEST_PARAMS,
    requestReservationSuccessfully: false,
  },
  reducers: {
    setReservationConfig(state, action) {
      state.reservationConfig = action.payload;
    },
    setRequestParams(state, action) {
      state.requestParams = action.payload;
    },
    setRequestReservationSuccessfully(state, action) {
      state.requestReservationSuccessfully = action.payload;
    }
  }
})

export const { setSiteId, setReservationConfig, setRequestParams, setRequestReservationSuccessfully } = reservationSlice.actions

export default reservationSlice.reducer