import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { mergeQueueConfig } from '../utils/queueUtils';
import { setQueueConfig, setRequestParams } from '../reducers/queueSlice';
import { getAvaliableSectionList } from '../utils/queueUtils';
import { getStringByTheme } from '../utils/utils';
import { QUEUE_STEPS } from '../constants/constants';
import { PATH } from '../constants/paths';

import Header from '../components/Header';
import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';

import '../styles/queue-ticket-type.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';

const QueueTicketType = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  const [ticketTypeList, setTicketTypeList] = useState([]);

  useEffect(() => {
    document.title = getStringByTheme('queueTicketType', lang, theme);
  }, [lang]);

  useEffect(() => {
    if (!lang) {
      return;
    }

    if (
      !queueConfig ||
      (requestParams.selectedDate && !queueConfig.filterResult)
    ) {
      const newParams = _.cloneDeep(requestParams);
      newParams.siteId = params.siteId;
      post(
        API_URLS.QUEUE,
        newParams,
        (payload) => {
          const newConfig = mergeQueueConfig(queueConfig, payload);
          dispatch(setQueueConfig(newConfig));
        },
        undefined,
        store
      );
    }
  }, [params.siteId, dispatch, store, queueConfig, lang]);

  useEffect(() => {
    if (queueConfig && queueConfig.filterResult) {
      const avaliableSectionList = getAvaliableSectionList(
        queueConfig.steps,
        QUEUE_STEPS.TICKET_TYPE,
        requestParams,
        queueConfig.filterResult
      );
      const tempMap = new Map();
      avaliableSectionList.forEach((section) => {
        if (!tempMap.has(section.ticketType)) {
          tempMap.set(section.ticketType, {
            ticketType: section.ticketType,
            ticketTypeLabel: section.ticketTypeLabel,
          });
        }
      });
      setTicketTypeList(Array.from(tempMap.values()));
    }
  }, [queueConfig, requestParams]);

  function handleTicketType(ticketType) {
    const newParams = _.cloneDeep(requestParams);
    newParams.ticketType = ticketType;
    dispatch(setRequestParams(newParams));
  }

  function handleSiteDetailButtonClick() {
    history.push(`/${lang}` + PATH.SITE_DETAIL + params.siteId);
  }

  return (
    <LanguageWrapper>
      {queueConfig && (
        <>
          <div className="queue-wrapper">
            <Header site={queueConfig.siteInfo} showSiteInfoGuluLogo={false} />
            <div className="queue-wrapper-content queue-ticket-type-wrapper">
              <QueueTitle
                title={getStringByTheme('selectTicketType', lang, theme)}
              />
              {ticketTypeList.map((ticketType, index) => {
                return (
                  <div
                    key={index}
                    className="ticket-type-radio-wrapper"
                    onClick={() => handleTicketType(ticketType.ticketType)}
                  >
                    <input
                      type="radio"
                      id={ticketType.ticketType}
                      name="ticketType"
                      checked={
                        ticketType.ticketType === requestParams.ticketType
                      }
                    />
                    <div>{ticketType.ticketTypeLabel}</div>
                  </div>
                );
              })}
            </div>
            <Header showGuluLogo />
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.TICKET_TYPE} />
        </>
      )}
      {!queueConfig && (
        <div className="queue-single-button-wrapper">
          <div className="single-button" onClick={handleSiteDetailButtonClick}>
            {getStringByTheme('returnToSiteDetail', lang, theme)}
          </div>
        </div>
      )}
    </LanguageWrapper>
  );
};

export default QueueTicketType;
