export const STRINGS = {
  TC: {
    // page titles
    pickupNumber: '取餐號碼',
    queueTableSize: '請選擇人數',
    queueTimeSection: '請選擇時段',
    queueDate: '請選擇日期',
    preview: '確認資料',
    queuePersonalInfo: '填寫個人資料',
    queueServiceType: '請選擇服務',
    queueTicketType: '請選擇類型',
    queueSpecialRequest: '請選擇特別安排',
    queueRequestTicketSuccess: '取票成功',
    siteTitle: '活動',
    ticketTitle: '票號詳情',
    siteListTitle: '活動列表',
    // reservation
    reservationDate: '請選擇日期及時間',
    reserve: '預約',
    // others
    selectSite: '選擇活動',
    requestTicket: '立即取票',
    serverError: '未能完成請求，請稍後再試',
    selectTableSize: '請選擇人數',
    selectTableType: '請選擇選項',
    selectTimeSection: '請選擇時段',
    selectServiceType: '請選擇服務',
    selectTicketType: '請選擇類型',
    selectDate: '請選擇日期',
    selectTime: '請選擇時間',
    selectSpecialRequest: '請選擇特別安排',
    selectPersonalInfo: '填寫個人資料',
    selectPreview: '資料',
    tableSize: '人數',
    tableType: '服務',
    timeSection: '時段',
    date: '日期',
    time: '時間',
    ticketType: '類型',
    tags: '特別安排',
    noTag: '沒選擇',
    personalData: '個人資料',
    agree: '同意',
    agreeTnc: '同意條款及細則',
    tnc: '條款及細則',
    emailTitle: '電郵地址*',
    email: '電郵地址',
    emailError: '*您輸入的電郵地址格式錯誤！',
    phoneTitle: '手機號碼*',
    phone: '手機號碼',
    phoneError: '*您輸入的手機號碼格式錯誤！',
    surnameTitle: '姓氏*',
    surname: '姓氏',
    surnameError: '*請輸入你的姓氏',
    mr: '先生',
    ms: '小姐',
    mrs: '太太',
    personTitle: '稱謂',
    personalInfoTnc: '同意透過個人資料接收優惠並作推廣用途。',
    invalidUrl: '沒有此頁面',
    success: '您已取票成功! ',
    sent: '確認資料已發至 ',
    ok: '確定',
    confirm: '確認',
    cancel: '取消',
    langButton: '繁',
    requestedTicket: '已經成功取票，請查看%deliveryType%',
    requestedTicketNone: '已成功取票',
    requestedReservation: '已經成功預約，請查看%deliveryType%',
    requestedReservationNone: '已成功預約',
    sms: '短訊',
    disabledLabel: '滿',
    redeemed: '已使用',
    deleted: '已刪除',
    returnToSiteDetail: '返回主頁',
    smsReminder: '請留意短訊。',
    whatsappReminder: '請留意WhatsApp訊息。',
    currentTagSequence: '召集中',
    expand: '展開',
    collapse: '收起',
    preRegistration: '網上預約',
    mobileRequestTicket: '即時手機取票',
    ticketSequence: '現時取票號碼',
    dailyQuota: '本日限額:',
    queueServiceTempUnavailable: '現時該排隊服務 : 沒有開啟',
    quotaRatio: '已派/限額:',
    updateTime: '更新時間:',
    emailSuccess: '電郵已成功發送!',
    reminderForTicket: '請截圖以保存此電子飛，或透過系統發送至您的電郵地址',
    screenShotReminder: '請截圖以保存此電子飛',
    emailDeliveryReminder: '透過系統發送至您的電郵地址：',
    ticketsLimited: '每小時取票上限為10張',
    ticketPopUpConfirm: '明白了',
    currentNoAvailable: '暫時沒有可取票的%replace%',
    subscribeViaWhatsapp: '接收WhatsApp叫號通知',
    whatsappSubscription: '接收叫號通知',
    callTimestamp: '叫號時間',
    select: '請選擇',
    requiredSelectSize: '請先選擇人數',
    requiredTableType: '請先選擇',
    requiredSelectDate: '請先選擇日期',
    noTimeOption: '暫時沒有可選擇的時間',
    characters: '字',
    surveyError: '請輸入正確資料格式',
    queueTagCall: '現時叫號',
    subscribeViaEmail: '接收電郵叫號通知',
    subscribeViaSms: '接收SMS叫號通知',
    subscribed: '已成功登記叫號通知',
    otpTitle: '驗證碼',
    sendOtp: '發送驗證碼',
    resendOtp: '重發',
    otpError: '驗證碼不正確',
    deleteReservationConfirmation: '確認要刪除此預約?',
    updateReservationDate: '修改預約時間',
    confirmEdit: '確認',
    reservedAlready: '你已有此活動的有效預約',
    navigateToTicketDetailPage: '查看票號詳情',
    pleaseGoTo: '請到',
    called: '已叫號',
    waiting: '等待進入',
    wait: '等待',
    record: '記錄',
    checkedOut: '已完成',
    checkedIn: '已到達',
    editReservationSuccess: '修改成功',
    readOnly: '唯讀',
    fullQuota: '已滿',
  },
  EN: {
    // page titles
    pickupNumber: 'Pickup number',
    queueTableSize: 'Select number of people',
    queueTimeSection: 'Select time section',
    queueDate: 'Select date',
    preview: 'Confirmation',
    queuePersonalInfo: 'Fill in personal info',
    queueServiceType: 'Select service',
    queueTicketType: 'Select type',
    queueSpecialRequest: 'Select special request',
    queueRequestTicketSuccess: 'Get ticket success',
    siteTitle: 'Event',
    ticketTitle: 'Ticket',
    siteListTitle: 'Event List',
    // reservation
    reservationDate: 'Select date & time',
    reserve: 'Reserve',
    // others
    selectSite: 'Select Event',
    requestTicket: 'Get Ticket',
    serverError: 'Server busy, please try again later',
    selectTableSize: 'Select Number of People',
    selectTableType: 'Select an Item',
    selectTimeSection: 'Select Time Section',
    selectServiceType: 'Select Service',
    selectDate: 'Select Date',
    selectTime: 'Select Time',
    selectSpecialRequest: 'Select Special Request',
    selectPersonalInfo: 'Fill in Personal Info',
    selectPreview: 'Confirmation',
    tableSize: 'Number of People',
    tableType: 'Service',
    timeSection: 'Time Section',
    date: 'Date',
    time: 'Time',
    ticketType: 'Type',
    tags: 'Special Request',
    noTag: 'N/A',
    personalData: 'Personal Info',
    agree: 'Agree the ',
    agreeTnc: 'Agree the terms & conditions',
    tnc: 'Terms & Conditions',
    emailTitle: 'Email*',
    email: 'Email',
    emailError: 'You have entered an invalid email!',
    phoneTitle: 'Mobile number*',
    phone: 'Mobile number',
    phoneError: 'You have entered an invalid mobile number!',
    surnameTitle: 'Surname*',
    surname: 'Surname',
    surnameError: '*Please fill in your surname!',
    mr: 'Mr.',
    ms: 'Ms.',
    mrs: 'Mrs.',
    personTitle: 'Title',
    personalInfoTnc: 'Agree to receive promotion via the personal info',
    invalidUrl: 'Page not found',
    success: 'Get ticket successfully! ',
    sent: 'Confirmation is sent to ',
    ok: 'OK',
    confirm: 'Confirm',
    cancel: 'Cancel',
    langButton: 'ENG',
    requestedTicket:
      'You have got ticket successfully. Please check %deliveryType%',
    requestedTicketNone: 'You have already got the ticket.',
    requestedReservation:
      'You have made a reservation successfully. Please check %deliveryType%',
    requestedReservationNone: 'You have made a reservation successfully.',
    sms: 'SMS',
    disabledLabel: 'Full',
    redeemed: 'Checked in',
    deleted: 'Deleted',
    returnToSiteDetail: 'Return to Home page',
    smsReminder: 'Please check SMS',
    whatsappReminder: 'Please check WhatsApp message',
    currentTagSequence: 'Calling Now',
    expand: 'Expand',
    collapse: 'Collapse',
    preRegistration: 'ONLINE BOOKING',
    mobileRequestTicket: 'GET A TICKET',
    ticketSequence: 'Tickets Distributed',
    dailyQuota: "Today's quota:",
    queueServiceTempUnavailable: 'Current queue status : not in service',
    quotaRatio: 'Distributed / Quota:',
    updateTime: 'Update Time:',
    emailSuccess: 'Email Sent!',
    reminderForTicket:
      'Please take a screenshot to save this E-ticket, or send it to your email address',
    screenShotReminder: 'Please take a screenshot to save this E-ticket',
    emailDeliveryReminder: 'or send it to your email address:',
    ticketsLimited: 'You can only request for 10 tickets per hour',
    ticketPopUpConfirm: 'OK',
    currentNoAvailable: 'No Available %replace%s Currently',
    subscribeViaWhatsapp: 'Receive WhatsApp reminder message',
    whatsappSubscription: 'Subscribe to ticket',
    callTimestamp: 'Call Time',
    select: 'Please select',
    requiredSelectSize: 'Number of People is required',
    requiredTableType: 'Please choose ',
    requiredSelectDate: 'Date is required',
    noTimeOption: 'Currently no available time',
    characters: 'characters',
    surveyError: 'Please fill in correct information format',
    queueTagCall: 'Now Serving',
    subscribeViaEmail: 'Receive email reminder message',
    subscribeViaSms: 'Receive SMS reminder message',
    subscribed: 'Registered reminder message successfully',
    otpTitle: 'One-time password',
    sendOtp: 'Send OTP',
    resendOtp: 'Resend',
    otpError: 'One-time password invalid',
    deleteReservationConfirmation: 'Confirm to delete the reservation?',
    updateReservationDate: 'Edit reservation date',
    confirmEdit: 'Confirm',
    reservedAlready: 'You have a valid reservation of the event',
    navigateToTicketDetailPage: 'View ticket detail',
    pleaseGoTo: 'Please Go To ',
    called: 'Called',
    waiting: 'Waiting',
    wait: 'Wait for',
    record: 'Record',
    checkedOut: 'Checked Out',
    checkIned: 'Checked In',
    editReservationSuccess: 'Edit Successfully',
    readOnly: 'Read Only',
    fullQuota: 'Full',
  },
  SC: {
    // page titles
    pickupNumber: '取餐号码',
    queueTableSize: '请选择人数',
    queueTimeSection: '请选择时段',
    queueDate: '请选择日期',
    preview: '确认资料',
    queuePersonalInfo: '填写个人资料',
    queueServiceType: '请选择服务',
    queueTicketType: '请选择类型',
    queueSpecialRequest: '请选择特别安排',
    queueRequestTicketSuccess: '取票成功',
    siteTitle: '活动',
    ticketTitle: '票号详情',
    siteListTitle: '活动列表',
    // reservation
    reservationDate: '请选择日期及时间',
    reserve: '预约',
    // others
    selectSite: '选择活动',
    requestTicket: '立即取票',
    serverError: '未能完成请求，请稍后再试',
    selectTableSize: '请选择人数',
    selectTableType: '请选择选项',
    selectTimeSection: '请选择时段',
    selectServiceType: '请选择服务',
    selectTicketType: '请选择类型',
    selectDate: '请选择日期',
    selectTime: '请选择时间',
    selectSpecialRequest: '请选择特别安排',
    selectPersonalInfo: '填写个人资料',
    selectPreview: '资料',
    tableSize: '人数',
    tableType: '服务',
    timeSection: '时段',
    date: '日期',
    time: '时间',
    ticketType: '类型',
    tags: '特别安排',
    noTag: '没选择',
    personalData: '个人资料',
    agree: '同意',
    agreeTnc: '同意条款及细则',
    tnc: '条款及细则',
    emailTitle: '电邮地址*',
    email: '电邮地址',
    emailError: '*您输入的电邮地址格式错误！',
    phoneTitle: '手机号码*',
    phone: '手机号码',
    phoneError: '*您输入的手机号码格式错误！',
    surnameTitle: '姓氏*',
    surname: '姓氏',
    surnameError: '*请输入你的姓氏',
    mr: '先生',
    ms: '小姐',
    mrs: '太太',
    personTitle: '称谓',
    personalInfoTnc: '同意透过个人资料接收优惠并作推广用途。',
    invalidUrl: '没有此页面',
    success: '您已取票成功! ',
    sent: '确认资料已发至 ',
    ok: '确定',
    confirm: '确认',
    cancel: '取消',
    langButton: '简',
    requestedTicket: '已经成功取票，请查看%deliveryType%',
    requestedTicketNone: '已成功取票',
    requestedReservation: '已经成功预约，请查看%deliveryType%',
    requestedReservationNone: '已成功预约',
    sms: '短讯',
    disabledLabel: '满',
    redeemed: '已使用',
    deleted: '已刪除',
    returnToSiteDetail: '返回主页',
    smsReminder: '请留意短讯。',
    whatsappReminder: '请留意WhatsApp讯息。',
    currentTagSequence: '召集中',
    expand: '展开',
    collapse: '收起',
    preRegistration: '网上预约',
    mobileRequestTicket: '即时手机取票',
    ticketSequence: '现时取票号码',
    dailyQuota: '本日限额:',
    queueServiceTempUnavailable: '现时该排队服务 : 没有开启',
    quotaRatio: '已派/限额:',
    updateTime: '更新时间:',
    emailSuccess: '电邮发送成功!',
    reminderForTicket: '请截图以保存此电子飞，或透过系统发送至您的电邮地址',
    screenShotReminder: '请截图以保存此电子飞',
    emailDeliveryReminder: '透过系统发送至您的电邮地址：',
    ticketsLimited: '每小时取票上限为10张',
    ticketPopUpConfirm: '明白了',
    currentNoAvailable: '暂时没有可取票的%replace%',
    subscribeViaWhatsapp: '接收WhatsApp叫号通知',
    whatsappSubscription: '接收叫号通知',
    callTimestamp: '叫号时间',
    select: '请选择',
    requiredSelectSize: '请先选择人数',
    requiredTableType: '请先选择',
    requiredSelectDate: '请先选择日期',
    noTimeOption: '暂时没有可选择的时间',
    characters: '字',
    surveyError: '请输入正确资料格式',
    queueTagCall: '现时叫号',
    subscribeViaEmail: '接收电邮叫号通知',
    subscribeViaSms: '接收SMS叫号通知',
    subscribed: '已成功登记叫号通知',
    otpTitle: '验证码',
    sendOtp: '发送验证码',
    resendOtp: '重发',
    otpError: '验证码不正确',
    deleteReservationConfirmation: '确认要删除此预约?',
    updateReservationDate: '修改预约时间',
    confirmEdit: '确认',
    reservedAlready: '你已有此活动的有效预约',
    navigateToTicketDetailPage: '查看票号详情',
    pleaseGoTo: '请到',
    called: '已叫号',
    waiting: '等待进入',
    wait: '等待',
    record: '记录',
    checkedOut: '已完成',
    checkIned: '已到达',
    editReservationSuccess: '修改成功',
    readOnly: '唯读',
    fullQuota: '已满',
  },
  TC_TB: {
    // page titles
    queueTableSize: '請選擇到訪⼈數',
    queueTimeSection: '請選擇到訪時段',
    queueDate: '請選擇到訪⽇期',
    preview: '登記資料',
    queuePersonalInfo: '請輸入電郵地址以作登記',
    queueRequestTicketSuccess: '登記成功',
    // others
    selectTableSize: '請選擇到訪⼈數',
    selectTimeSection: '請選擇到訪時段',
    selectDate: '請選擇到訪日期',
    selectPersonalInfo: '請輸入電郵地址以作登記',
    selectPreview: '登記資料',
    tableSize: '到訪⼈數',
    timeSection: '到訪時段',
    date: '到訪⽇期',
    personalData: '登記電郵地址',
    success: '登記成功！',
    sent: '登記確認資料已發送⾄ ',
  },
  EN_TB: {
    // page titles
    queueTableSize: 'Select No. of Visitor(s)',
    queueTimeSection: 'Select Session of Visit',
    queueDate: 'Select Date of Visit',
    preview: 'Registration Information',
    queuePersonalInfo: 'Fill in Email Address for Registration',
    queueRequestTicketSuccess: 'Registration Successful',
    // others
    selectTableSize: 'Select No. of Visitor(s)',
    selectTimeSection: 'Select Session of Visit',
    selectDate: 'Select Date of Visit',
    selectPersonalInfo: 'Fill in Email Address for Registration',
    selectPreview: 'Registration Information',
    tableSize: 'No. of Visitor(s)',
    timeSection: 'Session of Visit',
    date: 'Date of Visit',
    personalData: 'Registration Email',
    success: 'Registration Successful! ',
    sent: 'A Confirmation Email will be sent to ',
  },
  SC_TB: {
    // page titles
    queueTableSize: '请选择到访⼈数',
    queueTimeSection: '请选择到访时段',
    queueDate: '请选择到访⽇期',
    preview: '登记资料',
    queuePersonalInfo: '请输入电邮地址以作登记',
    queueRequestTicketSuccess: '登记成功',
    // others
    selectTableSize: '请选择到访⼈数',
    selectTimeSection: '请选择到访时段',
    selectDate: '请选择到访日期',
    selectPersonalInfo: '请输入电邮地址以作登记',
    selectPreview: '登记资料',
    tableSize: '到访⼈数',
    timeSection: '到访时段',
    date: '到访⽇期',
    personalData: '登记电邮地址',
    success: '登记成功！',
    sent: '登记确认资料已发送⾄ ',
  },
  TC_MTR_TAXI: {
    currentTagSequence: '現時叫號',
    ticketSequence: '現時取號',
    mobileRequestTicket: '立即取號',
    requestTicket: '立即取號',
    queueServiceType: '選擇⽬的地',
    selectServiceType: '選擇⽬的地',
    queuePersonalInfo: '填寫電郵地址以取號',
    selectPersonalInfo: '填寫電郵地址以取號',
    emailTitle: '電郵地址',
    tableType: '⽬的地',
    preRegistration: '立即取號',
    emailError: '*請提供正確電郵地址',
  },
  EN_MTR_TAXI: {
    currentTagSequence: 'Current status',
    ticketSequence: ' Current ticket',
    mobileRequestTicket: 'Get ticket',
    requestTicket: 'Get a ticket',
    queueServiceType: ' Select your destination',
    selectServiceType: ' Select your destination',
    queuePersonalInfo: 'Please provide your email address to get a ticket',
    selectPersonalInfo: 'Please provide your email address to get a ticket',
    emailTitle: 'Email address',
    tableType: 'Your destination',
    preRegistration: 'Get ticket',
    emailError: '*Please provide a correct email address',
  },
  SC_MTR_TAXI: {
    currentTagSequence: '现时叫号',
    ticketSequence: '现时取号',
    mobileRequestTicket: '立即取号',
    requestTicket: '立即取号',
    queueServiceType: '选择⽬的地',
    selectServiceType: '选择⽬的地',
    queuePersonalInfo: '填写电邮地址以取号',
    selectPersonalInfo: '填写电邮地址以取号',
    emailTitle: '电邮地址',
    tableType: '⽬的地',
    preRegistration: '立即取号',
    emailError: '*请提供正确电邮地址',
  },
  TC_COUNTER: {
    currentTagSequence: '現時叫號',
  },
  EN_COUNTER: {
    currentTagSequence: 'Now Serving',
  },
  SC_COUNTER: {
    currentTagSequence: '现时叫号',
  },
  TC_HIGH_SPEED_RAIL: {
    tableType: '票務',
  },
  SC_HIGH_SPEED_RAIL: {
    tableType: '票务',
  },
  EN_HIGH_SPEED_RAIL: {
    tableType: 'Ticketing Service',
  },
  TC_AIRLINE: {
    tableType: '航班編號/目的地',
  },
  SC_AIRLINE: {
    tableType: '航班编号/目的地',
  },
  EN_AIRLINE: {
    tableType: 'Flight Number/Destination',
  },

};
