import React from 'react';
import { GuluLogo } from '../components/GuluLogo';
import { QueueTitle } from '../components/QueueTitle';

const Main = () => {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }}>
      <GuluLogo show />
      <QueueTitle title={'THE GULU WEB'} />
    </div>
  );
}

export default Main;