import { CallingItem } from "./CallingItem"

export const CallingSection = ({callTicketList}) => {
  return (
    callTicketList?.length >0 &&
    <div className="calling-section-wrapper">
      {
        callTicketList.map((item)=><CallingItem key={item.id} item={item}/>)
      }
    </div>
  )
}