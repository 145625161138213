import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { setHttpStatusMessage } from '../reducers/systemSlice';

import { GuluLogo } from '../components/GuluLogo';

import { getStringByTheme } from '../utils/utils';

import '../styles/error.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';

const Error = () => {
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);
  const httpStatusMessage = useSelector((state) => state.system.httpStatusMessage);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (httpStatusMessage) {
      setMessage(httpStatusMessage);
      setHttpStatusMessage("");
    } else {
      setMessage(getStringByTheme('invalidUrl', lang, theme));
    }
  }, [lang])

  useEffect(() => {
    document.title = "THE GULU Web";
  }, [lang]);

  return (
    <LanguageWrapper>
      <div className="error-wrapper">
        <GuluLogo show />
        <div className="message">{message}</div>
      </div>
    </LanguageWrapper>
  );
}

export default Error;