import React from 'react'
import { getDisplayReason } from '../utils/queueUtils'

export const TableTypeDisplayReason = ({serviceType, lang, theme}) => {
  return (
    getDisplayReason(serviceType, lang, theme) && 
    <div className='display-reason'>
      {getDisplayReason(serviceType, lang, theme)}
    </div>
  )
}
