import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { mergeQueueConfig } from '../utils/queueUtils';
import { usePrevious } from '../utils/hooks'
import { getStringByTheme, showElementByTheme } from '../utils/utils';
import { setQueueConfig, resetQueue } from '../reducers/queueSlice';
import { QUEUE_STEPS, ELEMENT } from '../constants/constants';
import { PATH } from '../constants/paths';

import { SiteInfo } from '../components/SiteInfo';
import { GuluLogo } from '../components/GuluLogo';
import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';
import Calendar from '../components/Calendar';

import '../styles/queue-date.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';

const QueueDate = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const selectedDate = useSelector((state) => state.queue.requestParams.selectedDate);
  const lang = useSelector((state) => state.system.lang);
  const token = useSelector((state) => state.system.token);
  const theme = useSelector((state) => state.system.theme);
  const previousSelectedDate = usePrevious(selectedDate, '');

  const [calendarDisplayDate, setCalendarDisplayDate] = useState(moment().set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }))

  useEffect(() => {
    document.title = getStringByTheme('queueDate', lang, theme);
  }, [lang]);

  useEffect(() => {

    if(!lang) {
      return;
    }

    // if (!requestParams.nextStep) {
    const newParams = _.cloneDeep(requestParams);
    newParams.siteId = params.siteId;
    post(API_URLS.QUEUE, newParams, (payload) => {
      const newConfig = mergeQueueConfig(queueConfig, payload);
      dispatch(setQueueConfig(newConfig));
    }, undefined, store);
    // }
  }, [params.siteId, dispatch, store, lang]);

  useEffect(() => {
    if (queueConfig) {
      let displayDate = moment();
      if (requestParams.selectedDate) {
        displayDate = moment(requestParams.selectedDate);
      } else if (queueConfig.selectDate) {
        displayDate = moment(queueConfig.selectDate);
      }
      setCalendarDisplayDate(displayDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    }
  }, [queueConfig])

  function handleMonthClick(displayDate) {
    setCalendarDisplayDate(moment(displayDate).set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }));
  }

  function handleDateClick(selectedDate) {
    const newQueueConfig = _.cloneDeep(queueConfig);
    const newParams = _.cloneDeep(requestParams);
    if (selectedDate !== previousSelectedDate) {
      newParams.size = 0;
      newParams.tableType = '';
      newParams.timeSectionId = '';
      newParams.ticketType = '';
      newParams.tags = [];
      newParams.ticketTncAgreed = false;
      newQueueConfig.filterResult = undefined;
    }
    newParams.selectedDate = selectedDate;
    dispatch(resetQueue({ queueConfig: newQueueConfig, requestParams: newParams }));
  }

  function handleSiteDetailButtonClick() {
    history.push(`/${lang}` + PATH.SITE_DETAIL + params.siteId);
  }

  return (
    <LanguageWrapper>
      {
        queueConfig &&
        <>
          <div className="queue-wrapper">
            <GuluLogo show={!token && queueConfig.siteInfo && queueConfig.siteInfo.showIcon && showElementByTheme(ELEMENT.GULU_LOGO, theme)} />
            <div className="background-site-info">
              <SiteInfo site={queueConfig && queueConfig.siteInfo} show={false} showLang={false} />
            </div>
            <div className="queue-wrapper-content queue-date-wrapper">
              <QueueTitle title={getStringByTheme('selectDate', lang, theme)} />
              <Calendar
                startDate={queueConfig.selectDate && queueConfig.selectDate.startDate}
                endDate={queueConfig.selectDate && queueConfig.selectDate.endDate}
                calendarDisplayDate={calendarDisplayDate}
                disableDateList={queueConfig.selectDate && queueConfig.selectDate.disableDateList}
                selectedDate={requestParams.selectedDate}
                handleDateClick={handleDateClick}
                handleMonthClick={handleMonthClick}
              />
            </div>
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.DATE} />
        </>
      }
      {
        !queueConfig &&
        <div className="queue-single-button-wrapper">
          <div className="single-button" onClick={handleSiteDetailButtonClick}>{getStringByTheme('returnToSiteDetail', lang, theme)}</div>
        </div>
      }
    </LanguageWrapper>
  );
}

export default QueueDate;
