import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { LANG } from "../constants/constants";
import { setLang } from "../reducers/systemSlice";
import { useLocation } from 'react-router-dom';

export const LanguageWrapper = ({children}) => {

  const params = useParams();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.system.lang);
  const location = useLocation();

  useEffect(() => {
    if (params.language) {
      switch (params.language.toUpperCase()) {
        case LANG.EN:
        case LANG.SC:
          dispatch(setLang(params.language.toUpperCase()));
          break;
        default:
          dispatch(setLang(LANG.TC));
      }
    } else {
      dispatch(setLang(LANG.TC));
    }

  }, [location, params.language]);

  return (
    <>
    {
    lang &&
    children}
    </>
  )
}