import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import moment from 'moment';
import * as _ from 'lodash';
import QRCode from 'qrcode.react';
import { isEmpty } from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { setTicket, setSiteInfo, setSurveyInfo, setUserDetail } from '../reducers/ticketSlice';
import { unsubscribeMqttTopic, setMessage } from '../reducers/systemSlice';
import { setSubscribeExistingTagSequenceListOnly } from '../reducers/queueSlice';
import { tableColor, getStringByTheme, showElementByTheme, validateEmail, getSurnameString, isRedeemed, isDeleted, shadeColor } from '../utils/utils';
import { subscribeTicketViaWhatsapp } from '../utils/queueUtils';
import { getReservationTicket, reservationEditable } from '../utils/reservationUtils';
import { ELEMENT, PATH_LOCATION, TICKET_DISPLAY_TYPE, NOTIFIER, STATUS, MQTT } from '../constants/constants';

import { SiteInfo } from '../components/SiteInfo';
import { DropDown } from '../components/DropDown';
import { TagSequence } from '../components/TagSequence';
import Header from '../components/Header';
import { ConfirmDialog } from '../components/ConfirmDialog';
import { ReservationEditDialog } from '../components/ReservationEditDialog';

import { ReactComponent as PeopleIcon } from '../images/people.svg';
import { ReactComponent as CounterLogo } from '../images/counter-icon.svg';
import { ReactComponent as SendEmailArrow } from '../images/send-email-arrow.svg';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// import { ReactComponent as ScreenShotIcon } from '../images/screen-shot-icon.svg';
import { ReactComponent as WhatsappIcon } from '../images/whatsapp-icon.svg';
import { ReactComponent as TrashIcon } from '../images/trash.svg';
import { ReactComponent as EditIcon } from '../images/edit.svg';

import '../styles/ticket.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';

const ReservationTicket = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useStore();

  // const history = useHistory();
  const ticket = useSelector((state) => state.ticket.ticket);
  const siteInfo = useSelector((state) => state.ticket.siteInfo);
  const surveyInfo = useSelector((state) => state.ticket.surveyInfo);
  const userDetail = useSelector((state) => state.ticket.userDetail);
  const tagSequenceList = useSelector((state) => state.queue.tagSequenceList);
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);
  const counterTagList = useSelector((state) => state.queue.counterTagList);
  const latestTag = useSelector((state) => state.queue.latestTag);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailSuccess, setEmailSuccess] = useState(false);
  const subscribedTicketCallTimestamp = useSelector((state) => state.queue.subscribedTicketCallTimestamp);
  const requestTicketSuccessfully = useSelector((state) => state.queue.requestTicketSuccessfully);

  const [color, setColor] = useState('A');
  const [blinking, setBlinking] = useState(false);
  const [timeoutHandle, setTimeoutHandle] = useState(undefined);
  const [displayList, setDisplayList] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);

  useEffect(() => {
    return function unsubscribeMqtt() {
      if (ticket && siteInfo && siteInfo.siteId) {
        dispatch(unsubscribeMqttTopic([MQTT.VERSION, siteInfo.siteId, MQTT.CHANNEL, MQTT.RESERVATION, MQTT.TICKET, params.ticketId].join('/')));
        dispatch(unsubscribeMqttTopic([MQTT.VERSION, siteInfo.siteId, MQTT.CHANNEL, MQTT.QUEUE, ticket.timeSessionId, ticket.tableType].join('/')));
        dispatch(unsubscribeMqttTopic([MQTT.VERSION, siteInfo.siteId, MQTT.CHANNEL, MQTT.QUEUE, MQTT.TICKET, ticket.ticketId].join('/')));
        dispatch(setTicket(undefined));
        dispatch(setSiteInfo(undefined));
        dispatch(setSurveyInfo(undefined));
        dispatch(setUserDetail(undefined));
        dispatch(setSubscribeExistingTagSequenceListOnly(false));
      }
    };
  }, []);

  useEffect(() => {
    document.title = getStringByTheme('ticketTitle', lang, theme);
  }, [lang, theme]);

  useEffect(() => {
    if (!lang) {
      return;
    }

    const newParams = { ticketId: params.ticketId };
    getReservationTicket(newParams, dispatch, store);
  }, [params.ticketId, dispatch, store, lang]);

  useEffect(() => {
    if (ticket) {
      if (ticket.tableType) {
        setColor(tableColor(ticket.tableType));
      }
      if (isRedeemed(ticket) || isDeleted(ticket)) {
        if (timeoutHandle) {
          clearTimeout(timeoutHandle);
        }
        setBlinking(false);
      }
      if (ticket.displayType === TICKET_DISPLAY_TYPE.RESERVATION) {
        setColor('R');
      }
    }
  }, [ticket]);

  useEffect(() => {
    if (ticket && ticket.displayType !== TICKET_DISPLAY_TYPE.RESERVATION && subscribedTicketCallTimestamp) {
      if (Date.now() <= subscribedTicketCallTimestamp + 900000) {
        setBlinking(true);
        let newTimeoutHandle = setTimeout(() => {
          setBlinking(false);
        }, subscribedTicketCallTimestamp + 900000 - Date.now());
        setTimeoutHandle(newTimeoutHandle);
      }
    }
  }, [ticket && ticket.callTimestamp]);

  useEffect(() => {
    if (ticket) {
      const tempDisplayList = [];
      if (ticket.size && ticket.sizeSelectable) {
        tempDisplayList.push(
          <div key="tableSize">
            <div className="ticket-title">{getStringByTheme('tableSize', lang, theme)}</div>
            <div className="ticket-text">{ticket.size}</div>
            <div className="middle-line"></div>
          </div>
        );
      }
      if (ticket.tableTypeLabel && ticket.displayType === TICKET_DISPLAY_TYPE.RESERVATION) {
        tempDisplayList.push(
          <div key="tableType">
            <div className="ticket-title">{ticket.tableTypePreviewTitle ? ticket.tableTypePreviewTitle : getStringByTheme('tableType', lang, theme)}</div>
            <div className="ticket-text">{ticket.tableTypeLabel}</div>
            <div className="middle-line"></div>
          </div>
        );
      }
      if (ticket.startTimestamp) {
        tempDisplayList.push(
          <div key="date">
            <div className="ticket-title">{getStringByTheme('date', lang, theme)}</div>
            <div className="date-wrapper">
              <div className="ticket-text">
                {moment(ticket.startTimestamp).format(ticket.displayType === TICKET_DISPLAY_TYPE.RESERVATION ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD')}
              </div>
              {ticket.displayType === TICKET_DISPLAY_TYPE.RESERVATION && ticket.editable && reservationEditable(ticket.startTimestamp, ticket.status) && (
                <EditIcon
                  className="edit-icon"
                  onClick={() => {
                    setShowEditDialog(true);
                  }}
                />
              )}
            </div>
            <div className="middle-line"></div>
          </div>
        );
      }
      if (ticket.displayType !== TICKET_DISPLAY_TYPE.RESERVATION && ticket.timeSectionLabel) {
        tempDisplayList.push(
          <div key="timeSection">
            <div className="ticket-title">{getStringByTheme('timeSection', lang, theme)}</div>
            <div className="ticket-text">{ticket.timeSectionLabel}</div>
            <div className="middle-line"></div>
          </div>
        );
      }
      if (ticket.ticketType && ticket.ticketTypeLabel) {
        tempDisplayList.push(
          <div key="ticketType">
            <div className="ticket-title">{getStringByTheme('ticketType', lang, theme)}</div>
            <div className="ticket-text">{ticket.ticketTypeLabel}</div>
            <div className="middle-line"></div>
          </div>
        );
      }
      if (ticket.tags && ticket.tags.length > 0) {
        tempDisplayList.push(
          <div key="tags">
            <div className="ticket-title">{getStringByTheme('tags', lang, theme)}</div>
            <div className="ticket-text">
              <div>
                {ticket.tags.length > 0
                  ? ticket.tags.forEach((item) => {
                      tempDisplayList.push(<div>{item}</div>);
                    })
                  : getStringByTheme('noTag', lang, theme)}
              </div>
            </div>
            <div className="middle-line"></div>
          </div>
        );
      }
      if (userDetail) {
        if (userDetail.surname && userDetail.gender) {
          tempDisplayList.push(
            <div key="personTitle">
              <div className="ticket-title">{getStringByTheme('personTitle', lang, theme)}</div>
              <div className="ticket-text">{getSurnameString(userDetail.surname, userDetail.gender, lang, theme)}</div>
              <div className="middle-line"></div>
            </div>
          );
        }
        if (userDetail.countryCode && userDetail.mobile) {
          tempDisplayList.push(
            <div key="phone">
              <div className="ticket-title">{getStringByTheme('phone', lang, theme)}</div>
              <div className="ticket-text">{userDetail.countryCode + ' ' + userDetail.mobile}</div>
              <div className="middle-line"></div>
            </div>
          );
        }
        if (userDetail.email) {
          tempDisplayList.push(
            <div key="phone">
              <div className="ticket-title">{getStringByTheme('email', lang, theme)}</div>
              <div className="ticket-text">{userDetail.email}</div>
              <div className="middle-line"></div>
            </div>
          );
        }
      }

      setDisplayList(tempDisplayList);
    }
  }, [lang, ticket, userDetail, theme]);

  const emailOnClick = () => {
    if (email === '' || !validateEmail(email)) {
      setEmailError(getStringByTheme('emailError', lang, theme));
      setEmailSuccess(false);
      return;
    }

    setEmailError('');

    const requestParam = {
      ticketId: ticket.id,
      deliveryType: 'EMAIL',
      email: email,
    };

    post(
      API_URLS.REQUEST_EMAIL_TICKET_DELIVERY,
      requestParam,
      (payload) => {
        setEmailSuccess(true);
      },
      undefined,
      store
    );
  };

  useEffect(() => {
    if (!theme) return;
    if (showElementByTheme(ELEMENT.TICKET_DETAIL_POP_UP, theme) && requestTicketSuccessfully) {
      dispatch(setMessage('ticket-' + getStringByTheme('reminderForTicket', lang, theme)));
    }
  }, [theme]);

  const whatsappOnClick = () => {
    subscribeTicketViaWhatsapp(ticket.id, theme, lang);
  };

  const deleteTicket = () => {
    if (ticket) {
      // const newParams = { ticketId: params.ticketId }
      post(
        API_URLS.RESERVATION_DELETE_TICKET,
        params.ticketId,
        (payload) => {
          const tempTicket = _.cloneDeep(ticket);
          tempTicket.status = STATUS.DEACTIVE;
          dispatch(setTicket(tempTicket));
          setShowConfirmDialog(false);
        },
        undefined,
        store
      );
    }
  };

  const updateReservation = (reservationTimestamp) => {
    if (reservationTimestamp && ticket.startTimestamp !== reservationTimestamp) {
      const newParams = {
        siteId: siteInfo.siteId,
        ticketId: ticket.id,
        reservationTimestamp: reservationTimestamp,
      };
      post(
        API_URLS.RESERVATION_UPDATE,
        newParams,
        (payload) => {
          getReservationTicket({ ticketId: params.ticketId }, dispatch, store);
          setShowEditDialog(false);
          dispatch(setMessage(getStringByTheme('editReservationSuccess', lang, theme)));
          const ticketWrapperList = document.getElementsByClassName('ticket-wrapper');
          if (ticketWrapperList[0]) {
            ticketWrapperList[0].scrollIntoView({ behavior: 'smooth' });
          }
        },
        (data) => {
          setShowEditDialog(false);
          dispatch(setMessage(data.message));
        },
        store
      );
    } else {
      setShowEditDialog(false);
    }
  };

  return (
    <LanguageWrapper>
      <div className="ticket-wrapper queue-wrapper">
        {ticket && (
          <div className="flex-wrapper">
            <Header site={!showElementByTheme(ELEMENT.TICKET_DETAIL_SITE_INFO, theme) && siteInfo} showLanguageButtons showPoweredBy={true} />
            {showElementByTheme(ELEMENT.TICKET_DETAIL_COUNTER_TAG_LIST, theme) && (
              <>
                {/* <div className="title">{getStringByTheme('currentTagSequence', lang, theme)}</div> */}
                {counterTagList && counterTagList.length > 0 && (
                  <div className="counter-tag-list">
                    {counterTagList
                      .filter((counterTag) => {
                        return counterTag.tableType === ticket.tableType;
                      })
                      .slice(0, 1)
                      .map((counterTag, counterTagKey) => {
                        return <TagSequence key={counterTagKey} tagSequence={counterTag} latestTag={latestTag} />;
                      })}
                  </div>
                )}
              </>
            )}
            {ticket.displayType !== TICKET_DISPLAY_TYPE.RESERVATION &&
              showElementByTheme(ELEMENT.TICKET_DETAIL_TAG_SEQUENCE_LIST, theme, params.siteId) &&
              tagSequenceList &&
              tagSequenceList.length > 0 && (
                <div className="tag-sequence-list">
                  {tagSequenceList.map((tagSequence, tagSequenceKey) => {
                    return <TagSequence key={tagSequenceKey} tagSequence={tagSequence} showTicketLabel={false} />;
                  })}
                </div>
              )}
            <div className={`ticket-box ${blinking ? 'blinking' : ''}`}>
              <div
                className={`zig-zag zig-zag-${color}`}
              ></div>
              <div
                className={`ticket-box-content text-${color} background-dark-${color} `}
              >
                <div className={`ticket-content background-dark-${color} `}>
                  {showElementByTheme(ELEMENT.TICKET_DETAIL_SITE_INFO, theme) && (
                    <div className="background-site-info">
                      <SiteInfo site={ticket && siteInfo} location={PATH_LOCATION.TICKET} />
                    </div>
                  )}
                  <div className={`ticket-number background-${color}`}>
                    {ticket.ticketLabel}
                    {ticket.size && ticket.sizeSelectable && (
                      <div className="table-size">
                        <PeopleIcon className="image" />
                        <div>{ticket.size}</div>
                      </div>
                    )}
                    {!isEmpty(ticket.counterName) && (
                      <div className="counter-name-wrapper">
                        <CounterLogo className={`counter-icon`} />
                        <div
                          className={`counter-name text-${color} ${ticket.counterName.length > 1 ? 'long' : ''}`}
                        >
                          {ticket.counterName}
                        </div>
                      </div>
                    )}
                  </div>
                  {ticket.tableTypeLabel && ticket.displayType !== TICKET_DISPLAY_TYPE.RESERVATION && (
                    <div className="table-type-label">{ticket.tableTypeLabel}</div>
                  )}
                  <div className="qrcode-wrapper">
                    {isRedeemed(ticket) && <div className="overlay-text">{getStringByTheme('redeemed', lang, theme)}</div>}
                    {isDeleted(ticket) && <div className="overlay-text">{getStringByTheme('deleted', lang, theme)}</div>}
                    <div className={`qrcode-background ${isRedeemed(ticket) || isDeleted(ticket) ? 'overlay' : ''}`}>
                      <QRCode id="qrCode" className="qrcode" size={100} value={ticket.qrcode} level={'L'} renderAs={'svg'} />
                    </div>
                    {ticket.callTimestamp && (
                      <div className="call-timestamp">
                        {getStringByTheme('callTimestamp', lang, theme) + ' ' + moment(ticket.callTimestamp).format('YYYY-MM-DD HH:mm')}
                      </div>
                    )}
                    {ticket.deletable && !isDeleted(ticket) && (
                      <TrashIcon
                        className="trash-icon"
                        onClick={() => {
                          setShowConfirmDialog(true);
                        }}
                      />
                    )}
                  </div>
                  {ticket.displayType === TICKET_DISPLAY_TYPE.RESERVATION && ticket.reminderMessage && (
                    <div
                      className="reminder-message"
                      dangerouslySetInnerHTML={{
                        __html: ticket.reminderMessage,
                      }}
                    ></div>
                  )}
                  {ticket && ticket.notifierList && ticket.notifierList.length > 0 && (
                    <>
                      <div className="middle-line"></div>
                      <div className="subscription-wrapper">
                        {ticket.notifierList.includes(NOTIFIER.EMAIL) && (
                          <div className="ticket-email">
                            <div className="email-delivery-reminder">{getStringByTheme('emailDeliveryReminder', lang, theme)}</div>
                            <div className="ticket-email-input-field">
                              <input
                                className={`email ${emailError ? 'error' : ''}`}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                                type="text"
                                id="email"
                                name="email"
                                placeholder={getStringByTheme('emailTitle', lang, theme)}
                                value={email}
                              />
                              <SendEmailArrow className="email-arrow" onClick={emailOnClick} />
                            </div>
                            <div className="error-message email-error-message">{emailError}</div>
                            {emailSuccess && (
                              <div className="email-success">
                                <CheckCircleOutlineIcon />
                                {getStringByTheme('emailSuccess', lang, theme)}
                              </div>
                            )}
                          </div>
                        )}
                        {ticket.notifierList.includes(NOTIFIER.WHATSAPP) && (
                          <div className="whatsapp-subscribe-button" onClick={whatsappOnClick}>
                            <WhatsappIcon className="whatsapp-icon" />
                            <span>{getStringByTheme('subscribeViaWhatsapp', lang, theme)}</span>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  <div className="ticket-detail-wrapper">
                    <div className="middle-line"></div>
                    <div className="ticket-detail">
                      {displayList}
                      {surveyInfo && (
                        <>
                          <div className="survey-info-wrapper">
                            {surveyInfo.list &&
                              surveyInfo.list.map((question, index) => {
                                return (
                                  <div key={index} className="question">
                                    <div className="question-title">{question.name}</div>
                                    <div className="question-text">{question.type === 'TEXT' ? question.value : <img src={question.value} />}</div>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="middle-line"></div>
                        </>
                      )}
                    </div>
                    <DropDown
                      titleClass="ticket-title"
                      title={getStringByTheme('tnc', lang, theme)}
                      content={ticket.termsAndConditions}
                      location={PATH_LOCATION.TICKET}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`zig-zag zig-zag-bottom zig-zag-${color}`}
              ></div>
            </div>
          </div>
        )}
        {showConfirmDialog && (
          <ConfirmDialog
            message={getStringByTheme('deleteReservationConfirmation', lang, theme)}
            handleConfirm={deleteTicket}
            handleCancel={() => {
              setShowConfirmDialog(false);
            }}
          />
        )}
        {showEditDialog && (
          <ReservationEditDialog
            siteId={siteInfo?.siteId}
            reservation={ticket}
            handleConfirm={updateReservation}
            handleCancel={() => {
              setShowEditDialog(false);
            }}
          />
        )}
        <Header showGuluLogo />
      </div>
    </LanguageWrapper>
  );
};

export default ReservationTicket;
