import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { tableColor, showElementByTheme } from '../utils/utils';

import { ELEMENT } from '../constants/constants';

// import { ReactComponent as ReloadLogo } from '../images/reload.svg';
// import { ReactComponent as RefundIcon } from '../images/tv/mtr-icon-refund.svg';
// import { ReactComponent as TicketIcon } from '../images/tv/mtr-icon-ticket.svg';
// import { ReactComponent as QuestionIcon } from '../images/tv/mtr-icon-question.svg';

import '../styles/tv-tag-sequence.scss';

export const TVTagSequence = ({ tagSequence, tableTypeColorList, tableTypeIconListMap, extraColumnMap, bgColor, occupyAll, extraRowHeight }) => {
  const theme = useSelector((state) => state.system.theme);

  const [color, setColor] = useState('A');

  useEffect(() => {
    if (tagSequence && tagSequence.tableType) {
      setColor(tableColor(tagSequence.tableType));
    }
  }, [tagSequence]);

  // const getTableTypeColor = (tableType) => {
  //   const sequence = tableType.charCodeAt(0) - 65;

  //   return tableTypeColorList[sequence % tableTypeColorList.length];
  // };

  // const getTableTypeStyle = (tableType) => {
  //   const style = {};

  //   if (tableTypeColorList) {
  //     style.backgroundColor = getTableTypeColor(tagSequence.tableType);
  //     style.color = getTableTypeColor(tagSequence.tableType);
  //   }

  //   return style;
  // };

  const getForegroundColor = (color, tableType) => {
    if (tableTypeIconListMap.has(tableType) && tableTypeIconListMap.get(tableType).backgroundColor) {
      return tableTypeIconListMap.get(tableType).backgroundColor
    }

    return color
  }

  return (
    <>
      {tagSequence && (
        <>
          <tr className={`tv-data-body ${bgColor? 'with-bg-color': ''}`}>
            {
              occupyAll? 
              <td colSpan={42} style={{height: extraRowHeight+'px'}}></td>
              : 
              (
                <>
                {showElementByTheme(ELEMENT.TV_TABLE_TYPE, theme) && (
                  <td className="tv-table-type-data">
                    <div
                      className={`tv-table-type-square en background-${color}`}
                      style={{ backgroundColor: getForegroundColor(tagSequence.tableTypeForegroundColor), color: 'white' }}
                    >
                      {tagSequence.tableType}
                    </div>
                  </td>
                )}
                <td>
                  <div className={`tv-tableType-label text-${color}`} style={{ color: getForegroundColor(tagSequence.tableTypeForegroundColor, tagSequence.tableType), background: 'inherit' }}>
                    {
                      tagSequence.tableTypeTcLabel && tagSequence.tableTypeEnLabel ?
                      (
                        <>
                        {showElementByTheme(ELEMENT.TV_TICKET_ICON, theme) && (
                        <div className={`tv-table-type-square background-${color}`} style={{ backgroundColor: getForegroundColor(tagSequence.tableTypeForegroundColor, tagSequence.tableType), color: 'white' }}>
                          <TicketTypeIcon tableType={tagSequence.tableType} tableTypeIconListMap={tableTypeIconListMap} />
                        </div>
                      )}
                      <div className='tv-table-type-label-content'>
                        <div className="tc-label">{tagSequence.tableTypeTcLabel}</div>
                        <div className="en-label en">{tagSequence.tableTypeEnLabel}</div>
                      </div>
                      </>
                      ) : (
                        <>
                        {showElementByTheme(ELEMENT.TV_TICKET_ICON, theme) && (
                          <div >
                            <TicketTypeIcon tableType={tagSequence.tableType} tableTypeIconListMap={tableTypeIconListMap} />
                          </div>
                        )}
                        </>
                      )
                    }
                  </div>
                </td>
                {
                  extraColumnMap.get(tagSequence.tableType)?.map((column)=><td className='en' key={column} style={{ color: getForegroundColor(tagSequence.tableTypeForegroundColor, tagSequence.tableType), whiteSpace: 'nowrap'}}>{column}</td>)
                }
                <td className="tv-queue-calling-display en">
                  <div className={`tv-tag-calling `} style={{ color: tagSequence.tableTypeForegroundColor, textAlign: 'center' }}>
                    {tagSequence.tagLabel || '-'}
                    <div
                      className={`en reverse-tag-for-blink tv-tag-calling text-${color}`}
                      style={{ color: tagSequence.tableTypeForegroundColor }}
                    >
                      {tagSequence.tagLabel || '-'}
                      <div className={`en reverse-tag-for-blink tv-tag-calling text-${color}`} style={{ color: 'white' }}>
                        {tagSequence.tagLabel || '-'}
                      </div>
                    </div>
                  </div>
                </td>
                </>
              )
            }
          </tr>
        </>
      )}
    </>
  );
};

const TicketTypeIcon = ({ tableType, tableTypeIconListMap = new Map() }) => {
  if (tableTypeIconListMap.has(tableType)) {
    return <img src={tableTypeIconListMap.get(tableType).iconUrl} alt={tableType} />;
  }

  return <></>;
};
