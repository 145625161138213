import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as BannerImage } from '../images/cichk/cichk_banner.svg';
import { LANG } from '../constants/constants';
import { getStringByTheme } from '../utils/utils';

function CichkHeader({ show, showLang, handleLangClick }) {

  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);
  // const token = useSelector((state) => state.system.token);

  // function handleLangClick(newLang) {
  //   dispatch(setLoading(true));
  //   switch (newLang) {
  //     case LANG.EN:
  //       moment.locale('en');
  //       dispatch(setLang(LANG.EN));
  //       break;
  //     case LANG.SC:
  //       moment.locale('zb-cn');
  //       dispatch(setLang(LANG.SC));
  //       break;
  //     default:
  //       moment.locale('zh-hk');
  //       dispatch(setLang(LANG.TC));
  //   }
  // }

  return (
    <div className="cichk-header-wrapper">
      <BannerImage className="banner" />
      {
        showLang &&
        <div className="lang-wrapper">
          {
            lang !== LANG.TC &&
            <div className="lang-button" onClick={() => { handleLangClick(LANG.TC) }}>{getStringByTheme('langButton', LANG.TC, theme)}</div>
          }
          {
            lang !== LANG.SC &&
            <div className="lang-button" onClick={() => { handleLangClick(LANG.SC) }}>{getStringByTheme('langButton', LANG.SC, theme)}</div>
          }
          {
            lang !== LANG.EN &&
            <div className="lang-button" onClick={() => { handleLangClick(LANG.EN) }}>{getStringByTheme('langButton', LANG.EN, theme)}</div>
          }
        </div>
      }
    </div>
  )
}

export default CichkHeader