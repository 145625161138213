import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { mergeQueueConfig } from '../utils/queueUtils';
import { setQueueConfig, setRequestParams } from '../reducers/queueSlice';
import { getStringByTheme } from '../utils/utils';
import { QUEUE_STEPS } from '../constants/constants';
import { PATH } from '../constants/paths';

import Header from '../components/Header';
import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';

import '../styles/queue-special-request.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';

const QueueSpecialRequest = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const token = useSelector((state) => state.system.token);
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  const [specialRequestList, setSpecialRequestList] = useState([]);

  useEffect(() => {
    document.title = getStringByTheme('queueSpecialRequest', lang, theme);
  }, [lang]);

  useEffect(() => {
    if (!lang) {
      return;
    }

    if (
      !queueConfig ||
      (requestParams.selectedDate && !queueConfig.filterResult)
    ) {
      const newParams = _.cloneDeep(requestParams);
      newParams.siteId = params.siteId;
      post(
        API_URLS.QUEUE,
        newParams,
        (payload) => {
          const newConfig = mergeQueueConfig(queueConfig, payload);
          dispatch(setQueueConfig(newConfig));
        },
        undefined,
        store
      );
    }
  }, [params.siteId, dispatch, store, queueConfig, lang]);

  useEffect(() => {
    if (queueConfig && queueConfig.selectTags) {
      setSpecialRequestList(queueConfig.selectTags);
    }
  }, [queueConfig]);

  function handleSpecialRequestChange(specialRequest) {
    const newParams = _.cloneDeep(requestParams);
    // newParams.tableType = serviceType.tableType;
    dispatch(setRequestParams(newParams));
  }

  function handleSiteDetailButtonClick() {
    history.push(`/${lang}` + PATH.SITE_DETAIL + params.siteId);
  }

  return (
    <LanguageWrapper>
      {queueConfig && (
        <>
          <div className="queue-wrapper">
            <Header site={queueConfig.siteInfo} showSiteInfoGuluLogo={false} />
            <div className="queue-wrapper-content queue-special-request-wrapper">
              <QueueTitle
                title={getStringByTheme('selectSpecialRequest', lang, theme)}
              />
              {specialRequestList.map((specialRequest, index) => {
                return (
                  <div className="special-request-checkbox-wrapper" key={index}>
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        id={specialRequest.code}
                        name={specialRequest.name}
                        onChange={() =>
                          handleSpecialRequestChange(specialRequest)
                        }
                      />
                    </div>
                    <label htmlFor={specialRequest.code}>
                      {specialRequest.name}
                    </label>
                  </div>
                );
              })}
            </div>
            <Header showGuluLogo />
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.TAGS} defaultValid />
        </>
      )}
      {!queueConfig && (
        <div className="queue-single-button-wrapper">
          <div className="single-button" onClick={handleSiteDetailButtonClick}>
            {getStringByTheme('returnToSiteDetail', lang, theme)}
          </div>
        </div>
      )}
    </LanguageWrapper>
  );
};

export default QueueSpecialRequest;
