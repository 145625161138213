import { STRINGS } from '../constants/strings';
import { LANG, ELEMENT, COLOR, PATH_LOCATION, TICKET_DISPLAY_TYPE, STATUS } from '../constants/constants';
import { THEMES } from '../constants/themes';

// export const processQuery = (queryString) => {
//   const params = {};
//   let temp;
//   let i;
//   let l;
//   const queries = queryString.split('&');
//   for (i = 0, l = queries.length; i < l; i++) {
//     temp = queries[i].split('=');
//     params[temp[0]] = temp[1];
//   }
//   return params;
// }

export const isAndroid = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  return false;
};

export const isIOS = () => {
  if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)) {
    return true;
  }
  return false;
};

// export const getStatusText = (status, lang) => {
//   console.log(status);
//   switch (status) {
//     case STATUS.ACTIVE:
//       return STRINGS[lang].active;
//     case STATUS.PENDING:
//       return STRINGS[lang].pending;
//     case STATUS.PAYMENT_FAIL:
//     case STATUS.FAIL:
//       return STRINGS[lang].fail;
//     default:
//       return '';
//   }
// }

export const tableColor = (tableType) => {
  switch (tableType) {
    case 'B':
    case 'H':
    case 'N':
    case 'T':
    case 'Z':
      return 'B';
    case 'C':
    case 'I':
    case 'O':
    case 'U':
      return 'C';
    case 'D':
    case 'J':
    case 'P':
    case 'V':
      return 'D';
    case 'E':
    case 'K':
    case 'Q':
    case 'W':
      return 'E';
    case 'F':
    case 'L':
    case 'R':
    case 'X':
      return 'F';
    default:
      return 'A';
  }
};

export const getTableColorByIndex = (index) => {
  const tempIndex = Number(index);
  const tableColor = ['A', 'B', 'C', 'D', 'E', 'F'];
  if (!isNaN(tempIndex)) {
    return tableColor[tempIndex % 6];
  }
  return tableColor[0];
};

export const validateEmail = (email) => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)(\.[a-zA-Z0-9-]+){0,1}$/i.test(email);
};

export const validateMobile = (mobile) => {
  return /^[0-9]{8,}$/i.test(mobile);
};

export const getStringByTheme = (key, lang = LANG.TC, theme) => {
  switch (theme) {
    case THEMES.MTR_TAXI:
      switch (lang) {
        case LANG.EN:
          return STRINGS[LANG.EN_MTR_TAXI][key] ? STRINGS[LANG.EN_MTR_TAXI][key] : STRINGS[lang][key];
        case LANG.SC:
          return STRINGS[LANG.SC_MTR_TAXI][key] ? STRINGS[LANG.SC_MTR_TAXI][key] : STRINGS[lang][key];
        default:
          return STRINGS[LANG.TC_MTR_TAXI][key] ? STRINGS[LANG.TC_MTR_TAXI][key] : STRINGS[lang][key];
      }
    case THEMES.COUNTER:
    case THEMES.JOCKEY_CLUB:
      switch (lang) {
        case LANG.EN:
          return STRINGS[LANG.EN_COUNTER][key] ? STRINGS[LANG.EN_COUNTER][key] : STRINGS[lang][key];
        case LANG.SC:
          return STRINGS[LANG.SC_COUNTER][key] ? STRINGS[LANG.SC_COUNTER][key] : STRINGS[lang][key];
        default:
          return STRINGS[LANG.TC_COUNTER][key] ? STRINGS[LANG.TC_COUNTER][key] : STRINGS[lang][key];
      }
    case THEMES.HIGH_SPEED_RAIL:
      switch (lang) {
        case LANG.EN:
          return STRINGS[LANG.EN_HIGH_SPEED_RAIL][key] ? STRINGS[LANG.EN_HIGH_SPEED_RAIL][key] : STRINGS[lang][key];
        case LANG.SC:
          return STRINGS[LANG.SC_HIGH_SPEED_RAIL][key] ? STRINGS[LANG.SC_HIGH_SPEED_RAIL][key] : STRINGS[lang][key];
        default:
          return STRINGS[LANG.TC_HIGH_SPEED_RAIL][key] ? STRINGS[LANG.TC_HIGH_SPEED_RAIL][key] : STRINGS[lang][key];
      }
    case THEMES.AIRLINE:
      switch (lang) {
        case LANG.EN:
          return STRINGS[LANG.EN_AIRLINE][key] ? STRINGS[LANG.EN_AIRLINE][key] : STRINGS[lang][key];
        case LANG.SC:
          return STRINGS[LANG.SC_AIRLINE][key] ? STRINGS[LANG.SC_AIRLINE][key] : STRINGS[lang][key];
        default:
          return STRINGS[LANG.TC_AIRLINE][key] ? STRINGS[LANG.TC_AIRLINE][key] : STRINGS[lang][key];
      }
    default:
      return STRINGS[lang][key];
  }
};

export const showElementByTheme = (element, theme, siteId) => {
  switch (element) {
    case ELEMENT.PROMOTION_TNC:
      // switch (theme) {
      //   case THEMES.MTR_TAXI:
      return false;
    //   default:
    //     return true;
    // }
    case ELEMENT.SITE_LIST_LOGO:
      switch (theme) {
        default:
          return true;
      }
    case ELEMENT.SITE_DETAIL_QUEUE_TITLE:
      switch (theme) {
        default:
          return true;
      }
    case ELEMENT.SITE_DETAIL_BANNER_LIST:
      switch (theme) {
        default:
          return true;
      }
    case ELEMENT.SITE_DETAIL_NAVIGATION_LIST_TOP:
      switch (theme) {
        case THEMES.MTR_TAXI:
          return true;
        default:
          return false;
      }
    case ELEMENT.SITE_DETAIL_NAVIGATION_LIST_BOTTOM:
      switch (theme) {
        default:
          return false;
      }
    case ELEMENT.SITE_DETAIL_BOTTOM_BUTTON:
      switch (theme) {
        case THEMES.MTR_TAXI:
        case THEMES.TV:
          return false;
        default:
          return true;
      }
    case ELEMENT.SITE_DETAIL_NAV_PRE_REG_ICON:
      switch (theme) {
        case THEMES.MTR_TAXI:
          return false;
        default:
          return true;
      }
    case ELEMENT.SITE_DETAIL_DESCRIPTION_LIST:
      switch (theme) {
        default:
          return true;
      }
    case ELEMENT.SITE_DETAIL_NAV_QRCODE_ICON:
      switch (theme) {
        case THEMES.MTR_TAXI:
          return true;
        default:
          return false;
      }
    case ELEMENT.SITE_DETAIL_MOBILE_QUEUE:
      switch (theme) {
        case THEMES.MTR_TAXI:
          return false;
        default:
          return true;
      }
    case ELEMENT.SITE_DETAIL_DETAIL_BOX:
      switch (theme) {
        default:
          return false;
      }
    case ELEMENT.SITE_DETAIL_BOTTOM_BUTTON_ICON:
      switch (theme) {
        default:
          return false;
      }
    case ELEMENT.SITE_INFO_SITE_LOGO:
      switch (theme) {
        default:
          return true;
      }

    case ELEMENT.SITE_INFO_SITE_DATE:
      switch (theme) {
        default:
          return false;
      }

    case ELEMENT.TAG_SEQUENCE_CALL_LOGO:
      switch (theme) {
        case THEMES.MTR_TAXI:
        case THEMES.HANG_SENG:
        case THEMES.CICHK:
          return false;
        default:
          return true;
      }
    case ELEMENT.SITE_DETAIL_TAG_SEQUENCE_LIST:
      switch (theme) {
        case THEMES.COUNTER:
        case THEMES.JOCKEY_CLUB:
          return false;
        default:
          return true;
      }
    case ELEMENT.SITE_DETAIL_TAG_LIST_TITLE:
      switch (theme) {
        case THEMES.HANG_SENG:
        case THEMES.CICHK:
          return false;
        default:
          return true;
      }
    case ELEMENT.TICKET_DETAIL_COUNTER_TAG_LIST:
      switch (theme) {
        // case THEMES.COUNTER:
        // case THEMES.HANG_SENG:
        // case THEMES.CICHK:
        //   return true;
        default:
          return false;
      }
    case ELEMENT.TICKET_DETAIL_TAG_SEQUENCE_LIST:
      switch (theme) {
        // case THEMES.COUNTER:
        // case THEMES.HANG_SENG:
        // case THEMES.CICHK:
        //   return false;
        default:
          return true;
      }
    case ELEMENT.TAG_SEQUENCE_QUOTA:
      switch (theme) {
        default:
          return true;
      }
    case ELEMENT.TAG_MOBILE_ICON:
      switch (theme) {
        case THEMES.HANG_SENG:
        case THEMES.CICHK:
          return false;
        default:
          return true;
      }
    case ELEMENT.SITE_DETAIL_COUNTER_TAG_LIST:
      switch (theme) {
        case THEMES.COUNTER:
        case THEMES.JOCKEY_CLUB:
          return true;
        default:
          return false;
      }
    case ELEMENT.TICKET_DETAIL_BGCOLOR:
      switch (theme) {
        default:
          return true;
      }
    case ELEMENT.TICKET_DETAIL_EMAIL_FIELD:
      switch (theme) {
        default:
          return false;
      }
    case ELEMENT.TICKET_DETAIL_POP_UP:
      switch (theme) {
        default:
          return false;
      }
    case ELEMENT.SITE_DETAIL_SITE_INFO:
      switch (theme) {
        default:
          return true;
      }
    case ELEMENT.SITE_DETAIL_SITE_BANNER:
      switch (theme) {
        default:
          return false;
      }
    case ELEMENT.GULU_LOGO:
      switch (theme) {
        case THEMES.EVENT:
        case THEMES.HANG_SENG:
        case THEMES.CICHK:
          return false;
        default:
          return true;
      }
    case ELEMENT.TICKET_DETAIL_SITE_INFO:
      switch (theme) {
        case THEMES.HANG_SENG:
        case THEMES.CICHK:
        default:
          return true;
      }
    case ELEMENT.TV_TICKET_ICON:
      switch (theme) {
        case THEMES.HIGH_SPEED_RAIL:
        case THEMES.AIRLINE:
        case THEMES.AIRPORT_TAXI:
          return true;
        default:
          return false;
      }
    case ELEMENT.TV_TABLE_TYPE:
      switch (theme) {
        case THEMES.HIGH_SPEED_RAIL:
        case THEMES.AIRLINE:
          return false;
        default:
          return true;
      }
    case ELEMENT.TAG_SEQUENCE_TIME:
      switch (theme) {
        case THEMES.JOCKEY_CLUB:
          return false;
        default:
          return true;
      }
    case ELEMENT.TAG_SEQUENCE_YOUR_TICKET:
      switch (theme) {
        case THEMES.JOCKEY_CLUB:
          return true;
        default:
          return false;
      }
    case ELEMENT.TAG_SEQUENCE_TABLE_TYPE:
      switch (theme) {
        case THEMES.JOCKEY_CLUB:
          return false;
        default:
          return true;
      }
    default:
      return true;
  }
};

export const getStrokeColor = (pathLocation, theme) => {
  switch (pathLocation) {
    case PATH_LOCATION.SITE_DETAIL:
      switch (theme) {
        default:
          return COLOR.WHITE;
      }
    case PATH_LOCATION.TICKET:
      switch (theme) {
        default:
          return COLOR.WHITE;
      }
    case PATH_LOCATION.SITE_DETAIL_MAP:
      switch (theme) {
        default:
          return COLOR.WHITE;
      }
    default:
      return COLOR.WHITE;
  }
};

export const getSurnameString = (surname, gender, lang, theme) => {
  let genderString = '';
  switch (gender) {
    case 'M':
      genderString = getStringByTheme('mr', lang, theme);
      break;
    case 'F':
      genderString = getStringByTheme('ms', lang, theme);
      break;
    case 'A':
      genderString = getStringByTheme('mrs', lang, theme);
      break;
    default:
  }
  return lang === LANG.EN ? genderString + ' ' + surname : surname + genderString;
};

export const isRedeemed = (ticket) => {
  return (
    ticket &&
    ((ticket.displayType === TICKET_DISPLAY_TYPE.RESERVATION && ticket.status === STATUS.SUCCESS) ||
      (ticket.displayType !== TICKET_DISPLAY_TYPE.RESERVATION && ticket.status === STATUS.ACTIVE))
  );
};

export const isDeleted = (ticket) => {
  return ticket && (ticket.status === STATUS.DEACTIVE || ticket.status === STATUS.REJECT);
};

export const getMomentLang = (lang) => {
  if (lang === 'EN') {
    return 'en';
  }

  if (lang === 'TC') {
    return 'zh-hk';
  }

  if (lang === 'SC') {
    return 'zh-cn';
  }

  return 'en';
};

export const getCounterName = (currentLang, messageJson) => {
  switch (currentLang) {
    case LANG.EN:
      return messageJson.enCounterDisplayName ? messageJson.enCounterDisplayName : messageJson.counterName;
    case LANG.SC:
      return messageJson.scCounterDisplayName ? messageJson.scCounterDisplayName : messageJson.counterName;
    default:
      return messageJson.tcCounterDisplayName ? messageJson.tcCounterDisplayName : messageJson.counterName;
  }
};

export const shadeColor = (color, percent) => {
  try {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    R = Math.round(R);
    G = Math.round(G);
    B = Math.round(B);

    var RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

    return '#' + RR + GG + BB;
  } catch (error) {
    return '#2c0917';
  }
};
