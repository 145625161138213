import { createSlice } from '@reduxjs/toolkit';
import * as _ from 'lodash';

export const systemSlice = createSlice({
  name: 'system',
  initialState: {
    lang: undefined,
    loading: false,
    message: '',
    token: '',
    theme: null,
    mqttTopicList: [],
    httpStatusMessage: '',
  },
  reducers: {
    setLang(state, action) {
      state.lang = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setMessage(state, action) {
      state.message = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    setTheme(state, action) {
      state.theme = action.payload;
    },
    subscribeMqttTopic(state, action) {
      state.mqttTopicList.push(action.payload);
    },
    subscribeMqttTopics(state, action) {
      const uniqueList = _.union(state.mqttTopicList.concat(action.payload));
      state.mqttTopicList = uniqueList;
    },
    unsubscribeMqttTopic(state, action) {
      state.mqttTopicList = state.mqttTopicList.filter((topic) => {
        return topic !== action.payload;
      });
    },
    setHttpStatusMessage(state, action) {
      state.httpStatusMessage = action.payload;
    },
  },
});

export const {
  setLang,
  setLoading,
  setMessage,
  setToken,
  setTheme,
  subscribeMqttTopic,
  subscribeMqttTopics,
  unsubscribeMqttTopic,
  setHttpStatusMessage,
} = systemSlice.actions;

export default systemSlice.reducer;
