import { ReactComponent as WaitingIcon } from '../../..//images/queue_counter_three_pax.svg';
// import { ReactComponent as StartIcon } from '../../..//images/start.svg';
// import { useSelector } from 'react-redux';
// import { getStringByTheme, tableColor } from '../../../utils/utils';
import moment from 'moment';

export const WaitingItem = ({ item, tableType, tableTypeColor }) => {
  // const lang = useSelector((state) => state.system.lang);
  // const theme = useSelector((state) => state.system.theme);

  return (
    <div className="waiting-item">
      <div className="waiting-from-section">
        <div className="waiting-detail">
          <div className="waiting-table-type">{item.tableTypeName}</div>
          <div className="waiting-timestamp">
            {moment(item.createTimestamp).locale('en').format('HH:mm')}
          </div>
        </div>
      </div>

      <div className="arrow solid-arrow">
        <div className={`arrow-tail ${tableType}`} style={{backgroundColor: tableTypeColor}}></div>
        <div className={`arrow-triangle ${tableType}`} style={{backgroundColor: tableTypeColor}}></div>
      </div>

      <div className={`waiting-time-section ${tableType}`} style={{color: tableTypeColor}}>
        <WaitingIcon />
        <div className="waiting-time">
          {item.ticketNumber - item.tagNumber > 99
            ? '99+'
            : item.ticketNumber - item.tagNumber > 0
            ? item.ticketNumber - item.tagNumber
            : 0}
        </div>
      </div>
    </div>
  );
};
