import { useSelector } from 'react-redux';

import { ReactComponent as SendArrow } from '../images/send-email-arrow.svg';

import { getStringByTheme } from '../utils/utils';

export const EmailInput = ({ email, handleEmailChange, emailError, subscribeOnClick, mandatory }) => {

  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  return (
    <div className="email-text-wrapper">
      <div className={`email-text ${emailError ? "error" : ""}`}>
        <input
          className="input-text"
          onChange={handleEmailChange}
          type="text"
          id="email"
          name="email"
          placeholder={getStringByTheme('email', lang, theme) + ((mandatory) ? '*' : '')}
          value={email}
        />
        {
          subscribeOnClick &&
          <SendArrow className='subscribe-arrow' onClick={subscribeOnClick} />
        }
      </div>
      {
        emailError &&
        <div className="error-message email-error-message">{emailError}</div>
      }
    </div>
  )
};


