import { useState } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as ArrowIcon } from '../images/arrow.svg';
import { ReactComponent as SendArrow } from '../images/send-email-arrow.svg';
import { ReactComponent as Countdown } from '../images/countdown.svg';
import { ReactComponent as CountdownResend } from '../images/countdown-resend.svg';
import { ReactComponent as Check } from '../images/check.svg';

import { getStringByTheme } from '../utils/utils';
import { DELIVERY_TYPE, OTP_TYPE } from '../constants/constants';
import { useEffect } from 'react';

export const MobileInput = ({ deliveryType, countryCode, mobile, handleCountryCodeChange, handleMobileChange, mobileError, subscribeOnClick, mandatory, enableOtp, otp, sendOtp, handleOtpChange, otpError }) => {

  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  const [sendOtpResend, setSendOtpResend] = useState(false);
  const [sendOtpCountDown, setSendOtpCountDown] = useState(61);
  const [sendOtpInterval, setSendOtpInterval] = useState(undefined);
  const [otpType, setOtpType] = useState((deliveryType === DELIVERY_TYPE.WHATSAPP) ? OTP_TYPE.WHATSAPP : OTP_TYPE.SMS);

  useEffect(() => {
    return () => {
      if (sendOtpInterval) {
        clearInterval(sendOtpInterval);
        setSendOtpInterval(undefined);
      }
    }
  }, [])

  useEffect(() => {
    if (sendOtpCountDown <= 0) {
      setSendOtpCountDown(61);
      if (sendOtpInterval) {
        clearInterval(sendOtpInterval);
        setSendOtpInterval(undefined);
        setSendOtpResend(true);
      }
    }
  }, [sendOtpCountDown])

  return (
    <div className="drop-down-text-wrapper">
      <div className={`drop-down-text ${mobileError ? "error" : ""}`}>
        <div className="drop-down-wrapper">
          <select value={countryCode} onChange={handleCountryCodeChange} className="drop-down-select" id="countryCode" name="countryCode">
            <option value="852">+852</option>
            {/* <option value="853">+853</option> */}
            {/* <option value="86">+86</option> */}
          </select>
          <ArrowIcon className="image " />
        </div>
        <input value={mobile} type="text" onChange={handleMobileChange} className="text-input" id="phone" name="phone" placeholder={getStringByTheme('phoneTitle', lang, theme) + ((mandatory) ? '*' : '')} max="45" />
        {
          subscribeOnClick &&
          <SendArrow className='subscribe-arrow' onClick={subscribeOnClick} />
        }
        {
          enableOtp &&
          <div className={`send-otp-button ${(!countryCode || !mobile || mobileError || sendOtpCountDown <= 60 || otp?.verified) ? 'disable' : ''}`} onClick={() => {
            if (countryCode && mobile && !mobileError && !otp?.verified && sendOtpCountDown >= 60) {
              sendOtp({ type: otpType, countryCode: countryCode, mobile: mobile });
              setSendOtpCountDown(60);
              setSendOtpInterval(setInterval(() => setSendOtpCountDown((prev) => prev - 1), 1000));
            }
          }}>
            {!sendOtpResend && sendOtpCountDown >= 61 && !otp?.verified && getStringByTheme('sendOtp', lang, theme)}
            {!otp?.verified && (sendOtpCountDown <= 60) && <div className="countdown-image-wrapper"><Countdown /><div className="number">{sendOtpCountDown}</div></div>}
            {!otp?.verified && sendOtpResend && sendOtpCountDown >= 61 && <div className="resend-wrapper">{getStringByTheme('resendOtp', lang, theme)}<CountdownResend /></div>}
            {otp?.verified && <Check className='check-icon' />}
          </div>
        }
      </div>
      {
        mobileError &&
        <div className="error-message phone-error-message">{mobileError}</div>
      }
      {
        enableOtp &&
        <div className={`otp-wrapper ${!otp ? 'disable' : ''}`}>
          <div className="otp-prefix">GULU-</div>
          <input value={otp? otp.password : ''} type="text" disabled={!otp} autoComplete="one-time-code"
            onPaste={(event) => {
              if (event.clipboardData.getData("text/plain")?.startsWith("GULU")) {
                event.preventDefault();
                handleOtpChange({ type: otpType, password: event.clipboardData.getData('text/plain').replaceAll(/[GULU-]/g, ""), countryCode: countryCode, mobile: mobile })
              }
            }}
            onChange={(event) => {
              let password = event.target.value;
              if (password?.startsWith("GULU")) {
                password = password.replaceAll(/[GULU-]/g, "")
              }
              handleOtpChange({ type: otpType, password: password, countryCode: countryCode, mobile: mobile })
            }}
            className="text-input" name="otp" placeholder={getStringByTheme('otpTitle', lang, theme)} max="6" />
        </div>
      }
      {
        enableOtp && otp?.error &&
        <div className="error-message otp-error-message">{otp.error}</div>
      }
    </div>
  )
};