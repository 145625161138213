import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as BannerImage } from '../images/hang-seng/hang_seng_banner.svg';
import { LANG } from '../constants/constants';
import { getStringByTheme } from '../utils/utils';

function HangSengHeader({ show, showLang, handleLangClick }) {

  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);
  // const token = useSelector((state) => state.system.token);

  return (
    <div className="hang-seng-header-wrapper">
      <BannerImage className="banner" />
      {
        showLang &&
        <div className="lang-wrapper">
          {
            lang !== LANG.TC &&
            <div className="lang-button" onClick={() => { handleLangClick(LANG.TC) }}>{getStringByTheme('langButton', LANG.TC, theme)}</div>
          }
          {
            lang !== LANG.SC &&
            <div className="lang-button" onClick={() => { handleLangClick(LANG.SC) }}>{getStringByTheme('langButton', LANG.SC, theme)}</div>
          }
          {
            lang !== LANG.EN &&
            <div className="lang-button" onClick={() => { handleLangClick(LANG.EN) }}>{getStringByTheme('langButton', LANG.EN, theme)}</div>
          }
        </div>
      }
    </div>
  )
}

export default HangSengHeader