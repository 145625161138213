import { createSlice } from '@reduxjs/toolkit'

export const siteListSlice = createSlice({
  name: 'siteList',
  initialState: {
    folder: undefined
  },
  reducers: {
    setFolder(state, action) {
      state.folder = action.payload;
    }
  }
})

export const { setFolder } = siteListSlice.actions

export default siteListSlice.reducer