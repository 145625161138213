import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

import {
  setRequestParams,
  setRequestReservationSuccessfully,
} from '../reducers/reservationSlice';
import { resetTicket } from '../reducers/ticketSlice';
import { setMessage } from '../reducers/systemSlice';
import {
  getStringByTheme,
  getSurnameString,
} from '../utils/utils';
import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { SURVEY_QUESTION_TYPE, DELIVERY_TYPE, RESERVATION_MODE } from '../constants/constants';
import { PATH } from '../constants/paths';
import { RESERVATION_REQUEST_PARAMS } from '../constants/initialStates';

import { QueueTitle } from '../components/QueueTitle';
// import { PreviewEditBtn } from '../components/PreviewEditBtn';
import Header from '../components/Header';
import { ConfirmDialog } from '../components/ConfirmDialog';
import { ReactComponent as CheckIcon } from '../images/check.svg';
import { ReactComponent as ArrowButtonIcon } from '../images/arrow-button.svg';

import '../styles/preview.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';

const ReservationPreview = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const reservationConfig = useSelector(
    (state) => state.reservation.reservationConfig
  );
  const requestParams = useSelector((state) => state.reservation.requestParams);
  const requestReservationSuccessfully = useSelector(
    (state) => state.reservation.requestReservationSuccessfully
  );
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);
  const [valid, setValid] = useState(false);
  const [confirmDialogData, setConfirmDialogData] = useState(undefined);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const newParams = _.cloneDeep(requestParams);
    newParams.ticketTncAgreed = false;
    dispatch(setRequestParams(newParams));
  }, []);

  useEffect(() => {
    document.title = getStringByTheme('preview', lang, theme);
  }, [lang]);

  useEffect(() => {
    if (requestParams.ticketTncAgreed) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [requestParams]);

  const previewSurveyDetail = () => {
    const answerList = [];
    if (reservationConfig.surveyDetail) {
      reservationConfig.surveyDetail.questionList?.forEach((question) => {
        const currentAnswer = requestParams.survey ? requestParams.survey[question.questionId] : undefined;
        if (currentAnswer) {
          let tempAnswer = <></>;
          switch (question.type) {
            case SURVEY_QUESTION_TYPE.CHECKBOX:
              tempAnswer = currentAnswer.answerList?.map((ans) => ans.value).join(', ');
              break;
            case SURVEY_QUESTION_TYPE.DROPDOWN_TEXT_FIELD:
              tempAnswer = currentAnswer.dropdown.value + ' ' + currentAnswer.textfield;
              break;
            default:
              tempAnswer = currentAnswer.value;
          }
          answerList.push(
            <div key={question.questionId} className="step-info">
              <div className="preview-title">{question.answerLabel}</div>
              <div className="preview-text">{tempAnswer}</div>
            </div>
          );
        }
      });
    }
    return answerList;
  };

  const handleTncClick = () => {
    const newParams = _.cloneDeep(requestParams);
    newParams.ticketTncAgreed = !newParams.ticketTncAgreed;
    dispatch(setRequestParams(newParams));
  };

  const handleReservation = () => {
    if (valid) {
      if (requestReservationSuccessfully) {
        switch (reservationConfig.deliveryType) {
          case DELIVERY_TYPE.EMAIL:
            dispatch(
              setMessage(getStringByTheme('requestedReservation', lang, theme).replace('%deliveryType%', getStringByTheme('email', lang, theme).toLowerCase()))
            );
            break;
          case DELIVERY_TYPE.SMS:
            dispatch(
              setMessage(
                getStringByTheme('requestedReservation', lang, theme).replace(
                  '%deliveryType%',
                  getStringByTheme('sms', lang, theme)
                )
              )
            );
            break;
          default:
            dispatch(
              setMessage(
                getStringByTheme('requestedReservationNone', lang, theme)
              )
            );
        }
      }
      const newParams = {
        siteId: params.siteId,
        surname: requestParams.surname,
        gender: requestParams.gender,
        countryCode: requestParams.countryCode,
        mobile: requestParams.mobile,
        email: requestParams.email,
        reservationTimestamp: requestParams.reservationTimestamp,
        size: requestParams.size,
        deliveryType: requestParams.deliveryType,
        refTableTypeId: requestParams.refTableTypeId,
        otpValidationDtoList: Object.keys(requestParams.otpValidationDtoMap)
          .filter((key) => requestParams.otpValidationDtoMap[key].verified)
          .map((key) => {
            return {
              id: requestParams.otpValidationDtoMap[key].id,
              type: requestParams.otpValidationDtoMap[key].type,
              value: requestParams.otpValidationDtoMap[key].value,
              password: requestParams.otpValidationDtoMap[key].password,
            };
          }),
      };

      if (reservationConfig.surveyDetail) {
        const answerList = [];
        reservationConfig.surveyDetail.questionList?.forEach((question) => {
          const currentAnswer = requestParams.survey ? requestParams.survey[question.questionId] : undefined;
          if (currentAnswer) {
            const answer = {
              questionId: question.questionId,
            };
            switch (question.type) {
              case SURVEY_QUESTION_TYPE.RADIO:
              case SURVEY_QUESTION_TYPE.DROPDOWN:
                answer.answer = JSON.stringify({
                  key: currentAnswer.key,
                  value: currentAnswer.value,
                });
                break;
              case SURVEY_QUESTION_TYPE.IMAGELIST:
                break;
              case SURVEY_QUESTION_TYPE.CHECKBOX:
                answer.answer = JSON.stringify(currentAnswer.answerList);
                break;
              case SURVEY_QUESTION_TYPE.DROPDOWN_TEXT_FIELD:
                answer.answer = JSON.stringify({
                  dropdown: currentAnswer.dropdown,
                  textfield: currentAnswer.textfield,
                });
                break;
              default:
                answer.answer = currentAnswer.value;
            }
            answerList.push(answer);
          }
        });
        if (answerList.length > 0) {
          newParams.surveyAnswer = {
            surveyId: reservationConfig.surveyDetail.surveyId,
            answerList: answerList,
          };
        }
      }

      post(
        API_URLS.REQUEST_RESERVATION,
        newParams,
        (payload) => {
          dispatch(setRequestReservationSuccessfully(true));
          history.push(`/${lang}` + PATH.RESERVATION_TICKET + payload.ticket.id);
        },
        (data) => {
          switch (data.returnCode) {
            case 3:
              if (data.payload.ticket.id) {
                setConfirmDialogData(data.payload.ticket.id);
                setShowConfirmDialog(true);
              } else {
                dispatch(setMessage(getStringByTheme('serverError', lang, theme)));
              }
              break;
            default:
              dispatch(setMessage(data.message));
          }
        },
        store
      );
    }
  };

  const handleHomeButtonClick = () => {
    dispatch(resetTicket());
    dispatch(setRequestParams(RESERVATION_REQUEST_PARAMS));
    history.push(`/${lang}` + PATH.RESERVATION_DATE + params.siteId);
  };

  return (
    <LanguageWrapper>
      {reservationConfig && requestParams.reservationTimestamp && 
        <>
          <div className="reservation-wrapper">
            <Header
              site={reservationConfig.siteInfo}
              showSiteInfoGuluLogo={false}
            />
            <div className="reservation-wrapper-content preview-wrapper">
              <QueueTitle
                title={getStringByTheme('selectPreview', lang, theme)}
              />
              <div className="preview-content">
                {(reservationConfig.mode === RESERVATION_MODE.SIZE || reservationConfig.mode === RESERVATION_MODE.SERVICE_WITH_SIZE) && (
                  <div className="step-info">
                    <div className="preview-title">
                      {getStringByTheme('tableSize', lang, theme)}
                    </div>
                    <div className="preview-text">{requestParams.size}</div>
                  </div>
                )}
                {(reservationConfig.mode === RESERVATION_MODE.SERVICE || reservationConfig.mode === RESERVATION_MODE.SERVICE_WITH_SIZE) && (
                  <div className="step-info">
                    <div className="preview-title">{reservationConfig.tableTypePreviewTitle}</div>
                    <div className="preview-text">{requestParams.displayTableType?.tableTypeName}</div>
                  </div>
                )}
                <div className="step-info">
                  <div className="preview-title">{getStringByTheme('date', lang, theme)}</div>
                  <div className="preview-text">{moment(requestParams.reservationTimestamp).format('YYYY-MM-DD HH:mm')}</div>
                </div>
                <div className="step-info">
                  <div className="preview-title">
                    {getStringByTheme('personTitle', lang, theme)}
                  </div>
                  <div className="preview-text">
                    {getSurnameString(
                      requestParams.surname,
                      requestParams.gender,
                      lang,
                      theme
                    )}
                  </div>
                </div>
                <div className="step-info">
                  <div className="preview-title">{getStringByTheme('phone', lang, theme)}</div>
                  <div className="preview-text">{requestParams.countryCode + ' ' + requestParams.mobile}</div>
                </div>
                {requestParams.email && (
                  <div className="step-info">
                    <div className="preview-title">
                      {getStringByTheme('email', lang, theme)}
                    </div>
                    <div className="preview-text">{requestParams.email}</div>
                  </div>
                )}
                {previewSurveyDetail()}
                <div className="tnc-wrapper">
                  <QueueTitle title={getStringByTheme('tnc', lang, theme)} />
                  <div
                    className="tnc-content"
                    dangerouslySetInnerHTML={{
                      __html: reservationConfig.termsAndConditions,
                    }}
                  ></div>
                  <div className="tnc-title" onClick={handleTncClick}>
                    <div className="checkbox-wrapper">{requestParams.ticketTncAgreed && <CheckIcon className="check-icon" />}</div>
                    {getStringByTheme('agree', lang, theme) + getStringByTheme('tnc', lang, theme)}
                  </div>
                </div>
              </div>
            </div>
            <Header showGuluLogo />
          </div>
          <div className="reservation-bottom-btn-wrapper">
            <div onClick={handleReservation} className={'reservation-button next' + (!valid ? ' disable' : '')}>
              {/* {getStringByTheme('reserve', lang, theme)} */}
              <ArrowButtonIcon className="arrow prev" />
            </div>
          </div>
        </>
      }
      {showConfirmDialog && (
        <ConfirmDialog
          message={getStringByTheme('reservedAlready', lang, theme)}
          confirmButtonText="navigateToTicketDetailPage"
          handleConfirm={() => {
            setShowConfirmDialog(false);
            history.push(`/${lang}` + PATH.RESERVATION_TICKET + confirmDialogData);
          }}
          handleCancel={() => {
            setShowConfirmDialog(false);
          }}
        />
      )}
      {(!reservationConfig || !requestParams.reservationTimestamp) && (
        <div className="reservation-single-button-wrapper">
          <div className="single-button" onClick={handleHomeButtonClick}>
            {getStringByTheme('returnToSiteDetail', lang, theme)}
          </div>
        </div>
      )}
    </LanguageWrapper>
  );
};

export default ReservationPreview;
