import { createSlice } from '@reduxjs/toolkit'

export const ticketSlice = createSlice({
  name: 'ticket',
  initialState: {
    ticket: undefined,
    siteInfo: undefined,
    surveyInfo: undefined,
    userDetail: undefined
  },
  reducers: {
    setTicket(state, action) {
      state.ticket = action.payload;
    },
    setSiteInfo(state, action) {
      state.siteInfo = action.payload;
    },
    setSurveyInfo(state, action) {
      state.surveyInfo = action.payload;
    },
    setUserDetail(state, action) {
      state.userDetail = action.payload;
    },
    resetTicket(state, action) {
      state.ticket = undefined;
      state.siteInfo = undefined;
      state.surveyInfo = undefined;
      state.userDetail = undefined;
    }
  }
})

export const { setTicket, setSiteInfo, setSurveyInfo, setUserDetail, resetTicket } = ticketSlice.actions

export default ticketSlice.reducer