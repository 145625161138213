import React from 'react';
import { useSelector } from 'react-redux';

import { getStringByTheme } from '../utils/utils';
import '../styles/gulu-logo.scss';
import { LANG } from '../constants/constants';

export const GuluLogo = ({
  show,
  showLang = false,
  handleLangClick,
  showPoweredBy = false,
}) => {
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  return (
    <div className={`gulu-logo-wrapper ${!show && !showLang ? 'hidden' : ''}`}>
      {showPoweredBy && (
        <div className="powered-by-wrapper">Powered By THE GULU</div>
      )}
      {show && (
        <div className="image-wrapper">
          <div className="gulu-logo">
            <>
              <img
                className="image"
                src={process.env.PUBLIC_URL + '/images/gulu-logo.png'}
                alt="thegulu"
              />
              <img
                className="image image-bottom logo-blink"
                src={process.env.PUBLIC_URL + '/images/gulu-logo-reverse.png'}
                alt="thegulu"
              />
            </>
          </div>
        </div>
      )}
      {showLang && (
        <div className="lang-wrapper">
          {lang !== LANG.TC && (
            <div
              className="lang-button"
              onClick={() => {
                handleLangClick(LANG.TC);
              }}
            >
              {getStringByTheme('langButton', LANG.TC, theme)}
            </div>
          )}
          {lang !== LANG.SC && (
            <div
              className="lang-button"
              onClick={() => {
                handleLangClick(LANG.SC);
              }}
            >
              {getStringByTheme('langButton', LANG.SC, theme)}
            </div>
          )}
          {lang !== LANG.EN && (
            <div
              className="lang-button"
              onClick={() => {
                handleLangClick(LANG.EN);
              }}
            >
              {getStringByTheme('langButton', LANG.EN, theme)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
