import { WaitingSection } from './WaitingSection/WaitingSection';
import '../../styles/transferable-ticket.scss';
import { RecordSection } from './RecordSection/RecordSection';
import { CallingSection } from './CallingSection/CallingSection';
import { useState } from 'react';
import { useEffect } from 'react';
import { STATUS } from '../../constants/constants';

export const TransferableTicketDetailWrapper = ({
  ticketTransferDetailList,
  tableType,
  tableTypeColor,
}) => {
  const [callTicketList, setCallTicketList] = useState([]);
  const [waitingTicketList, setWaitingTicketList] = useState([]);
  const [recordTicketList, setRecordTicketList] = useState([]);

  useEffect(() => {
    const tempCallTicketList = [];
    const tempWaitingTicketList = [];
    const tempRecordTicketList = [];
    ticketTransferDetailList?.forEach((ticket) => {
      if (ticket.deleteTimestamp || ticket.status === STATUS.DEACTIVE) {
        return;
      }

      if (ticket.checkOutTimestamp || ticket.checkInTimestamp) {
        tempRecordTicketList.push(ticket);
      } else if (ticket.callTimestamp) {
        tempCallTicketList.push(ticket);
      } else {
        tempWaitingTicketList.push(ticket);
      }
    });

    setCallTicketList(
      tempCallTicketList.sort((a, b) => b.callTimestamp - a.callTimestamp)
    );
    setWaitingTicketList(
      tempWaitingTicketList.sort(
        (a, b) => b.createTimestamp - a.createTimestamp
      )
    );
    setRecordTicketList(
      tempRecordTicketList.sort(
        (a, b) =>
          (b.checkOutTimestamp ? b.checkOutTimestamp : b.checkInTimestamp) -
          (a.checkOutTimestamp ? a.checkOutTimestamp : a.checkInTimestamp)
      )
    );
  }, [ticketTransferDetailList]);

  return (
    ticketTransferDetailList?.length > 0 && (
      <div className="transferable-ticket-detail-wrapper">
        <div className="dividend-line"></div>
        <CallingSection callTicketList={callTicketList}/>
        <WaitingSection
          waitingTicketList={waitingTicketList}
          tableType={tableType}
          tableTypeColor={tableTypeColor}
        />
        <RecordSection
          recordTicketList={recordTicketList}
          tableType={tableType}
          tableTypeColor={tableTypeColor}
        />
        <div className="dividend-line"></div>
      </div>
    )
  );
};
