import { getStringByTheme } from '../../../utils/utils';
import { WaitingItem } from './WaitingItem';
import { useSelector } from 'react-redux';

export const WaitingSection = ({ waitingTicketList, tableType, tableTypeColor }) => {
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  return (
    waitingTicketList?.length > 0 && (
      <div className="waiting-section-wrapper">
        <div className="waiting-section-title">
          {getStringByTheme('waiting', lang, theme)}
        </div>
        {waitingTicketList.map((item) => (
          <WaitingItem key={item.id} item={item} tableType={tableType} tableTypeColor={tableTypeColor}/>
        ))}
      </div>
    )
  );
};
