import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { API_URLS } from '../constants/apiUrls';
import { getOne } from '../utils/baseFetch';
import { setPickup, setImage } from '../reducers/pickupSlice';
import { subscribeMqttTopic, unsubscribeMqttTopic } from '../reducers/systemSlice';

import '../styles/pickup.scss';
import { usePrevious } from '../utils/hooks';
import { getStringByTheme } from '../utils/utils';
import { LANG, MQTT } from '../constants/constants';
import { LanguageWrapper } from '../components/LanguageWrapper';

const Pickup = () => {
  const params = useParams();
  const previousParams = usePrevious(params, {});
  const dispatch = useDispatch();
  const pickup = useSelector((state) => state.pickup.pickup);
  const image = useSelector((state) => state.pickup.image);
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);
  const store = useStore();

  const latestTag = useSelector((state) => state.pickup.latestTag);

  useEffect(() => {
    document.title = getStringByTheme('pickupNumber', lang, theme);

    return function unsubscribeMqtt() {
      dispatch(unsubscribeMqttTopic([MQTT.VERSION, params.siteId, MQTT.CHANNEL, MQTT.PICKUP, MQTT.GENERAL].join('/')));
    };
  }, [dispatch, lang, params.siteId]);

  useEffect(() => {
    if (!lang) {
      return;
    }

    if (previousParams.siteId) {
      dispatch(unsubscribeMqttTopic([MQTT.VERSION, previousParams.siteId, MQTT.CHANNEL, MQTT.PICKUP, MQTT.GENERAL].join('/')));
    }

    dispatch(subscribeMqttTopic([MQTT.VERSION, params.siteId, MQTT.CHANNEL, MQTT.PICKUP, MQTT.GENERAL].join('/')));

    getOne(
      API_URLS.PICKUP + params.siteId,
      undefined,
      (payload) => {
        dispatch(setPickup(payload.pickupTagList));
        dispatch(setImage(payload.image));
      },
      undefined,
      store
    );
  }, [params.siteId, previousParams.siteId, dispatch, store]);

  return (
    <LanguageWrapper>
      <div className="pickup-wrapper">
        <div className="pickup-content-wrapper">
          <div className="pickup-content">
            <div className="title-wrapper">
              <div className="rest-logo">
                <img className="image" src={image} alt="" />
              </div>
              <div className="title">
                <div className="text">
                  <div className="text-zh">
                    <img className="pickup-logo" src={process.env.PUBLIC_URL + '/images/pickup-logo.png'} alt="pickup" />
                    <div>{getStringByTheme('pickupNumber', LANG.TC, theme)}</div>
                  </div>
                  <div>{getStringByTheme('pickupNumber', LANG.EN, theme)}</div>
                </div>
              </div>
              <div className="pickup-gulu-logo"></div>
            </div>
            <div className="middle-line" />
            <div className="pickup-list">
              {pickup &&
                pickup.map((pickupNumber, index) => {
                  return (
                    <div key={index} className={'text' + (pickupNumber.tag === latestTag ? ' blink' : '')}>
                      {pickupNumber.tag}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="pickup-gulu-logo">
          <img className="image" src={process.env.PUBLIC_URL + '/images/gulu-logo.png'} alt="thegulu" />
          <img className="image image-bottom logo-blink" src={process.env.PUBLIC_URL + '/images/gulu-logo-reverse.png'} alt="thegulu" />
        </div>
      </div>
    </LanguageWrapper>
  );
};

export default Pickup;
