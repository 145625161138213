import { createSlice } from '@reduxjs/toolkit'

export const siteSlice = createSlice({
  name: 'site',
  initialState: {
    site: undefined,
    rejectReasonMessage: ''
  },
  reducers: {
    setSite(state, action) {
      state.site = action.payload;
    },
    setRejectReasonMessage(state, action) {
      state.rejectReasonMessage = action.payload;
    }
  }
})

export const { setSite, setRejectReasonMessage } = siteSlice.actions

export default siteSlice.reducer