import _ from 'lodash';
import moment from 'moment';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import {
  setTicket,
  setSiteInfo,
  setSurveyInfo,
  setUserDetail,
} from '../reducers/ticketSlice';
import { subscribeMqttTopic } from '../reducers/systemSlice';
import { setTagSequenceList, setCounterTagList, setSubscribedTicket, setSubscribedTicketCallTimestamp } from '../reducers/queueSlice';
import { STATUS, TICKET_DISPLAY_TYPE, MQTT, DEFAULT_TABLE_TYPE_COLOR } from '../constants/constants';

export const mergeReservationConfig = (reservationConfig, payload) => {
  let newConfig = _.cloneDeep(reservationConfig);
  if (!reservationConfig) {
    newConfig = payload;
  } else {
    if (!newConfig.termsAndConditions) {
      newConfig.termsAndConditions = payload.termsAndConditions;
    }
    newConfig.siteInfo = payload.siteInfo;
    newConfig.queueAvailable = payload.queueAvailable;
    newConfig.timeSessionList = payload.timeSessionList;
    newConfig.tableTypePreviewTitle = payload.tableTypePreviewTitle ? payload.tableTypePreviewTitle : newConfig.tableTypePreviewTitle;
    newConfig.tableTypeTitle = payload.tableTypeTitle ? payload.tableTypeTitle : newConfig.tableTypeTitle;
    newConfig.tableSizeList = payload.tableSizeList?.length > 0 ? payload.tableSizeList : newConfig.tableSizeList;
    newConfig.surveyDetail = payload.surveyDetail ? payload.surveyDetail : newConfig.surveyDetail;
  }

  newConfig.timeInterval = payload.timeInterval * 60 * 1000;
  return newConfig;
};

export const getReservationTicket = (params, dispatch, store) => {
  post(
    API_URLS.RESERVATION_TICKET,
    params,
    (payload) => {
      if (payload) {
        const { siteInfo, surveyInfo, ticket, userDetail } = payload;
        if (ticket.callTimestamp) {
          dispatch(setSubscribedTicketCallTimestamp(ticket.callTimestamp));
        }
        dispatch(setTicket({...ticket, tableTypeForegroundColor: ticket.tableTypeForegroundColor || DEFAULT_TABLE_TYPE_COLOR}));
        dispatch(setSiteInfo(siteInfo));
        dispatch(setSurveyInfo(surveyInfo));
        dispatch(setUserDetail(userDetail ? userDetail : undefined));
        dispatch(setSubscribedTicket(ticket));
        if (siteInfo && siteInfo.siteId && ticket.displayType !== TICKET_DISPLAY_TYPE.EVENT) {
          if (ticket.displayType === TICKET_DISPLAY_TYPE.RESERVATION) {
            dispatch(subscribeMqttTopic([MQTT.VERSION, siteInfo.siteId, MQTT.CHANNEL, MQTT.RESERVATION, MQTT.TICKET, ticket.id].join('/')));
          } else {
            if (ticket.tagSequence) {
              const tempTagSequence = [];
              tempTagSequence.push(ticket.tagSequence);
              dispatch(
                setTagSequenceList({
                  siteId: siteInfo.siteId,
                  tagSequenceList: tempTagSequence,
                  subscribeExistingTagSequenceListOnly: true,
                })
              );
              dispatch(
                setCounterTagList({
                  counterTagList: ticket.counterTagList ? ticket.counterTagList : [],
                })
              );
            }
            dispatch(subscribeMqttTopic([MQTT.VERSION, siteInfo.siteId, MQTT.CHANNEL, MQTT.QUEUE, ticket.timeSessionId, ticket.tableType].join('/')));
            dispatch(subscribeMqttTopic([MQTT.VERSION, siteInfo.siteId, MQTT.CHANNEL, MQTT.QUEUE, MQTT.TICKET, ticket.id].join('/')));
          }
        }
      }
    },
    undefined,
    store
  );
};

export const generateTimeOptions = (timeSessionList, timeInterval) => {
  const tempTimeOptionGroups = [];

  timeSessionList.forEach((timeSession) => {
    const group = tempTimeOptionGroups.find((group) => {
      return group.groupedSessionId === timeSession.groupedSessionId;
    });
    const tempTimeOptionGroup = !group
      ? {
          groupedSessionId: timeSession.groupedSessionId,
          description: timeSession.description,
          timeOptions: [],
        }
      : group;

    const tempTimeOptions = [];
    for (
      let i = timeSession.startTimestamp;
      i <= timeSession.endTimestamp;
      i += timeInterval
    ) {
      const tempTime = moment(i);
      tempTimeOptions.push({
        value: tempTime.valueOf(),
        label: tempTime.format('HH:mm'),
        haveQuota: timeSession.haveQuota,
      });
    }
    tempTimeOptionGroup.timeOptions =
      tempTimeOptionGroup.timeOptions.concat(tempTimeOptions);
    if (!group) {
      tempTimeOptionGroups.push(tempTimeOptionGroup);
    }
  });

  return tempTimeOptionGroups;
};

export const reservationEditable = (timestamp, status) => {
  const statusList = [
    STATUS.ACTIVE,
    STATUS.PENDING,
    STATUS.WAITING,
    STATUS.HOLD,
  ];
  return Date.now() < timestamp && statusList.includes(status);
};
