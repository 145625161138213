import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { getDisplayReason, mergeQueueConfig } from '../utils/queueUtils';
import { setQueueConfig, setRequestParams } from '../reducers/queueSlice';
import { getAvaliableSectionList } from '../utils/queueUtils';
import { getStringByTheme } from '../utils/utils';
import { QUEUE_STEPS, STATUS } from '../constants/constants';
import { PATH } from '../constants/paths';

import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';
import Header from '../components/Header';

import { ReactComponent as ServiceTypeIcon } from '../images/service-type.svg';

import '../styles/queue-service-type.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';
import { TableTypeDisplayReason } from '../components/TableTypeDisplayReason';

const QueueServiceType = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  const [serviceTypeList, setServiceTypeList] = useState([]);

  useEffect(() => {
    document.title = getStringByTheme('queueServiceType', lang, theme);
  }, [lang]);

  useEffect(() => {
    if (!lang) {
      return;
    }

    if (!queueConfig || (requestParams.selectedDate && !queueConfig.filterResult)) {
      const newParams = _.cloneDeep(requestParams);
      newParams.siteId = params.siteId;
      post(
        API_URLS.QUEUE,
        newParams,
        (payload) => {
          const newConfig = mergeQueueConfig(queueConfig, payload);
          dispatch(setQueueConfig(newConfig));
        },
        undefined,
        store
      );
    }
  }, [params.siteId, dispatch, store, queueConfig, lang]);

  useEffect(() => {
    if (queueConfig && queueConfig.filterResult) {
      const avaliableSectionList = getAvaliableSectionList(queueConfig.steps, QUEUE_STEPS.TABLE_TYPE, requestParams, queueConfig.filterResult);
      const tempMap = new Map();
      avaliableSectionList.forEach((section) => {
        if (!tempMap.has(section.tableType)) {
          tempMap.set(section.tableType, {
            tableType: section.tableType,
            tableTypeLabel: section.tableTypeLabel,
            status: section.status,
            readOnly: section.readOnly,
            hasQuota: section.hasQuota,
            rejectReason: section.rejectReason,
          });
        }
      });
      setServiceTypeList(Array.from(tempMap.values()));
    }
  }, [queueConfig]);

  useEffect(() => {
    if (serviceTypeList.length < 1) return;
    const availableList = serviceTypeList.filter((item) => item.status === STATUS.ACTIVE);
    if (availableList?.length === 1 && availableList[0].hasQuota && !availableList[0].readOnly) {
      // console.log(availableList[0]);
      if (availableList[0].tableType !== requestParams.tableType) handleServiceTypeChange(availableList[0]);
    }
  }, [serviceTypeList]);

  function handleServiceTypeChange(serviceType, disabled) {
    if (disabled) return;
    const newParams = _.cloneDeep(requestParams);
    newParams.tableType = serviceType.tableType;
    dispatch(setRequestParams(newParams));
  }

  function handleSiteDetailButtonClick() {
    history.push(`/${lang}` + PATH.SITE_DETAIL + params.siteId);
  }

  const getLabelByTheme = () => {
    return getStringByTheme('selectServiceType', lang, theme);
  };

  const getLabelPrefix = () => {
    return getStringByTheme('activity', lang, theme);
  };

  const getServiceTypeLabelByTheme = (string) => {
    return string.replace('%replace%', getStringByTheme('tableType', lang, theme));
  };

  return (
    <LanguageWrapper>
      {queueConfig && (
        <>
          <div className="queue-wrapper">
            <Header site={queueConfig.siteInfo} showSiteInfoGuluLogo={false} />
            <div className="queue-wrapper-content queue-service-type-wrapper">
              {serviceTypeList?.length > 0 ? (
                <>
                  <QueueTitle title={getLabelByTheme()} />
                  {serviceTypeList.map((serviceType, index) => {
                    const disabled = serviceType.status !== STATUS.ACTIVE || !serviceType.hasQuota || serviceType.readOnly;
                    return (
                      <div className="service-type-radio-wrapper" key={index}>
                        <div className={'service-type-radio' + (disabled ? ' disable' : '')} onClick={() => handleServiceTypeChange(serviceType, disabled)}>
                          <input
                            type="radio"
                            disabled={disabled}
                            id={serviceType.tableType}
                            name="serviceType"
                            checked={serviceType.tableType === requestParams.tableType}
                            onChange={() => handleServiceTypeChange(serviceType, disabled)}
                          />
                          <ServiceTypeIcon className="image" />
                          <label htmlFor={serviceType.tableType}>
                            {getLabelPrefix()}
                            {serviceType.tableTypeLabel}
                          </label>
                        </div>
                        <TableTypeDisplayReason serviceType={serviceType} lang={lang} theme={theme} />
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="queue-title">{`${getServiceTypeLabelByTheme(getStringByTheme('currentNoAvailable', lang, theme))}`}</div>
              )}
            </div>
            <Header showGuluLogo />
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.TABLE_TYPE} />
        </>
      )}
      {!queueConfig && (
        <div className="queue-single-button-wrapper">
          <div className="single-button" onClick={handleSiteDetailButtonClick}>
            {getStringByTheme('returnToSiteDetail', lang, theme)}
          </div>
        </div>
      )}
    </LanguageWrapper>
  );
};

export default QueueServiceType;
