import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { mergeQueueConfig } from '../utils/queueUtils';
import { setQueueConfig, setRequestParams } from '../reducers/queueSlice';
import {
  validateEmail,
  validateMobile,
  getStringByTheme
} from '../utils/utils';
import {
  PERSONAL_DATAS,
  QUEUE_STEPS,
  DELIVERY_TYPE,
} from '../constants/constants';
import { PATH } from '../constants/paths';

import Header from '../components/Header';
import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';

import { ReactComponent as ArrowIcon } from '../images/arrow.svg';

import '../styles/queue-personal-info.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';

const QueuePersonalInfo = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [personalDataList, setPersonalDataList] = useState([]);

  useEffect(() => {
    document.title = getStringByTheme('queuePersonalInfo', lang, theme);
  }, [lang]);

  useEffect(() => {

    if(!lang) {
      return;
    }

    if (!queueConfig || (requestParams.selectedDate && !queueConfig.filterResult)) {
      const newParams = _.cloneDeep(requestParams);
      newParams.siteId = params.siteId;
      post(
        API_URLS.QUEUE,
        newParams,
        (payload) => {
          const newConfig = mergeQueueConfig(queueConfig, payload);
          dispatch(setQueueConfig(newConfig));
        },
        undefined,
        store
      );
    }
  }, [params.siteId, dispatch, store, lang]);

  useEffect(() => {
    if (queueConfig && queueConfig.deliveryType) {
      const tempPersonalDataList = [];
      if (queueConfig.deliveryType === DELIVERY_TYPE.EMAIL) {
        tempPersonalDataList.push(
          <div className="input-text-wrapper" key={DELIVERY_TYPE.EMAIL}>
            <input
              className={`input-text ${emailError ? 'error' : ''}`}
              onChange={(item) => {
                handlePersonalInfoChange(item, PERSONAL_DATAS.EMAIL);
              }}
              type="text"
              id="email"
              name="email"
              placeholder={getStringByTheme('emailTitle', lang, theme)}
              value={requestParams.personalData.email}
            />
            <div className="error-message email-error-message">
              {emailError}
            </div>
          </div>
        );
      }
      if (queueConfig.deliveryType === DELIVERY_TYPE.SMS || queueConfig.deliveryType === DELIVERY_TYPE.WHATSAPP) {
        tempPersonalDataList.push(
          <div className="drop-down-text-wrapper" key={queueConfig.deliveryType}>
            <div className={`drop-down-text ${phoneError ? "error" : ""}`}>
              <div className="drop-down-wrapper">
                <select
                  value={requestParams.personalData.countryCode}
                  onChange={(item) => {
                    handlePersonalInfoChange(item, PERSONAL_DATAS.COUNTRY_CODE);
                  }}
                  className="drop-down-select"
                  id="countryCode"
                  name="countryCode"
                >
                  <option value="852">+852</option>
                  {/* <option value="853">+853</option> */}
                  {/* <option value="86">+86</option> */}
                </select>
                <ArrowIcon className="image " />
              </div>
              <input
                value={requestParams.personalData.mobile}
                type="text"
                onChange={(item) =>
                  handlePersonalInfoChange(item, PERSONAL_DATAS.MOBILE)
                }
                className="text-input"
                id="phone"
                name="phone"
                placeholder={getStringByTheme('phoneTitle', lang, theme)}
              />
            </div>
            <div className="error-message phone-error-message">
              {phoneError}
            </div>
          </div>
        );
      }
      setPersonalDataList(tempPersonalDataList);
    }
  }, [queueConfig, requestParams, lang, emailError, phoneError]);

  function handlePersonalInfoChange(personalInfo, type) {
    const newParams = _.cloneDeep(requestParams);
    switch (type) {
      case PERSONAL_DATAS.EMAIL:
        newParams.personalData.email = personalInfo.target.value;
        setEmailError(
          personalInfo.target.value === '' ||
            validateEmail(personalInfo.target.value)
            ? ''
            : getStringByTheme('emailError', lang, theme)
        );
        break;
      case PERSONAL_DATAS.COUNTRY_CODE:
        newParams.personalData.countryCode = personalInfo.target.value;
        break;
      case PERSONAL_DATAS.MOBILE:
        newParams.personalData.mobile = personalInfo.target.value;
        setPhoneError(
          personalInfo.target.value === '' ||
            validateMobile(personalInfo.target.value)
            ? ''
            : getStringByTheme('phoneError', lang, theme)
        );
        break;
      case PERSONAL_DATAS.TAC:
        newParams.personalData.tncAgreed = !newParams.personalData.tncAgreed;
        break;
      default:
    }
    dispatch(setRequestParams(newParams));
  }

  function handleSiteDetailButtonClick() {
    history.push( `/${lang}` + PATH.SITE_DETAIL + params.siteId);
  }

  return (
    <LanguageWrapper>
      {
        queueConfig && 
        <>
          <div className="queue-wrapper">
            <Header site={queueConfig.siteInfo} showSiteInfoGuluLogo={false} />
            <div className="queue-wrapper-content queue-personal-info-wrapper">
              <QueueTitle
                title={getStringByTheme('selectPersonalInfo', lang, theme)}
              />
              <div className="personal-info-wrapper">{personalDataList}</div>
            </div>
            <Header showGuluLogo />
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.PERSONAL_DATA} />
        </>
      }
      {!queueConfig && (
        <div className="queue-single-button-wrapper">
          <div className="single-button" onClick={handleSiteDetailButtonClick}>
            {getStringByTheme('returnToSiteDetail', lang, theme)}
          </div>
        </div>
      )}
    </LanguageWrapper>
  );
};

export default QueuePersonalInfo;
