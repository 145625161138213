import ReactGA from 'react-ga4';

import { setLoading, setMessage, setTheme, setHttpStatusMessage } from '../reducers/systemSlice';
import { LANG } from '../constants/constants';
import { API_URLS } from '../constants/apiUrls';
import { getStringByTheme } from '../utils/utils';

const httpStatusCodeError = 'status code !== 200';

const handleError = (error, path, siteId, store) => {
  ReactGA.event({
    category: 'API Call',
    action: `${process.env.REACT_APP_API_ROOT_URL}` + path,
    label: JSON.stringify({
      error: 'Unexpected error',
      siteId: siteId
    })
  });

  if (error === httpStatusCodeError) {
    const dispatch = store.dispatch;
    const lang = store.getState().system.lang;
    const theme = store.getState().system.theme;
    dispatch(setHttpStatusMessage(getStringByTheme('serverError', lang, theme)));
  }
}

export const getOne = (path, dataParam, successCallback, failCallback, store) => {
  const dispatch = store.dispatch;
  const lang = store.getState().system.lang;
  const token = store.getState().system.token;
  const theme = store.getState().system.theme;
  dispatch(setLoading(true));

  const apiDomain = `${process.env.REACT_APP_API_ROOT_URL}`;

  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept-Language': lang ? lang : LANG.TC,
    'X-LANG': lang ? lang : LANG.TC,
  };
  if (token) {
    headers['X-AUTH-TOKEN'] = token;
  }

  let queryString = '';
  if (dataParam) {
    const keys = Object.keys(dataParam);
    if (keys.length > 0) {
      queryString = '?' + keys.map((key) => {
        
        if(Array.isArray(dataParam[key])){

          let tempt = ''

          for(let i =0; i<dataParam[key].length; i++) {

            const item = dataParam[key][i]

            tempt += key + '=' + item

            if(i < dataParam[key].length - 1) {
              tempt += '&'
            }

          }
          
          return tempt;
        }

        return key + '=' + dataParam[key]
      }).join('&');
    }
  }

  fetch(apiDomain + path + queryString, {
    method: 'GET',
    headers: headers,
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          statusCode: res.status,
          siteId: (dataParam && dataParam.siteId) ? dataParam.siteId : ''
        })
      })
      return Promise.reject(httpStatusCodeError);
    }
  }).then((data) => {
    // console.log("data", data);
    if (data.returnCode === 0) {
      successCallback(data.payload);
      if (path === API_URLS.GET_TV_DETAIL && data.payload) {
        dispatch(setTheme((data.payload.siteInfo?.theme) ? data.payload.siteInfo.theme : ''));
      }
    } else if (data.returnCode > 0) {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          siteId: (dataParam && dataParam.siteId) ? dataParam.siteId : ''
        })
      })
      if (failCallback) {
        failCallback(data.message);
      } else {
        dispatch(setMessage(data.message));
      }
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          siteId: (dataParam && dataParam.siteId) ? dataParam.siteId : ''
        })
      })
      dispatch(setMessage(getStringByTheme('serverError', lang, theme)));
    }
  }).catch((error) => {
    handleError(error, path, (dataParam && dataParam.siteId) ? dataParam.siteId : '', store);
  }).finally(() => {
    dispatch(setLoading(false));
  });
}

export const post = (path, dataParam, successCallback, failCallback, store) => {
  const dispatch = store.dispatch;
  const lang = store.getState().system.lang;
  const token = store.getState().system.token;
  const theme = store.getState().system.theme;
  dispatch(setLoading(true));

  const apiDomain = `${process.env.REACT_APP_API_ROOT_URL}`;

  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept-Language': lang ? lang : LANG.TC,
    'X-LANG': lang ? lang : LANG.TC,
  };
  if (token) {
    headers['X-AUTH-TOKEN'] = token;
  }

  fetch(apiDomain + path, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(dataParam)
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          statusCode: res.status,
          siteId: dataParam.siteId
        })
      })
      return Promise.reject(httpStatusCodeError);
    }
  }).then((data) => {
    // console.log("data", data);
    if (data.returnCode === 0) {
      if (path === API_URLS.SITE && data.payload) {
        dispatch(setTheme((data.payload.theme) ? data.payload.theme : ''));
      } else if ((path === API_URLS.QUEUE || path === API_URLS.TICKET || path === API_URLS.RESERVATION_TICKET || path === API_URLS.RESERVATION_CONFIG) && data.payload && data.payload.siteInfo) {
        dispatch(setTheme((data.payload.siteInfo.theme) ? data.payload.siteInfo.theme : ''));
      }
      if (path === API_URLS.QUEUE && data.payload && data.payload.ticketRejectReason) {
        dispatch(setMessage(data.payload.ticketRejectReason));
      } else {
        successCallback(data.payload);
      }
    } else if (data.returnCode > 0) {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          siteId: dataParam.siteId
        })
      })
      if (failCallback) {
        failCallback(data);
      } else {
        dispatch(setMessage(data.message));
      }
    } else {
      ReactGA.event({
        category: 'API Call',
        action: apiDomain + path,
        label: JSON.stringify({
          returnCode: data.returnCode,
          siteId: dataParam.siteId
        })
      })
      dispatch(setMessage(getStringByTheme('serverError', lang, theme)));
    }
  }).catch((error) => {
    handleError(error, path, dataParam.siteId, store);
  }).finally(() => {
    dispatch(setLoading(false));
  });
}