import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import Header from '../components/Header';

import { getStringByTheme } from '../utils/utils';
import { DELIVERY_TYPE } from '../constants/constants';
import { PATH } from '../constants/paths';

import '../styles/queue-request-ticket-success.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';

const QueueRequestTicketSuccess = () => {
  const params = useParams();
  const history = useHistory();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  useEffect(() => {
    document.title = getStringByTheme('queueRequestTicketSuccess', lang, theme);
  }, [lang]);

  function handleSiteDetailButtonClick() {
    history.push('/' + lang + PATH.SITE_DETAIL + params.siteId);
  }

  return (
    <LanguageWrapper>
      {queueConfig && (
        <>
          <div className="queue-wrapper">
            <Header site={queueConfig.siteInfo} showSiteInfoGuluLogo={false} />
            <div className="queue-wrapper-content queue-request-ticket-success-wrapper">
              <div className="success">
                {getStringByTheme('success', lang, theme)}
              </div>
              <div className="sent">
                <span>{getStringByTheme('sent', lang, theme)}</span>
                {queueConfig.deliveryType === DELIVERY_TYPE.EMAIL && (
                  <span className="email">
                    {requestParams.personalData.email}
                  </span>
                )}
                {queueConfig.deliveryType === DELIVERY_TYPE.SMS && (
                  <>
                    <span className="email">
                      {'+' +
                        requestParams.personalData.countryCode +
                        requestParams.personalData.mobile}
                    </span>
                    <div className="reminder">
                      {getStringByTheme('smsReminder', lang, theme)}
                    </div>
                  </>
                )}
                {queueConfig.deliveryType === DELIVERY_TYPE.WHATSAPP && (
                  <>
                    <span className="email">
                      {'+' +
                        requestParams.personalData.countryCode +
                        requestParams.personalData.mobile}
                    </span>
                    <div className="reminder">
                      {getStringByTheme('whatsappReminder', lang, theme)}
                    </div>
                  </>
                )}
              </div>
            </div>
            <Header showGuluLogo />
          </div>
        </>
      )}
      {!queueConfig && (
        <div className="queue-single-button-wrapper">
          <div className="single-button" onClick={handleSiteDetailButtonClick}>
            {getStringByTheme('returnToSiteDetail', lang, theme)}
          </div>
        </div>
      )}
    </LanguageWrapper>
  );
};

export default QueueRequestTicketSuccess;
