export const API_URLS = {
  SITE_LIST: 'web/queue/getFolder/',
  SITE: 'web/queue/getSiteDetail/',
  QUEUE: 'web/queue/getQueueConfig/',
  REQUEST_TICKET: 'web/queue/requestTicket/',
  TICKET: 'web/queue/getTicketDetail/',
  PICKUP: 'web/pickup/getPickupTagList/',
  REQUEST_EMAIL_TICKET_DELIVERY: 'web/queue/deliveryTicket/',
  QUEUE_DELETE_TICKET: 'web/queue/deleteTicket',
  RESERVATION_CONFIG: 'web/reservation/getReservationConfig/',
  REQUEST_RESERVATION: 'web/reservation/requestTicket/',
  RESERVATION_TICKET: 'web/reservation/getTicketDetail/',
  RESERVATION_DELETE_TICKET: 'web/reservation/deleteTicket',
  RESERVATION_UPDATE: 'web/reservation/updateReservation',
  GET_TV_DETAIL: 'web/queue/getTvDetail',
  SUBSCRIBE_NOTIFICATION: 'web/queue/subscribeNotification',
  REQUEST_OTP: 'web/requestOtp',
  VERIFY_OTP: 'web/verifyOtp'
}