import { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { getStringByTheme } from '../utils/utils';
import {
  mergeReservationConfig,
  generateTimeOptions,
} from '../utils/reservationUtils';
import { setMessage } from '../reducers/systemSlice';

import { QueueTitle } from '../components/QueueTitle';
import Calendar from '../components/Calendar';

import '../styles/reservation-edit-dialog.scss';

export const ReservationEditDialog = ({
  siteId,
  reservation,
  handleConfirm,
  handleCancel,
}) => {
  const dispatch = useDispatch();
  const store = useStore();

  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  const [requestParams, setRequestParams] = useState({
    siteId: siteId,
    editTicketId: reservation.id,
    reservationDate: moment(reservation.startTimestamp).format('YYYY-MM-DD'),
    selectedTime: reservation.startTimestamp,
    reservationTimestamp: reservation.startTimestamp,
    size: reservation.size,
    refTableTypeId: reservation.refTableTypeId,
  });
  const [reservationConfig, setReservationConfig] = useState(undefined);
  const [calendarDisplayDate, setCalendarDisplayDate] = useState(
    moment().set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
  );
  const [timeOptionGroup, setTimeOptionGroups] = useState([]);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (reservationConfig) {
      if (requestParams.reservationDate && requestParams.selectedTime) {
        setValid(true);
      } else {
        setValid(false);
      }
    }
  }, [requestParams]);

  useEffect(() => {
    post(
      API_URLS.RESERVATION_CONFIG,
      requestParams,
      (payload) => {
        const newConfig = mergeReservationConfig(reservationConfig, payload);

        let displayDate = moment();
        if (requestParams.reservationDate) {
          displayDate = moment(requestParams.reservationDate);
        } else if (newConfig.startTimestamp) {
          displayDate = moment(newConfig.startTimestamp);
        }
        setCalendarDisplayDate(
          displayDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        );

        if (
          requestParams.reservationDate &&
          newConfig.timeSessionList &&
          newConfig.timeInterval
        ) {
          setTimeOptionGroups(
            generateTimeOptions(
              newConfig.timeSessionList,
              newConfig.timeInterval
            )
          );
        }

        setReservationConfig(newConfig);
      },
      (data) => {
        dispatch(setMessage(data.message));
        setTimeOptionGroups([]);
      },
      store
    );
  }, [requestParams.reservationDate, lang]);

  const handleMonthClick = (displayDate) => {
    setCalendarDisplayDate(
      moment(displayDate).set({
        date: 1,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    );
  };

  const handleDateClick = (selectedDate) => {
    const newParams = _.cloneDeep(requestParams);
    if (selectedDate !== newParams.reservationDate) {
      newParams.reservationDate = selectedDate;
      newParams.selectedTime = '';
      setRequestParams(newParams);
    }
  };

  const handleTimeClick = (selectedTime) => {
    const newParams = _.cloneDeep(requestParams);
    newParams.selectedTime =
      newParams.selectedTime === selectedTime ? '' : selectedTime;
    if (newParams.selectedTime) {
      const selectedDate = moment(newParams.reservationDate);
      const selectedTime = moment(Number(newParams.selectedTime));
      newParams.reservationTimestamp = selectedDate
        .set({
          hour: selectedTime.get('hour'),
          minute: selectedTime.get('minute'),
          second: selectedTime.get('second'),
          millisecond: selectedTime.get('millisecond'),
        })
        .valueOf();
    }
    setRequestParams(newParams);
  };

  const updateReservation = () => {
    if (valid) {
      handleConfirm(requestParams.reservationTimestamp);
    }
  };

  return (
    <>
      {reservationConfig && (
        <div className="reservation-edit-dialog">
          <div className="dialog">
            <QueueTitle
              title={getStringByTheme('updateReservationDate', lang, theme)}
            />
            <QueueTitle title={getStringByTheme('selectDate', lang, theme)} />
            <div className="select-date-wrapper">
              <Calendar
                startDate={
                  reservationConfig.startTimestamp &&
                  moment(reservationConfig.startTimestamp).format('YYYY-MM-DD')
                }
                endDate={
                  reservationConfig.expiredTimestamp &&
                  moment(reservationConfig.expiredTimestamp).format(
                    'YYYY-MM-DD'
                  )
                }
                calendarDisplayDate={calendarDisplayDate}
                disableDateList={reservationConfig.blackoutDateList}
                selectedDate={requestParams.reservationDate}
                requestParams={requestParams}
                handleDateClick={handleDateClick}
                handleMonthClick={handleMonthClick}
              />
            </div>
            <QueueTitle title={getStringByTheme('selectTime', lang, theme)} />
            <div className="select-time-wrapper">
              {!requestParams.reservationDate && (
                <div className="select-time-message">
                  {getStringByTheme('requiredSelectDate', lang, theme)}
                </div>
              )}
              {requestParams.reservationDate && (
                <>
                  {timeOptionGroup.length === 0 ||
                  timeOptionGroup.every((group) =>
                    group.timeOptions?.every((option) => !option.haveQuota)
                  ) ? (
                    <div className="select-time-message">
                      {getStringByTheme('noTimeOption', lang, theme)}
                    </div>
                  ) : (
                    timeOptionGroup.length > 0 &&
                    timeOptionGroup.map((group) => {
                      return (
                        <div
                          key={group.groupedSessionId}
                          className="option-group"
                        >
                          <div className="group-description">
                            {group.description}
                          </div>
                          <div className="options">
                            {group.timeOptions.map((option, optionIndex) => {
                              return (
                                <div
                                  key={optionIndex}
                                  className={`option ${
                                    !option.haveQuota ? 'disable' : ''
                                  } ${
                                    option.value === requestParams.selectedTime
                                      ? 'selected'
                                      : ''
                                  }`}
                                  onClick={() => {
                                    if (option.haveQuota) {
                                      handleTimeClick(option.value);
                                    }
                                  }}
                                >
                                  {option.label}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })
                  )}
                </>
              )}
            </div>
            <div className="dialog-buttons">
              {handleCancel && (
                <div className="dialog-button" onClick={handleCancel}>
                  {getStringByTheme('cancel', lang, theme)}
                </div>
              )}
              {handleConfirm && (
                <div
                  className={`dialog-button ${!valid ? ' disable' : ''} `}
                  onClick={updateReservation}
                >
                  {getStringByTheme('confirmEdit', lang, theme)}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
