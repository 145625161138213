// import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getStringByTheme } from '../utils/utils';

import '../styles/dialog.scss';
// import { THEMES } from '../constants/themes';

export const ConfirmDialog = ({ title, message, handleConfirm, handleCancel, confirmButtonText = 'confirm' }) => {
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  return (
    <div className="dialog-wrapper confirm">
      <div className="dialog">
        {
          title &&
          <div className="dialog-text">{title}</div>
        }
        {
          message &&
          <div className="dialog-text">{message}</div>
        }
        <div className="dialog-line"></div>
        <div className="dialog-buttons">
          {
            handleCancel &&
            <div className="dialog-button" onClick={handleCancel}>{getStringByTheme('cancel', lang, theme)}</div>
          }
          {
            handleConfirm &&
            <div className="dialog-button" onClick={handleConfirm}>{getStringByTheme(confirmButtonText, lang, theme)}</div>
          }
        </div>
      </div>
    </div>
  )
};
