import { createSlice } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { getTagLabel, getTicketLabel } from '../utils/queueUtils';

import { QUEUE_REQUEST_PARAMS } from '../constants/initialStates';
import { DEFAULT_TABLE_TYPE_COLOR } from '../constants/constants';

export const queueSlice = createSlice({
  name: 'queue',
  initialState: {
    queueConfig: undefined,
    requestParams: QUEUE_REQUEST_PARAMS,
    requestTicketSuccessfully: false,
    siteId: '',
    tagSequenceList: [],
    counterTagList: [],
    counterList: [],
    latestTag: '',
    subscribeExistingTagSequenceListOnly: false,
    subscribedTicket: undefined,
    subscribedTicketCallTimestamp: undefined,
    transferrableTicketList: [],
    transferrableTicketUpdateVersion: 0,
  },
  reducers: {
    setQueueConfig(state, action) {
      state.queueConfig = action.payload;
    },
    setRequestParams(state, action) {
      state.requestParams = action.payload;
    },
    setRequestTicketSuccessfully(state, action) {
      state.requestTicketSuccessfully = action.payload;
    },
    setTagSequenceList(state, action) {
      state.subscribeExistingTagSequenceListOnly = action.payload.subscribeExistingTagSequenceListOnly ? true : false;
      const newSiteId = action.payload.siteId;
      const newTagSequenceList = action.payload.tagSequenceList;
      if (!newTagSequenceList || newTagSequenceList.length === 0) {
        state.tagSequenceList = [];
      } else {
        let tempTagSequenceList = [];
        if (newSiteId !== state.siteId) {
          tempTagSequenceList = newTagSequenceList.map((tagSequence) => {
            return {
              timeSectionId: tagSequence.timeSectionId,
              showTableType: tagSequence.showTableType,
              tableType: tagSequence.tableType,
              tableTypeLabel: tagSequence.tableTypeLabel,
              tagStart: tagSequence.start,
              tagEnd: tagSequence.end,
              tagLabel: getTagLabel(tagSequence.showTableType ? tagSequence.tableType : '', tagSequence.start, tagSequence.end, tagSequence.type),
              updateTimestamp: Number(tagSequence.updateTimestamp),
              ticketSequence: tagSequence.ticketSequence,
              ticketLabel: getTicketLabel(tagSequence.showTableType ? tagSequence.tableType : '', tagSequence.ticketSequence),
              ticketUpdateTimestamp: Number(tagSequence.ticketUpdateTimestamp),
              quota: tagSequence.quota,
              mobileQueueAvailable: tagSequence.mobileQueueAvailable,
              rollTagType: tagSequence.type,
              ticketType: tagSequence.ticketType,
              ticketTypeEnLabel: tagSequence.ticketTypeEnLabel,
              ticketTypeScLabel: tagSequence.ticketTypeScLabel,
              ticketTypeTcLabel: tagSequence.ticketTypeTcLabel,
              tableTypeEnLabel: tagSequence.tableTypeEnLabel,
              tableTypeScLabel: tagSequence.tableTypeScLabel,
              tableTypeTcLabel: tagSequence.tableTypeTcLabel,
              ticketTypeBackgroundColor: tagSequence.ticketTypeBackgroundColor,
              ticketTypeTextColor: tagSequence.ticketTypeTextColor,
              tableTypeForegroundColor: tagSequence.tableTypeForegroundColor || DEFAULT_TABLE_TYPE_COLOR,
              tableTypeBackgroundColor: tagSequence.tableTypeBackgroundColor,
              ticketTypeForegroundColor: tagSequence.ticketTypeForegroundColor,
              ticketTypeIconUrl: tagSequence.ticketTypeIconUrl,
            };
          });
        } else {
          tempTagSequenceList = _.cloneDeep(state.tagSequenceList);
          if (tempTagSequenceList) {
            tempTagSequenceList = tempTagSequenceList.filter((tempTag) => {
              return (
                newTagSequenceList.findIndex((a) => {
                  return a.tableType === tempTag.tableType && a.timeSectionId === tempTag.timeSectionId;
                }) !== -1
              );
            });
            newTagSequenceList.forEach((tagSequence) => {
              const tempTagSequence = tempTagSequenceList.find((tempTagSequence) => {
                return tempTagSequence.tableType === tagSequence.tableType && tempTagSequence.timeSectionId === tagSequence.timeSectionId;
              });
              const updateTimestamp = Number(tagSequence.updateTimestamp);
              const ticketUpdateTimestamp = Number(tagSequence.ticketUpdateTimestamp);
              if (tempTagSequence) {
                tempTagSequence.tableTypeLabel = tagSequence.tableTypeLabel;
                if (tempTagSequence.updateTimestamp <= updateTimestamp) {
                  tempTagSequence.showTableType = tagSequence.showTableType;
                  // tempTagSequence.tableTypeLabel = tagSequence.tableTypeLabel;
                  tempTagSequence.tagStart = tagSequence.start;
                  tempTagSequence.tagEnd = tagSequence.end;
                  tempTagSequence.tagLabel = getTagLabel(
                    tagSequence.showTableType ? tagSequence.tableType : '',
                    tagSequence.start,
                    tagSequence.end,
                    tagSequence.type
                  );
                  tempTagSequence.updateTimestamp = updateTimestamp;
                  tempTagSequence.rollTagType = tagSequence.type;
                  tempTagSequence.ticketType = tagSequence.ticketType;
                  tempTagSequence.ticketTypeEnLabel = tagSequence.ticketTypeEnLabel;
                  tempTagSequence.ticketTypeScLabel = tagSequence.ticketTypeScLabel;
                  tempTagSequence.ticketTypeTcLabel = tagSequence.ticketTypeTcLabel;
                  tempTagSequence.tableTypeEnLabel = tagSequence.tableTypeEnLabel;
                  tempTagSequence.tableTypeScLabel = tagSequence.tableTypeScLabel;
                  tempTagSequence.tableTypeTcLabel = tagSequence.tableTypeTcLabel;
                  tempTagSequence.ticketTypeBackgroundColor = tagSequence.ticketTypeBackgroundColor;
                  tempTagSequence.ticketTypeTextColor = tagSequence.ticketTypeTextColor;
                  tempTagSequence.tableTypeForegroundColor = tagSequence.tableTypeForegroundColor;
                  tempTagSequence.tableTypeBackgroundColor = tagSequence.tableTypeBackgroundColor;
                  tempTagSequence.ticketTypeForegroundColor = tagSequence.ticketTypeForegroundColor;
                  tempTagSequence.ticketTypeIconUrl = tagSequence.ticketTypeIconUrl;
                }
                if (tempTagSequence.ticketUpdateTimestamp <= ticketUpdateTimestamp) {
                  tempTagSequence.ticketSequence = tagSequence.ticketSequence;
                  tempTagSequence.ticketLabel = getTicketLabel(tagSequence.showTableType ? tagSequence.tableType : '', tagSequence.ticketSequence);
                  tempTagSequence.ticketUpdateTimestamp = ticketUpdateTimestamp;
                }
              } else if (!tempTagSequence) {
                tempTagSequenceList.push({
                  timeSectionId: tagSequence.timeSectionId,
                  showTableType: tagSequence.showTableType,
                  tableType: tagSequence.tableType,
                  tableTypeLabel: tagSequence.tableTypeLabel,
                  tagStart: tagSequence.start,
                  tagEnd: tagSequence.end,
                  tagLabel: getTagLabel(tagSequence.showTableType ? tagSequence.tableType : '', tagSequence.start, tagSequence.end, tagSequence.type),
                  updateTimestamp: updateTimestamp,
                  ticketSequence: tagSequence.ticketSequence,
                  ticketLabel: getTicketLabel(tagSequence.showTableType ? tagSequence.tableType : '', tagSequence.ticketSequence),
                  ticketUpdateTimestamp: ticketUpdateTimestamp,
                  quota: tagSequence.quota,
                  mobileQueueAvailable: tagSequence.mobileQueueAvailable,
                  rollTagType: tagSequence.type,
                  ticketType: tagSequence.ticketType,
                  ticketTypeEnLabel: tagSequence.ticketTypeEnLabel,
                  ticketTypeScLabel: tagSequence.ticketTypeScLabel,
                  ticketTypeTcLabel: tagSequence.ticketTypeTcLabel,
                  tableTypeEnLabel: tagSequence.tableTypeEnLabel,
                  tableTypeScLabel: tagSequence.tableTypeScLabel,
                  tableTypeTcLabel: tagSequence.tableTypeTcLabel,
                  ticketTypeBackgroundColor: tagSequence.ticketTypeBackgroundColor,
                  ticketTypeTextColor: tagSequence.ticketTypeTextColor,
                  tableTypeForegroundColor: tagSequence.tableTypeForegroundColor || DEFAULT_TABLE_TYPE_COLOR,
                  tableTypeBackgroundColor: tagSequence.tableTypeBackgroundColor,
                  ticketTypeForegroundColor: tagSequence.ticketTypeForegroundColor,
                  ticketTypeIconUrl: tagSequence.ticketTypeIconUrl,
                });
              }
            });
          }
        }
        state.siteId = newSiteId;
        state.tagSequenceList = tempTagSequenceList;
      }
    },
    substituteTagSequenceInList(state, action) {
      const substituteTag = {
        ...action.payload.tagSequence,
      };
      const newSubstitueTag = {
        timeSectionId: substituteTag.timeSectionId,
        showTableType: substituteTag.showTableType,
        tableType: substituteTag.tableType,
        tableTypeLabel: substituteTag.tableTypeLabel,
        tagStart: substituteTag.start,
        tagEnd: substituteTag.end,
        tagLabel: getTagLabel(substituteTag.showTableType ? substituteTag.tableType : '', substituteTag.start, substituteTag.end, substituteTag.type),
        updateTimestamp: substituteTag.updateTimestamp,
        ticketSequence: substituteTag.ticketSequence,
        ticketLabel: getTicketLabel(substituteTag.showTableType ? substituteTag.tableType : '', substituteTag.ticketSequence),
        ticketUpdateTimestamp: substituteTag.ticketUpdateTimestamp,
        quota: substituteTag.quota,
        mobileQueueAvailable: substituteTag.mobileQueueAvailable,
        rollTagType: substituteTag.type,
        ticketType: substituteTag.ticketType,
        ticketTypeEnLabel: substituteTag.ticketTypeEnLabel,
        ticketTypeScLabel: substituteTag.ticketTypeScLabel,
        ticketTypeTcLabel: substituteTag.ticketTypeTcLabel,
        tableTypeEnLabel: substituteTag.tableTypeEnLabel,
        tableTypeScLabel: substituteTag.tableTypeScLabel,
        tableTypeTcLabel: substituteTag.tableTypeTcLabel,
        ticketTypeBackgroundColor: substituteTag.ticketTypeBackgroundColor,
        ticketTypeTextColor: substituteTag.ticketTypeTextColor,
        tableTypeForegroundColor: substituteTag.tableTypeForegroundColor || DEFAULT_TABLE_TYPE_COLOR,
        tableTypeBackgroundColor: substituteTag.tableTypeBackgroundColor,
        ticketTypeForegroundColor: substituteTag.ticketTypeForegroundColor,
        ticketTypeIconUrl: substituteTag.ticketTypeIconUrl,
      };

      state.tagSequenceList = [newSubstitueTag];
    },
    setCounterTagList(state, action) {
      // const newSiteId = action.payload.siteId;
      const newCounterTagList = action.payload.counterTagList;
      const tableTypeDisplayTypeMap = new Map((action.payload.tagList || []).map((tag) => [tag.tableType, tag.type]));
      if (!newCounterTagList || newCounterTagList.length === 0) {
        state.counterTagList = [];
      } else {
        const tempCounterTagList = newCounterTagList.map((counterTag) => {
          return {
            tableType: counterTag.tableType,
            tagLabel: getTagLabel(counterTag.tableType, counterTag.tagNumber, counterTag.tagNumber, tableTypeDisplayTypeMap.get(counterTag.tableType)),
            counterName: counterTag.counterName,
            updateTimestamp: Number(counterTag.updateTimestamp),
          };
        });

        // state.siteId = newSiteId;
        state.counterTagList =
          tempCounterTagList
            .sort((a, b) => {
              return b.updateTimestamp - a.updateTimestamp;
            })
            .slice(0, 3) || [];
      }
    },
    setTagSequenceListByMqtt(state, action) {
      const topicContents = action.payload.topicContents;
      const messageJson = action.payload.messageJson;
      const mqttUpdateTimestamp = Number(messageJson.timestamp);
      const timeSectionId = topicContents[4];
      const tableType = topicContents[5];
      const tagStart = messageJson.from !== undefined ? Number(messageJson.from) : Number(messageJson.to);
      const tagEnd = Number(messageJson.to);
      const tagStartLabel = messageJson.fromTicketLabel;
      const tagEndLabel = messageJson.toTicketLabel;
      if (!isNaN(mqttUpdateTimestamp)) {
        const tempTagSequenceList = _.cloneDeep(state.tagSequenceList);
        const tempTagSequence = tempTagSequenceList.find((tagSequence) => {
          return tagSequence.timeSectionId === timeSectionId && tagSequence.tableType === tableType;
        });
        if (tempTagSequence) {
          if (tempTagSequence.updateTimestamp <= mqttUpdateTimestamp) {
            tempTagSequence.tagStart = tagStart;
            tempTagSequence.tagEnd = tagEnd;
            tempTagSequence.tagLabel = tagStartLabel !== undefined ? tagStartLabel + '~' + tagEndLabel : tagEndLabel;
            tempTagSequence.updateTimestamp = mqttUpdateTimestamp;
          }
        }
        // else if (!state.subscribeExistingTagSequenceListOnly) {
        //   tempTagSequenceList.push({
        //     timeSectionId: timeSectionId,
        //     tableType: tableType,
        //     tagStart: tagStart,
        //     tagEnd: tagEnd,
        //     tagLabel: getTagLabel(tableType, tagStart, tagEnd),
        //     updateTimestamp: mqttUpdateTimestamp
        //   })
        // }
        // console.log('tempTagSequenceList', tempTagSequenceList)
        state.tagSequenceList = tempTagSequenceList;

        if (
          state.subscribedTicket &&
          state.subscribedTicket.timeSectionId === timeSectionId &&
          state.subscribedTicket.tableType === tableType &&
          state.subscribedTicket.ticketNumber >= tagStart &&
          state.subscribedTicket.ticketNumber <= tagEnd
        ) {
          state.subscribedTicketCallTimestamp = mqttUpdateTimestamp;
        }
      }
    },
    setTicketSequenceListByMqtt(state, action) {
      const topicContents = action.payload.topicContents;
      if (state.siteId === topicContents[1]) {
        const messageJson = action.payload.messageJson;
        const mqttUpdateTimestamp = Number(messageJson.timestamp);
        if (!isNaN(mqttUpdateTimestamp)) {
          const timeSectionId = topicContents[4];
          const tableType = topicContents[5];
          const mqttTicketNumber = Number(messageJson.nextTicketNum);
          const ticketNumber = isNaN(mqttTicketNumber) || mqttTicketNumber <= 0 ? 0 : mqttTicketNumber - 1;
          const tempTagSequenceList = _.cloneDeep(state.tagSequenceList);
          const tempTagSequence = tempTagSequenceList.find((tagSequence) => {
            return tagSequence.timeSectionId === timeSectionId && tagSequence.tableType === tableType;
          });
          if (tempTagSequence) {
            if (tempTagSequence.ticketUpdateTimestamp <= mqttUpdateTimestamp) {
              tempTagSequence.ticketSequence = ticketNumber;
              tempTagSequence.ticketLabel = getTicketLabel(tempTagSequence.showTableType ? tempTagSequence.tableType : '', ticketNumber);
              tempTagSequence.ticketUpdateTimestamp = mqttUpdateTimestamp;
            }
          }
          state.tagSequenceList = tempTagSequenceList;
        }
      }
    },
    setCounterTagListByMqtt(state, action) {
      const topicContents = action.payload.topicContents;
      const messageJson = action.payload.messageJson;
      let tagLabel = messageJson.toTicketLabel;
      let tableType = topicContents[5];
      let mqttUpdateTimestamp = Number(messageJson.timestamp);
      let counterName = messageJson.counterName;
      if (!isNaN(mqttUpdateTimestamp)) {
        const tempCounterTagList = _.cloneDeep(state.counterTagList);
        const tempCounterTag = tempCounterTagList.find((counterTag) => {
          return counterTag.tagLabel === tagLabel;
        });
        if (tempCounterTag) {
          if (tempCounterTag.updateTimestamp <= mqttUpdateTimestamp) {
            tempCounterTag.counterName = counterName;
            tempCounterTag.updateTimestamp = mqttUpdateTimestamp;
          }
        } else {
          tempCounterTagList.push({
            tableType: tableType,
            tagLabel: tagLabel,
            counterName: counterName,
            updateTimestamp: mqttUpdateTimestamp,
          });
        }
        // console.log(tempCounterTagList);
        state.counterTagList = tempCounterTagList
          .sort((a, b) => {
            return b.updateTimestamp - a.updateTimestamp;
          })
          .slice(0, 3);

        const tempCounterList = _.cloneDeep(state.counterList);
        const tempCounter = tempCounterList.find((counter) => {
          return counter.counterName === counterName;
        });
        if (tempCounter) {
          if (!tempCounter.updateTimestamp || tempCounter.updateTimestamp <= mqttUpdateTimestamp) {
            tempCounter.counterName = counterName;
            tempCounter.updateTimestamp = mqttUpdateTimestamp;
            tempCounter.tagSequence = {
              tableType: tableType,
              tagLabel: tagLabel,
            };
          }
        } else {
          tempCounterList.push({
            counterName: counterName,
            updateTimestamp: mqttUpdateTimestamp,
            tagSequence: {
              tableType: tableType,
              tagLabel: tagLabel,
            },
          });
        }
        state.counterList = tempCounterList;
      }
    },
    setCounterList(state, action) {
      state.counterList = action.payload;
    },
    resetQueue(state, action) {
      state.queueConfig = action.payload.queueConfig;
      state.requestParams = action.payload.requestParams;
    },
    setLatestTag(state, action) {
      state.latestTag = action.payload;
    },
    setSubscribeExistingTagSequenceListOnly(state, action) {
      state.subscribeExistingTagSequenceListOnly = action.payload;
    },
    setSubscribedTicket(state, action) {
      state.subscribedTicket = action.payload;
      state.subscribeExistingTagSequenceListOnly = true;
    },
    setSubscribedTicketCallTimestamp(state, action) {
      state.subscribedTicketCallTimestamp = action.payload;
    },
    setTransferrableTicketList(state, action) {
      state.transferrableTicketList = action.payload;
    },
    updateTransferrableTicketList(state, action) {
      const { updateId, updateDetail } = action.payload;

      let tempticketList = _.cloneDeep(state.transferrableTicketList);

      tempticketList = tempticketList.map((ticket) => {
        if (ticket.id === updateId) {
          return { ...ticket, ...updateDetail };
        }

        return { ...ticket };
      });

      state.transferrableTicketList = tempticketList;
    },
    addTransferrableTickettUpdateVersion(state, action) {
      state.transferrableTicketUpdateVersion++;
    },
  },
});

export const {
  setQueueConfig,
  setRequestParams,
  setRequestTicketSuccessfully,
  setTagSequenceList,
  substituteTagSequenceInList,
  setCounterTagList,
  setTagSequenceListByMqtt,
  setTicketSequenceListByMqtt,
  setCounterTagListByMqtt,
  resetQueue,
  setLatestTag,
  setCounterList,
  setSubscribeExistingTagSequenceListOnly,
  setSubscribedTicket,
  setSubscribedTicketCallTimestamp,
  setTransferrableTicketList,
  updateTransferrableTicketList,
  addTransferrableTickettUpdateVersion,
} = queueSlice.actions;

export default queueSlice.reducer;
