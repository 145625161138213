import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Main from './containers/Main';
import SiteList from './containers/SiteList';
import SiteDetail from './containers/SiteDetail';
import Pickup from './containers/Pickup';
import Queue from './containers/Queue';
import QueueTableSize from './containers/QueueTableSize';
import QueueTicketType from './containers/QueueTicketType';
import QueueTimeSection from './containers/QueueTimeSection';
import QueueServiceType from './containers/QueueServiceType';
import QueueDate from './containers/QueueDate';
import QueueSpecialRequest from './containers/QueueSpecialRequest';
import QueuePersonalInfo from './containers/QueuePersonalInfo';
import QueuePreview from './containers/QueuePreview';
import Ticket from './containers/Ticket';
import QueueRequestTicketSuccess from './containers/QueueRequestTicketSuccess';
import Error from './containers/Error';
import ReservationDate from './containers/ReservationDate';
import ReservationPreview from './containers/ReservationPreview';
import ReservationTicket from './containers/ReservationTicket';

import { PATH } from './constants/paths';
import { LANG } from './constants/constants';
import { TV } from './containers/TV';

const Routes = () => {
  const history = useHistory();
  const httpStatusMessage = useSelector((state) => state.system.httpStatusMessage);
  const lang = useSelector((state) => state.system.lang);

  useEffect(() => {
    if (httpStatusMessage) {
      history.push(`/${lang}` + PATH.ERROR);
    }
  }, [httpStatusMessage])

  const getPathLang = () => {
    switch (navigator.language) {
      case NAVIGATOR_LANGUAGE.ZH:
      case NAVIGATOR_LANGUAGE.ZH_CN:
      case NAVIGATOR_LANGUAGE.ZH_SG:
        return LANG.SC;
      case NAVIGATOR_LANGUAGE.ZH_HK:
      case NAVIGATOR_LANGUAGE.ZH_TW:
        return LANG.TC
      default:
        return LANG.EN
    }
  }

  return (
    <Switch>
      {/* routes for new standard language param */}

      <Route exact path={PATH.LANDING} component={Main}/>

      <Route exact path={'/:language/app/queue' + PATH.SITE_DETAIL + ':siteId/:ticketId'} component={SiteDetail} />
      <Route exact path={'/:language/app/queue' + PATH.TICKET + ':ticketId'} component={Ticket} />
      <Route exact path={'/:language/app/site/:siteId'} component={SiteDetail} />


      <Route exact path={'/:language'+ PATH.SITE_LIST + ':folderCode'} component={SiteList} />
      <Route exact path={'/:language' + PATH.SITE_DETAIL + ':siteId/:ticketId'} component={SiteDetail} />
      <Route exact path={'/:language' + PATH.SITE_DETAIL + ':siteId'} component={SiteDetail} />

      <Route exact path={'/:language' + PATH.PICKUP + ':siteId'} component={Pickup} />

      <Route exact path={'/:language' + PATH.TV + ':siteId'} component={TV} />

      <Route exact path={'/:language' + PATH.TICKET + ':ticketId'} component={Ticket} />


      <Route exact path={'/:language' + PATH.QUEUE_CURRENT_SECTION + ':siteId'} component={Queue} />
      <Route exact path={'/:language' + PATH.QUEUE_SIZE + ':siteId'} component={QueueTableSize} />
      <Route exact path={'/:language' + PATH.QUEUE_TICKET_TYPE + ':siteId'} component={QueueTicketType} />
      <Route exact path={'/:language' + PATH.QUEUE_TIME_SECTION + ':siteId'} component={QueueTimeSection} />
      <Route exact path={'/:language' + PATH.QUEUE_SERVICE_TYPE + ':siteId'} component={QueueServiceType} />
      <Route exact path={'/:language' + PATH.QUEUE_DATE + ':siteId'} component={QueueDate} />
      <Route exact path={'/:language' + PATH.QUEUE_TAGS + ':siteId'} component={QueueSpecialRequest} />
      <Route exact path={'/:language' + PATH.QUEUE_PERSONAL_INFO + ':siteId'} component={QueuePersonalInfo} />
      <Route exact path={'/:language' + PATH.QUEUE_PREVIEW + ':siteId'} component={QueuePreview} />


      <Route exact path={'/:language' + PATH.QUEUE_REQUEST_TICKET_SUCCESS + ':siteId'} component={QueueRequestTicketSuccess} />
      <Route exact path={'/:language' + PATH.QUEUE_REQUEST_TICKET_SUCCESS} component={QueueRequestTicketSuccess} />

      <Route exact path={'/:language' + PATH.RESERVATION_DATE + ':siteId'} component={ReservationDate} />
      <Route exact path={'/:language' + PATH.RESERVATION_PREVIEW + ':siteId'} component={ReservationPreview} />

      <Route exact path={'/:language' + PATH.RESERVATION_TICKET + ':ticketId'} component={ReservationTicket} />

      <Route exact path={'/:language' + PATH.ERROR} component={Error} />

      {/* routes for new standard language param end*/}

      {/* old routes for app urls */}
      <Route exact path={'/app/queue' + PATH.SITE_DETAIL + ':siteId/:language/:ticketId'} render={({ match }) => {
          return <Redirect to={`/${match.params.language}/app/queue${PATH.SITE_DETAIL}${match.params.siteId}/${match.params.ticketId}`} />
        }}/>
      <Route exact path={'/app/queue' + PATH.TICKET + ':language/:ticketId'} render={({ match }) => {
          return <Redirect to={`/${match.params.language}/app/queue${PATH.TICKET}${match.params.ticketId}`} />
        }}/>
      <Route exact path={'/app/queue' + PATH.TICKET + ':ticketId'} render={({ match }) => {
        const lang = getPathLang();
        return <Redirect to={`/${lang}/app/queue${PATH.TICKET}${match.params.ticketId}`} />
      }} />
      <Route exact path={'/app/site/:siteId'} render={({ match }) => {
        const lang = getPathLang();
        return <Redirect to={`/${lang}/app/site/${match.params.siteId}`} />
        }}/>
      <Route exact path={'/app/site/:language/:siteId'} render={({ match }) => {
        return <Redirect to={`/${match.params.language}/app/site/${match.params.siteId}`} />
        }}/>
      {/* old routes for app urls end */}

      {/* old routes for site list */}
      <Route exact path={PATH.SITE_LIST + ':folderCode/:language'} 
        render={({ match }) => {
          return <Redirect to={`/${match.params.language}${PATH.SITE_LIST}${match.params.folderCode}`} />
        }}/>
      <Route exact path={PATH.SITE_LIST + ':folderCode'}
        render={({ match }) => {
          const lang = getPathLang();
          return <Redirect to={`/${lang}${PATH.SITE_LIST}${match.params.folderCode}`} />
        }}/>
      {/* old routes for site list end*/}

      {/* old routes for site detail */}
      <Route exact path={PATH.SITE_DETAIL + ':siteId/:language/:ticketId'}
        render={({ match }) => {
          return <Redirect to={`/${match.params.language}${PATH.SITE_DETAIL}${match.params.siteId}/${match.params.ticketId}`} />
        }}/>
      <Route exact path={PATH.SITE_DETAIL + ':siteId/:language'}
        render={({ match }) => {
          return <Redirect to={`/${match.params.language}${PATH.SITE_DETAIL}${match.params.siteId}`} />
        }}/>  
      <Route exact path={PATH.SITE_DETAIL + ':siteId'} render={({ match }) => {
        const lang = getPathLang();
        return <Redirect to={`/${lang}${PATH.SITE_DETAIL}${match.params.siteId}`} />
      }} />
      {/* old routes for site detail end*/}

      {/* old routes for pickup */}
      <Route exact path={PATH.PICKUP + ':siteId'} render={({ match }) => {
        const lang = getPathLang();
        return <Redirect to={`/${lang}${PATH.PICKUP}${match.params.siteId}`} />
      }} />
      {/* old routes for pickup end */}

      <Route exact path={PATH.QUEUE_CURRENT_SECTION + ':siteId'} component={Queue} />
      <Route exact path={PATH.QUEUE_SIZE + ':siteId'} component={QueueTableSize} />
      <Route exact path={PATH.QUEUE_TICKET_TYPE + ':siteId'} component={QueueTicketType} />
      <Route exact path={PATH.QUEUE_TIME_SECTION + ':siteId'} component={QueueTimeSection} />
      <Route exact path={PATH.QUEUE_SERVICE_TYPE + ':siteId'} component={QueueServiceType} />
      <Route exact path={PATH.QUEUE_DATE + ':siteId'} component={QueueDate} />
      <Route exact path={PATH.QUEUE_TAGS + ':siteId'} component={QueueSpecialRequest} />
      <Route exact path={PATH.QUEUE_PERSONAL_INFO + ':siteId'} component={QueuePersonalInfo} />
      <Route exact path={PATH.QUEUE_PREVIEW + ':siteId'} component={QueuePreview} />

      {/* old routes for ticket */}
      <Route exact path={PATH.TICKET + ':language/:ticketId'} render={({ match }) => {
        return <Redirect to={`/${match.params.language}${PATH.TICKET}${match.params.ticketId}`} />
      }} />
      <Route exact path={PATH.TICKET + ':ticketId'} render={({ match }) => {
        const lang = getPathLang();
        return <Redirect to={`/${lang}${PATH.TICKET}${match.params.ticketId}`} />
      }} />
      {/* old routes for ticket end */}

      {/* old routes for reservation ticket */}
      <Route exact path={PATH.RESERVATION_TICKET + ':language/:ticketId'} render={({ match }) => {
        return <Redirect to={`/${match.params.language}${PATH.RESERVATION_TICKET}${match.params.ticketId}`} />
      }} />
      <Route exact path={PATH.RESERVATION_TICKET + ':ticketId'} render={({ match }) => {
        const lang = getPathLang();
        return <Redirect to={`/${lang}${PATH.RESERVATION_TICKET}${match.params.ticketId}`} />
      }} />
      {/* old routes for reservation ticket end */}

      <Route exact path={PATH.QUEUE_REQUEST_TICKET_SUCCESS + ':siteId'} component={QueueRequestTicketSuccess} />
      <Route exact path={PATH.QUEUE_REQUEST_TICKET_SUCCESS} component={QueueRequestTicketSuccess} />

      <Route exact path={PATH.RESERVATION_DATE + ':siteId'} render={({ match }) => {
        const lang = getPathLang();
        return <Redirect to={`/${lang}${PATH.RESERVATION_DATE}${match.params.siteId}`} />
      }} />
      <Route exact path={PATH.RESERVATION_PREVIEW + ':siteId'} component={ReservationPreview} />

      {/* old routes for error */}
      <Route exact path={PATH.ERROR} render={() => {
        const lang = getPathLang();
        return <Redirect to={`/${lang}${PATH.ERROR}`} />
      }} />
      <Route exact path={'*'} render={() => {
        const lang = getPathLang();
        return <Redirect to={`/${lang}${PATH.ERROR}`} />
      }} />
      {/* old routes for error end*/}
    </Switch>
  )
}

export default Routes;

const NAVIGATOR_LANGUAGE = {
  ZH: 'zh',
  ZH_HK: 'zh-hk',
  ZH_CN: 'zh-cn',
  ZH_SG: 'zh-sg',
  ZH_TW: 'zh-tw',
}
