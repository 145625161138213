export const THEMES = {
  MTR_TAXI: 'mtr-taxi',
  COUNTER: 'counter',
  HIGH_SPEED_RAIL: 'high-speed-rail',
  EVENT: 'event',
  HANG_SENG: 'hang-seng',
  CICHK: 'cichk',
  TV: 'tv',
  AIRPORT_TAXI: 'airport-taxi',
  JOCKEY_CLUB: 'jockey-club',
  AIRLINE: 'airline',
}

export const MTR_TV_COLOR = '#002639';