import React from 'react';
import '../styles/queue-title-info.scss';

export const QueueTitle = ({ title, postTitle }) => {

  return (
    <>
      <div className="queue-title">
        <div>{title}</div>
        {
          postTitle &&
          <div>{postTitle}</div>
        }
      </div>
      {/* <div className="queue-title-underline" /> */}
    </>
  )
};
