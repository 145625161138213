import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { tableColor /*, showElementByTheme */ } from '../utils/utils';

// import { ELEMENT } from '../constants/constants';

// import { ReactComponent as CallLogo } from '../images/call-icon.svg';
import { ReactComponent as CounterLogo } from '../images/counter-icon.svg';

import '../styles/counter.scss';

export const Counter = ({ counter, latestTag, siteId }) => {

  // const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  const [color, setColor] = useState('A');

  useEffect(() => {
    if (counter.tagSequence && counter.tagSequence.tableType) {
      setColor(tableColor(counter.tagSequence.tableType));
    }
  }, [counter])

  const enableBlinkClassName = () => {

    if(latestTag === counter.tagSequence.tagLabel){
      return true;
    }

    return false;
  }

  return (
    <>
      {
        counter && counter.counterName &&
        <div key={counter.counterName + counter.updateTimestamp} className={`counter-item ${(counter.tagSequence && (enableBlinkClassName())) ? 'blink' : ''}`}>
          {/* {
            counter.tagSequence.tableTypeLabel &&
            <div className="table-type-label">{tagSequence.tableTypeLabel}</div>
          } */}
          <div className="ticket-number">
            <div className="text">
              <div className={`counter-name ${(counter.counterName.length > 1) ? 'long' : ''}`}>{counter.counterName}</div>
              <CounterLogo className={`counter-icon logo-white`} />
              {/* {
                showElementByTheme(ELEMENT.TAG_SEQUENCE_CALL_LOGO, theme) &&
                <CallLogo className={`sound-icon logo-white`} />
              } */}
              <div className={`tag-label text-${(counter.tagSequence && counter.tagSequence.tagLabel) ? color : ''}`}>{(counter.tagSequence && counter.tagSequence.tagLabel) ? counter.tagSequence.tagLabel : '----'}</div>
            </div>
          </div>
          <div className={`zig-zag`}></div>
          <div className="time">
            <>
              <span>{'@ '}</span>
              <span className='time-label'>{((counter.updateTimestamp) ? moment(counter.updateTimestamp).locale('en').format('HH:mm') : '--:--')}</span>
            </>
          </div>
        </div>
      }
    </>
  )
}