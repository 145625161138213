import _ from 'lodash';
import { QUEUE_STEPS, MQTT_MSG_TYPE, TICKET_DISPLAY_TYPE } from '../constants/constants';
import { PATH } from '../constants/paths';
import { getStringByTheme } from './utils';

export const getAvaliableSectionList = (steps, currentStep, requestParams, sectionList = []) => {
  let avaliableSectionList = _.cloneDeep(sectionList);

  if (!steps || steps.length === 0 || !requestParams || !sectionList || sectionList.length === 0) {
    return [];
  }

  const index = steps.findIndex((step) => { return step === currentStep });
  const previousSteps = _.slice(steps, 0, index);
  previousSteps.forEach((step) => {
    switch (step) {
      case QUEUE_STEPS.TIME_SECTION:
        if (requestParams.timeSectionId) {
          avaliableSectionList = avaliableSectionList.filter((section) => {
            return section.timeSectionId === requestParams.timeSectionId;
          });
        }
        break;
      case QUEUE_STEPS.TABLE_TYPE:
        if (requestParams.tableType) {
          avaliableSectionList = avaliableSectionList.filter((section) => {
            return section.tableType === requestParams.tableType;
          });
        }
        break;
      case QUEUE_STEPS.TICKET_TYPE:
        if (requestParams.ticketType) {
          avaliableSectionList = avaliableSectionList.filter((section) => {
            return section.ticketType === requestParams.ticketType;
          });
        }
        break;
      case QUEUE_STEPS.SIZE:
        if (requestParams.size) {
          avaliableSectionList = avaliableSectionList.filter((section) => {
            return requestParams.size >= section.minSize && requestParams.size <= section.maxSize;
          });
        }
        break;
      default:
    }
  })

  return (avaliableSectionList) ? avaliableSectionList : [];
}

export const getQueuePageByStep = (step) => {
  switch (step) {
    case QUEUE_STEPS.CURRENT_SECTION:
      return PATH.QUEUE_CURRENT_SECTION;
    case QUEUE_STEPS.SIZE:
      return PATH.QUEUE_SIZE;
    case QUEUE_STEPS.TABLE_TYPE:
      return PATH.QUEUE_SERVICE_TYPE;
    case QUEUE_STEPS.DATE:
      return PATH.QUEUE_DATE;
    case QUEUE_STEPS.TIME_SECTION:
      return PATH.QUEUE_TIME_SECTION;
    case QUEUE_STEPS.PERSONAL_DATA:
      return PATH.QUEUE_PERSONAL_INFO;
    case QUEUE_STEPS.PREVIEW:
      return PATH.QUEUE_PREVIEW;
    case QUEUE_STEPS.TICKET_TYPE:
      return PATH.QUEUE_TICKET_TYPE;
    case QUEUE_STEPS.TAGS:
      return PATH.QUEUE_TAGS;
    default:
      return ''
  }
}

export const mergeQueueConfig = (queueConfig, payload) => {
  let newConfig = _.cloneDeep(queueConfig);
  if (!queueConfig) {
    let tempSteps = payload.steps;
    tempSteps = tempSteps.filter((item)=> item !== QUEUE_STEPS.PREVIEW);
    tempSteps.push(QUEUE_STEPS.PREVIEW);
    payload.steps = tempSteps;
    newConfig = payload;
    if (!newConfig.personalDataRequired) {
      newConfig.personalDataRequired = [];
    }
  } else {
    if (!newConfig.currentSection) {
      newConfig.currentSection = payload.currentSection;
    }
    if (!newConfig.filterResult) {
      newConfig.filterResult = payload.filterResult;
    }
    if (!newConfig.personalDataRequired) {
      newConfig.personalDataRequired = (!payload.personalDataRequired) ? [] : payload.personalDataRequired;
    }
    if (!newConfig.selectDate) {
      newConfig.selectDate = payload.selectDate;
    }
    if (!newConfig.filterResult) {
      newConfig.filterResult = payload.filterResult;
    }
    if (!newConfig.selectTags) {
      newConfig.selectTags = payload.selectTags;
    }
    if (!newConfig.steps) {
      let tempSteps = payload.steps;
      tempSteps = tempSteps.filter((item)=> item !== QUEUE_STEPS.PREVIEW);
      tempSteps.push(QUEUE_STEPS.PREVIEW);
      newConfig.steps = tempSteps;
    }
    if (!newConfig.termsAndConditions) {
      newConfig.termsAndConditions = payload.termsAndConditions;
    }
    if (!newConfig.ticketRejectReason) {
      newConfig.ticketRejectReason = payload.ticketRejectReason;
    }
    newConfig.siteInfo = payload.siteInfo;
    newConfig.queueAvailable = payload.queueAvailable;
  }
  return newConfig;
}

export const getTagLabel = (tableType, startTagSequence, endTagSequence, messageType) => {

  if (startTagSequence === null) {
    startTagSequence = '----';
  }

  if (endTagSequence === null) {
    endTagSequence = '----';
  }

  if (tableType) {
    if (messageType === MQTT_MSG_TYPE.TAG_BATCH || messageType === TICKET_DISPLAY_TYPE.BATCH) {
      return (tableType + padZero(startTagSequence, 3) + '~' + tableType + padZero(endTagSequence, 3))
    }
    return (startTagSequence !== endTagSequence) ? tableType + padZero(startTagSequence, 3) + '~' + tableType + padZero(endTagSequence, 3) : tableType + padZero(startTagSequence, 3);
  } else {
    if (messageType === MQTT_MSG_TYPE.TAG_BATCH || messageType === TICKET_DISPLAY_TYPE.BATCH) {
      return (startTagSequence + '~' + endTagSequence)
    }
    return (startTagSequence !== endTagSequence) ? startTagSequence + '~' + endTagSequence : startTagSequence;
  }
}

export const getTicketLabel = (tableType, ticketSequence) => {
  if (ticketSequence != null && !isNaN(Number(ticketSequence))) {
    return '' + ((tableType) ? tableType + padZero(ticketSequence, 3) : ticketSequence);
  }
  return '';
}

const padZero = (number, length) => {
  var str = '' + number;
  while (str.length < length) {
    str = '0' + str;
  }
  return str;
}

export const callTimeCountDown = (callTimestamp, blinkMilliSeconds) => {
  const remainingMilliSeconds = callTimestamp + blinkMilliSeconds - Date.now()
  if (!callTimestamp || !blinkMilliSeconds || remainingMilliSeconds <= 0) {
    return "";
  }
  const minutes = Math.floor(remainingMilliSeconds / 60000);
  const seconds = ((remainingMilliSeconds % 60000) / 1000).toFixed(0);
  return (minutes < 10 ? '0' : '') + minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

export const subscribeTicketViaWhatsapp = (ticketId, theme, lang) => {
  window.location = `${process.env.REACT_APP_WHATSAPP_ENDPOINT}` + "?text=" + getStringByTheme('whatsappSubscription', lang, theme) + ", ID: " + ticketId;
}

export const getDisplayReason = (serviceType, lang, theme) => {

  if(serviceType.status === 'H') {
    return serviceType.rejectReason
  }

  if(serviceType.readOnly) {
    return `${[getStringByTheme('readOnly', lang, theme), !serviceType.hasQuota? getStringByTheme('fullQuota', lang, theme) :null].filter((item)=>item).join(' - ')}`
  }

  if(!serviceType.hasQuota) {
    return getStringByTheme('fullQuota', lang, theme)
  }

  return null
}