import { ReactComponent as CallLogo } from '../../../images/call-icon.svg';
import { getStringByTheme } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';

let timeoutHanlder = undefined;

export const CallingItem = ({ item }) => {
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);
  const [blink, setBlink] = useState(false);

  useEffect(() => {
    if (item.callTimestamp) {
      setBlink(true);
    }
    // if(item.callTimestamp) {
    //   if (Date.now() <= item.callTimestamp + 300000) {
    //     setBlink(true);
    //     timeoutHanlder = setTimeout(() => {
    //       setBlink(false);
    //     }, (item.callTimestamp + 300000) - Date.now());
    //   }
    // }

    // return () => {
    //   clearTimeout(timeoutHanlder);
    // }
  }, [item]);

  return (
    <div className={`calling-item-wrapper ${blink ? 'blink' : ''}`}>
      <div className="calling-icon">
        <CallLogo />
      </div>
      <div className="zig-zag"></div>
      <div className="calling-item-detail">
        <div className="calling-item-counter">
          {item.counterName ? (
            <>
              {getStringByTheme('pleaseGoTo', lang, theme)}
              <span>{item.counterName}</span>
            </>
          ) : item.tableTypeName ? (
            <span>{item.tableTypeName}</span>
          ) : (
            <span>{item.tableType}</span>
          )}
        </div>
        <div className="calling-item-time-wrapper">
          <div className="calling-item-time">
            {moment(item.callTimestamp).locale('en').format('HH:mm')}
          </div>
          <div className="dot"></div>
          <div className="calling-item-label">
            {getStringByTheme('called', lang, theme)}
          </div>
        </div>
      </div>
    </div>
  );
};
