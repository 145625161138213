import React from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { QUEUE_STEPS } from '../constants/constants';

export const PreviewEditBtn = ({ editStep }) => {
  const history = useHistory();

  const queueConfig = useSelector((state) => state.queue.queueConfig);

  function edit() {
    if (queueConfig) {
      const currentIndex = queueConfig.steps.findIndex((step) => { return step === QUEUE_STEPS.PREVIEW });
      const index = queueConfig.steps.findIndex((step) => { return step === editStep });
      history.go(-(currentIndex - index));
    }
  }

  return (
    <img onClick={() => edit()} className="image edit-button" src={process.env.PUBLIC_URL + "/images/edit-btn.png"} alt="edit-btn" />
  )
};
