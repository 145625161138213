import React, { useEffect } from 'react';
// import { useParams } from "react-router-dom";
// import { useDispatch, useSelector, useStore } from 'react-redux';

// import { API_URLS } from '../constants/apiUrls';
// import { getOne } from '../utils/baseFetch';
// import { setQueueConfig } from '../reducers/queueSlice';
import { SiteInfo } from '../components/SiteInfo';

import '../styles/queue.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';

const Queue = () => {
  // const params = useParams();
  // const dispatch = useDispatch();
  // const queueConfig = useSelector((state) => state.queue.queueConfig);
  // const queuePreview = useSelector((state) => state.queue.queuePreview);
  // const lang = useSelector((state) => state.system.lang);
  // const store = useStore();

  useEffect(() => {
    document.title = 'Queue Page';
  }, []);

  // useEffect(() => {
  //   getOne(API_URLS.QUEUE, params.restUrlId, (payload) => {
  //     dispatch(setQueueConfig(payload));
  //   }, undefined, store);
  // }, [params.restUrlId, dispatch, store]);

  return (
    <LanguageWrapper>
    <div className="queue-wrapper">
      <div className="gulu-logo"><img className="image" src={process.env.PUBLIC_URL + "/images/gulu-logo.png"} alt="thegulu" /></div>
      <SiteInfo />
    </div>
    </LanguageWrapper>
  );
}

export default Queue;