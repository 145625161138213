import { createSlice } from '@reduxjs/toolkit';

export const tvSlice = createSlice({
  name: 'tv',
  initialState: {
    timeSessionUpdateTimestamp: 0,
  },
  reducers: {
    setTimeSessionUpdateTimestamp(state, action) {
      if (state.timeSessionUpdateTimestamp >= action.payload) {
        return;
      }
      state.timeSessionUpdateTimestamp = action.payload;
    },
  },
});

export const { setTimeSessionUpdateTimestamp } = tvSlice.actions;

export default tvSlice.reducer;
