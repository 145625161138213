import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import moment from 'moment';
import * as _ from 'lodash';
import QRCode from 'qrcode.react';
import { isEmpty } from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { setTicket, setSiteInfo, setSurveyInfo, setUserDetail } from '../reducers/ticketSlice';
import { subscribeMqttTopic, unsubscribeMqttTopic, setMessage, subscribeMqttTopics } from '../reducers/systemSlice';
import {
  setTagSequenceList,
  setCounterTagList,
  setSubscribeExistingTagSequenceListOnly,
  setSubscribedTicket,
  setSubscribedTicketCallTimestamp,
  setTransferrableTicketList,
  substituteTagSequenceInList,
} from '../reducers/queueSlice';
import {
  tableColor,
  getStringByTheme,
  showElementByTheme,
  validateEmail,
  isRedeemed,
  isDeleted,
  validateMobile,
  getMomentLang,
  shadeColor,
} from '../utils/utils';
import { subscribeTicketViaWhatsapp } from '../utils/queueUtils';
import { ELEMENT, PATH_LOCATION, TICKET_DISPLAY_TYPE, NOTIFIER, MQTT, DEFAULT_TABLE_TYPE_COLOR } from '../constants/constants';

import { SiteInfo } from '../components/SiteInfo';
import { DropDown } from '../components/DropDown';
import { TagSequence } from '../components/TagSequence';
import Header from '../components/Header';
import { EmailInput } from '../components/EmailInput';
import { MobileInput } from '../components/MobileInput';

import { ReactComponent as PeopleIcon } from '../images/people.svg';
import { ReactComponent as CounterLogo } from '../images/counter-icon.svg';
// import { ReactComponent as SendArrow } from '../images/send-email-arrow.svg';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { ReactComponent as WhatsappIcon } from '../images/whatsapp-icon.svg';
// import { ReactComponent as TrashIcon } from '../images/trash.svg';

import { TransferableTicketDetailWrapper } from '../components/TransferableTicketComponent/TransferableTicketDetailWrapper';

import '../styles/ticket.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';

const Ticket = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useStore();

  // const history = useHistory();
  const ticket = useSelector((state) => state.ticket.ticket);
  const siteInfo = useSelector((state) => state.ticket.siteInfo);
  // const surveyInfo = useSelector((state) => state.ticket.surveyInfo);
  // const userDetail = useSelector((state) => state.ticket.userDetail);
  const tagSequenceList = useSelector((state) => state.queue.tagSequenceList);
  const transferrableTicketList = useSelector((state) => state.queue.transferrableTicketList);
  const transferrableTicketUpdateVersion = useSelector((state) => state.queue.transferrableTicketUpdateVersion);
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);
  const counterTagList = useSelector((state) => state.queue.counterTagList);
  const latestTag = useSelector((state) => state.queue.latestTag);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [countryCode, setCountryCode] = useState('852');
  const [mobile, setMobile] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const subscribedTicketCallTimestamp = useSelector((state) => state.queue.subscribedTicketCallTimestamp);
  const requestTicketSuccessfully = useSelector((state) => state.queue.requestTicketSuccessfully);

  const [color, setColor] = useState('A');
  const [blinking, setBlinking] = useState(false);
  const [timeoutHandle, setTimeoutHandle] = useState(undefined);

  const [displayList, setDisplayList] = useState([]);

  useEffect(() => {
    return function unsubscribeMqtt() {
      if (ticket && siteInfo && siteInfo.siteId && ticket.displayType !== TICKET_DISPLAY_TYPE.TRANSFERABLE) {
        dispatch(unsubscribeMqttTopic([MQTT.VERSION, siteInfo.siteId, MQTT.CHANNEL, MQTT.QUEUE, ticket.timeSessionId, ticket.tableType].join('/')));
        dispatch(unsubscribeMqttTopic([MQTT.VERSION, siteInfo.siteId, MQTT.CHANNEL, MQTT.QUEUE, MQTT.TICKET, ticket.id].join('/')));
        dispatch(setTicket(undefined));
        dispatch(setSiteInfo(undefined));
        dispatch(setSurveyInfo(undefined));
        dispatch(setUserDetail(undefined));
        dispatch(setSubscribeExistingTagSequenceListOnly(false));
        dispatch(setTransferrableTicketList([]));

        if (ticket.ticketTransferDetailList && ticket.displayType === TICKET_DISPLAY_TYPE.TRANSFERABLE && siteInfo && siteInfo.siteId) {
          unsubscribeMqttTopic([MQTT.VERSION, siteInfo.siteId, MQTT.CHANNEL, MQTT.QUEUE, MQTT.TICKET, ticket.id].join('/'));

          for (const transferrableTicket of ticket.ticketTransferDetailList) {
            dispatch(
              unsubscribeMqttTopic(
                [MQTT.VERSION, siteInfo.siteId, MQTT.CHANNEL, MQTT.QUEUE, transferrableTicket.timeSessionId, transferrableTicket.tableType].join('/')
              )
            );
          }
        }
      }
      setBlinking(false);
    };
  }, [transferrableTicketUpdateVersion]);

  useEffect(() => {
    document.title = getStringByTheme('ticketTitle', lang, theme);
  }, [lang, theme]);

  useEffect(() => {
    if (!lang) {
      return;
    }

    const newParams = { ticketId: params.ticketId };
    post(
      API_URLS.TICKET,
      newParams,
      (payload) => {
        if (payload) {
          const { siteInfo, surveyInfo, ticket, userDetail } = payload;
          dispatch(setSubscribedTicketCallTimestamp(ticket.callTimestamp));
          dispatch(setTicket({ ...ticket, tableTypeForegroundColor: ticket.tableTypeForegroundColor || DEFAULT_TABLE_TYPE_COLOR }));
          dispatch(setSiteInfo(siteInfo));
          dispatch(setSurveyInfo(surveyInfo));
          dispatch(setUserDetail(userDetail));
          dispatch(setSubscribedTicket(ticket));

          if (ticket.ticketTransferDetailList && ticket.displayType === TICKET_DISPLAY_TYPE.TRANSFERABLE) {
            const topics = [];
            dispatch(setTransferrableTicketList(ticket.ticketTransferDetailList));
            topics.push([MQTT.VERSION, siteInfo.siteId, MQTT.CHANNEL, MQTT.QUEUE, MQTT.TICKET, ticket.id].join('/'));
            for (const transferrableTicket of ticket.ticketTransferDetailList) {
              topics.push(
                [MQTT.VERSION, siteInfo.siteId, MQTT.CHANNEL, MQTT.QUEUE, transferrableTicket.timeSessionId, transferrableTicket.tableType].join('/')
              );
            }
            dispatch(subscribeMqttTopics(topics));
          }

          if (ticket.subscribedNotifierType) {
            setSubscribed(true);
          }

          if (siteInfo && siteInfo.siteId && ticket.displayType !== TICKET_DISPLAY_TYPE.EVENT && ticket.displayType !== TICKET_DISPLAY_TYPE.TRANSFERABLE) {
            if (ticket.tagSequence) {
              dispatch(
                substituteTagSequenceInList({
                  siteId: siteInfo.siteId,
                  tagSequence: ticket.tagSequence})
              );
              dispatch(
                setCounterTagList({
                  counterTagList: ticket.counterTagList ? ticket.counterTagList : [],
                })
              );
            }
            dispatch(subscribeMqttTopic([MQTT.VERSION, siteInfo.siteId, MQTT.CHANNEL, MQTT.QUEUE, ticket.timeSectionId, ticket.tableType].join('/')));
            dispatch(subscribeMqttTopic([MQTT.VERSION, siteInfo.siteId, MQTT.CHANNEL, MQTT.QUEUE, MQTT.TICKET, ticket.id].join('/')));
          }
        }
      },
      undefined,
      store
    );
  }, [params.ticketId, dispatch, store, lang, transferrableTicketUpdateVersion]);

  useEffect(() => {
    if (ticket) {
      if (ticket.tableType) {
        setColor(tableColor(ticket.tableType));
      }
      if (isRedeemed(ticket) || isDeleted(ticket)) {
        if (timeoutHandle) {
          clearTimeout(timeoutHandle);
        }
        setBlinking(false);
      }
    }
  }, [ticket, transferrableTicketList]);

  useEffect(() => {
    if (ticket && !ticket.substitute && subscribedTicketCallTimestamp && ticket.displayType !== TICKET_DISPLAY_TYPE.TRANSFERABLE) {
      if (Date.now() <= subscribedTicketCallTimestamp + 900000) {
        setBlinking(true);
        let newTimeoutHandle = setTimeout(() => {
          setBlinking(false);
        }, subscribedTicketCallTimestamp + 900000 - Date.now());
        setTimeoutHandle(newTimeoutHandle);
      }
    } else {
      setBlinking(false);
    }
  }, [ticket && ticket.callTimestamp, ticket?.substitute]);

  useEffect(() => {
    if (ticket && ticket.displayType !== TICKET_DISPLAY_TYPE.TRANSFERABLE) {
      const tempDisplayList = [];
      // if (ticket.size && ticket.sizeSelectable) {
      //   displayList.push(<div key="tableSize">
      //     <div className="ticket-title">{getStringByTheme('tableSize', lang, theme)}</div>
      //     <div className="ticket-text">{ticket.size}</div>
      //     <div className="middle-line"></div>
      //   </div>)
      // }
      // if (ticket.tableTypeLabel) {
      //   tempDisplayList.push(<div key="tableType">
      //     <div className="ticket-title">{getStringByTheme('tableType', lang, theme)}</div>
      //     <div className="ticket-text">{ticket.tableTypeLabel}</div>
      //     <div className="middle-line"></div>
      //   </div>)
      // }
      if (ticket.startTimestamp) {
        tempDisplayList.push(
          <div key="date">
            <div className="ticket-title">{getStringByTheme('date', lang, theme)}</div>
            <div className="ticket-text">{moment(ticket.startTimestamp).format('YYYY-MM-DD')}</div>
            <div className="middle-line"></div>
          </div>
        );
      }
      if (ticket.timeSectionLabel) {
        tempDisplayList.push(
          <div key="timeSection">
            <div className="ticket-title">{getStringByTheme('timeSection', lang, theme)}</div>
            <div className="ticket-text">{ticket.timeSectionLabel}</div>
            <div className="middle-line"></div>
          </div>
        );
      }
      if (ticket.ticketType && ticket.ticketTypeLabel) {
        tempDisplayList.push(
          <div key="ticketType">
            <div className="ticket-title">{getStringByTheme('ticketType', lang, theme)}</div>
            <div className="ticket-text">{ticket.ticketTypeLabel}</div>
            <div className="middle-line"></div>
          </div>
        );
      }
      if (ticket.tags && ticket.tags.length > 0) {
        tempDisplayList.push(
          <div key="tags">
            <div className="ticket-title">{getStringByTheme('tags', lang, theme)}</div>
            <div className="ticket-text">
              <div>
                {ticket.tags.length > 0
                  ? ticket.tags.forEach((item) => {
                      tempDisplayList.push(<div>{item}</div>);
                    })
                  : getStringByTheme('noTag', lang, theme)}
              </div>
            </div>
            <div className="middle-line"></div>
          </div>
        );
      }

      setDisplayList(tempDisplayList);
    }
  }, [lang, ticket, theme]);

  // const emailOnClick = () => {
  //   if (email === '' || !validateEmail(email)) {
  //     setEmailError(getStringByTheme('emailError', lang, theme));
  //     setEmailSuccess(false);
  //     return;
  //   }

  //   setEmailError('');

  //   const requestParam = {
  //     ticketId: ticket.id,
  //     deliveryType: 'EMAIL',
  //     email: email,
  //   }

  //   post(API_URLS.REQUEST_EMAIL_TICKET_DELIVERY, requestParam, (payload) => {
  //     setEmailSuccess(true);
  //   }, undefined, store);

  // }

  useEffect(() => {
    if (!theme) return;
    if (showElementByTheme(ELEMENT.TICKET_DETAIL_POP_UP, theme) && requestTicketSuccessfully) {
      dispatch(setMessage('ticket-' + getStringByTheme('reminderForTicket', lang, theme)));
    }
  }, [theme]);

  const whatsappOnClick = () => {
    subscribeTicketViaWhatsapp(ticket.id, theme, lang);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(event.target.value === '' || validateEmail(event.target.value) ? '' : getStringByTheme('emailError', lang, theme));
  };

  const emailSubscribeOnClick = () => {
    if (!validateEmail(email)) {
      setEmailError(getStringByTheme('emailError', lang, theme));
      return;
    }

    setEmailError('');

    const requestParam = {
      ticketId: ticket.id,
      type: NOTIFIER.EMAIL,
      email: email,
    };

    post(
      API_URLS.SUBSCRIBE_NOTIFICATION,
      requestParam,
      (payload) => {
        setSubscribed(true);
      },
      undefined,
      store
    );
  };

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
    setMobileError(event.target.value === '' || validateMobile(event.target.value) ? '' : getStringByTheme('phoneError', lang, theme));
  };

  const smsSubscribeOnClick = () => {
    if (!countryCode || !validateMobile(mobile)) {
      setMobileError(getStringByTheme('phoneError', lang, theme));
      return;
    }

    setMobileError('');

    const requestParam = {
      ticketId: ticket.id,
      type: NOTIFIER.SMS,
      countryCode: countryCode,
      mobile: mobile,
    };

    post(
      API_URLS.SUBSCRIBE_NOTIFICATION,
      requestParam,
      (payload) => {
        setSubscribed(true);
      },
      undefined,
      store
    );
  };

  // const deleteTicket = () => {
  //   if (ticket) {
  //     const newParams = { ticketId: ticket.ticketId }
  //     post(API_URLS.QUEUE_DELETE_TICKET, newParams, (payload) => {
  //       const tempTicket = _.cloneDeep(ticket);
  //       tempTicket.status = STATUS.DEACTIVE;
  //       dispatch(setTicket(tempTicket));
  //     }, undefined, store);
  //   }
  // }
  return (
    <LanguageWrapper>
      <div className="ticket-wrapper queue-wrapper">
        {ticket && (
          <>
            <Header showLanguageButtons showPoweredBy={true} />
            {showElementByTheme(ELEMENT.TICKET_DETAIL_COUNTER_TAG_LIST, theme) && (
              <>
                {/* <div className="title">{getStringByTheme('currentTagSequence', lang, theme)}</div> */}
                {counterTagList && counterTagList.length > 0 && (
                  <div className="counter-tag-list">
                    {counterTagList
                      .filter((counterTag) => {
                        return counterTag.tableType === ticket.tableType;
                      })
                      .slice(0, 1)
                      .map((counterTag, counterTagKey) => {
                        return <TagSequence key={counterTagKey} tagSequence={counterTag} latestTag={latestTag} />;
                      })}
                  </div>
                )}
              </>
            )}
            {showElementByTheme(ELEMENT.TICKET_DETAIL_TAG_SEQUENCE_LIST, theme, params.siteId) &&
              ticket.displayType !== TICKET_DISPLAY_TYPE.TRANSFERABLE &&
              tagSequenceList &&
              tagSequenceList.length > 0 && (
                <div className="tag-sequence-list">
                  {tagSequenceList.map((tagSequence, tagSequenceKey) => {
                    return <TagSequence key={tagSequenceKey} tagSequence={tagSequence} showTicketLabel={false} />;
                  })}
                </div>
              )}
            <div className={`ticket-box ${blinking ? 'blinking' : ''}`}>
              <div
                className={`zig-zag zig-zag-${color}`}
                style={{
                  backgroundImage: `linear-gradient(-45deg, ${shadeColor(
                    ticket.tableTypeForegroundColor,
                    -80
                  )} 14px, transparent 0), linear-gradient(45deg, ${shadeColor(ticket.tableTypeForegroundColor, -80)} 14px, transparent 0)`,
                }}
              ></div>
              <div
                className={`ticket-box-content text-${color} background-dark-${color} `}
                style={{ backgroundColor: shadeColor(ticket.tableTypeForegroundColor, -80), color: ticket.tableTypeForegroundColor }}
              >
                <div className={`ticket-content background-dark-${color} `} style={{ backgroundColor: shadeColor(ticket.tableTypeForegroundColor, -80) }}>
                  {/* {showElementByTheme(ELEMENT.TICKET_DETAIL_SITE_INFO, theme) && (
                      <div className="background-site-info">
                        <SiteInfo
                          site={ticket && ticket.siteInfo}
                          location={PATH_LOCATION.TICKET}
                        />
                      </div>
                    )} */}
                  <SiteInfo site={siteInfo} location={PATH_LOCATION.TICKET} />
                  <div className={`ticket-number background-${color}`} style={{ backgroundColor: ticket.tableTypeForegroundColor }}>
                    {ticket.ticketLabel}
                    {ticket.size && ticket.sizeSelectable && (
                      <div className="table-size">
                        <PeopleIcon className="image" />
                        <div>{ticket.size}</div>
                      </div>
                    )}
                    {!isEmpty(ticket.counterName || ticket.pickupTypeName) && (
                      <div className="counter-name-wrapper">
                        <CounterLogo className={`counter-icon`} />
                        <div
                          className={`counter-name text-${color} ${((ticket.counterName && ticket.counterName.length > 1) || (ticket.pickupTypeName && ticket.pickupTypeName.length > 1)) ? 'long' : ''}`}
                          style={{ color: ticket.tableTypeForegroundColor }}
                        >
                          {ticket.counterName || ticket.pickupTypeName}
                        </div>
                      </div>
                    )}
                  </div>
                  {ticket.displayType === TICKET_DISPLAY_TYPE.TRANSFERABLE && ticket.startTimestamp && (
                    <div className="ticket-date">
                      <div>
                        {moment(ticket.startTimestamp).locale('en').format('YYYY-MM-DD')}{' '}
                        {moment(ticket.startTimestamp).locale(getMomentLang(lang)).format('ddd')}
                        {ticket.timeSectionLabel ? ` (${ticket.timeSectionLabel})` : null}
                      </div>
                    </div>
                  )}
                  {ticket.tableTypeLabel && <div className="table-type-label">{ticket.tableTypeLabel}</div>}
                  <div className="qrcode-wrapper">
                    {isRedeemed(ticket) && ticket.displayType !== TICKET_DISPLAY_TYPE.TRANSFERABLE && (
                      <div className="overlay-text">{getStringByTheme('redeemed', lang, theme)}</div>
                    )}
                    {isDeleted(ticket) && <div className="overlay-text">{getStringByTheme('deleted', lang, theme)}</div>}
                    <div
                      className={`qrcode-background ${
                        (isRedeemed(ticket) && ticket.displayType !== TICKET_DISPLAY_TYPE.TRANSFERABLE) || isDeleted(ticket) ? 'overlay' : ''
                      }`}
                    >
                      <QRCode id="qrCode" className="qrcode" size={100} value={ticket.qrcode} level={'L'} renderAs={'svg'} />
                    </div>
                    {ticket.callTimestamp && ticket.displayType !== TICKET_DISPLAY_TYPE.TRANSFERABLE && (
                      <div className="call-timestamp">
                        {getStringByTheme('callTimestamp', lang, theme) + ' ' + moment(ticket.callTimestamp).format('YYYY-MM-DD HH:mm')}
                      </div>
                    )}
                    {/* {
                    ticket.deletable && !isDeleted(ticket) &&
                    <TrashIcon className="trash-icon" onClick={deleteTicket} />
                  } */}
                  </div>
                  {ticket.displayType === TICKET_DISPLAY_TYPE.TRANSFERABLE && (
                    <TransferableTicketDetailWrapper
                      ticketTransferDetailList={transferrableTicketList}
                      tableType={color}
                      tableTypeColor={ticket.tableTypeForegroundColor}
                    />
                  )}
                  {ticket && ticket.notifierList && ticket.notifierList.length > 0 && (
                    <>
                      {(ticket.displayType !== TICKET_DISPLAY_TYPE.TRANSFERABLE ||
                        !ticket.ticketTransferDetailList ||
                        ticket.ticketTransferDetailList?.length <= 0) && <div className="middle-line"></div>}
                      {ticket.displayType !== TICKET_DISPLAY_TYPE.TRANSFERABLE &&
                        (subscribed ? (
                          <div className="subscribed-message">
                            <CheckCircleOutlineIcon />
                            {getStringByTheme('subscribed', lang, theme)}
                          </div>
                        ) : 
                        !isDeleted(ticket) && !isRedeemed(ticket)?
                        (
                          <div className="subscription-wrapper">
                            {ticket.notifierList.includes(NOTIFIER.EMAIL) && (
                              <div className="ticket-email">
                                <div className="subscription-title">{getStringByTheme('subscribeViaEmail', lang, theme)}</div>
                                <EmailInput
                                  email={email}
                                  handleEmailChange={handleEmailChange}
                                  subscribeOnClick={emailSubscribeOnClick}
                                  emailError={emailError}
                                />
                              </div>
                            )}
                            {ticket.notifierList.includes(NOTIFIER.WHATSAPP) && (
                              <div className="whatsapp-subscribe-button" onClick={whatsappOnClick}>
                                <WhatsappIcon className="whatsapp-icon" />
                                <span>{getStringByTheme('subscribeViaWhatsapp', lang, theme)}</span>
                              </div>
                            )}
                            {ticket.notifierList.includes(NOTIFIER.SMS) && (
                              <>
                                <div className="subscription-title">{getStringByTheme('subscribeViaSms', lang, theme)}</div>
                                <MobileInput
                                  countryCode={countryCode}
                                  mobile={mobile}
                                  handleCountryCodeChange={handleCountryCodeChange}
                                  handleMobileChange={handleMobileChange}
                                  subscribeOnClick={smsSubscribeOnClick}
                                  mobileError={mobileError}
                                />
                              </>
                            )}
                          </div>
                        ): <></>)}
                    </>
                  )}
                  <div className="ticket-detail-wrapper">
                    {/* conditional render */}
                    {/* transferrable ticket and no notifier list */}
                    {ticket.displayType !== TICKET_DISPLAY_TYPE.TRANSFERABLE && <div className="middle-line"></div>}
                    <div className="ticket-detail">{displayList}</div>
                    <DropDown
                      titleClass="ticket-title"
                      title={getStringByTheme('tnc', lang, theme)}
                      content={ticket.termsAndConditions}
                      location={PATH_LOCATION.TICKET}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`zig-zag zig-zag-bottom zig-zag-${color}`}
                style={{
                  backgroundImage: `
                  linear-gradient(-135deg, ${shadeColor(ticket.tableTypeForegroundColor, -80)} 14px, transparent 0), linear-gradient(135deg, ${shadeColor(ticket.tableTypeForegroundColor, -80)} 14px, transparent 0)`,
                }}
              ></div>
            </div>
          </>
        )}
        <Header showGuluLogo />
      </div>
    </LanguageWrapper>
  );
};

export default Ticket;
