import { getStringByTheme } from '../../../utils/utils';
import { RecordItem } from './RecordItem';
import { useSelector } from 'react-redux';

export const RecordSection = ({ recordTicketList, tableType, tableTypeColor }) => {
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  return (
    recordTicketList?.length > 0 && (
      <div className="record-section-wrapper">
        <div className="record-section-title">
          {getStringByTheme('record', lang, theme)}
        </div>
        {recordTicketList.map((item) => (
          <RecordItem key={item.id} item={item} tableType={tableType} tableTypeColor={tableTypeColor}/>
        ))}
      </div>
    )
  );
};
