import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getStringByTheme } from '../utils/utils';
import { setMessage } from '../reducers/systemSlice';

import '../styles/dialog.scss';

export const Dialog = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.system.lang);
  const message = useSelector((state) => state.system.message);
  const theme = useSelector((state) => state.system.theme);
  const [prefix, setPrefix] = useState('');

  function handleDialogClose() {
    setPrefix('')
    dispatch(setMessage(''));
  }

  useEffect(() => {
    if (message) {
      const parts = message.split('-');
      if (parts[0] === 'ticket') {
        setPrefix(parts[0]);
      }
    }
  }, [message])

  return (
    <>
      {
        message &&
        <div className="dialog-wrapper">
          <div className="dialog">
            {
              prefix === 'ticket' ?
                <div className="dialog-text">{message.replace('ticket-', '')}</div>
                : <div className="dialog-text">{message}</div>
            }
            <div className="dialog-line"></div>
            <div className="dialog-button" onClick={handleDialogClose}>
              {
                prefix === 'ticket' ?
                  getStringByTheme('ticketPopUpConfirm', lang, theme)
                  : getStringByTheme('ok', lang, theme)
              }</div>
          </div>
        </div>
      }
    </>
  )
};
