import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { tableColor, getStringByTheme, showElementByTheme, shadeColor } from '../utils/utils';

import { ELEMENT } from '../constants/constants';

// import { ReactComponent as ReloadLogo } from '../images/reload.svg';
import { ReactComponent as MobileTicketIcon } from '../images/mobile-ticket.svg';
import { ReactComponent as CallLogo } from '../images/call-icon.svg';
import { ReactComponent as CounterLogo } from '../images/counter-icon.svg';

import '../styles/tag-sequence.scss';

export const TagSequence = ({ tagSequence, showTicketLabel = true, showQueueServiceTempUnavailable = false, latestTag }) => {
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  const [color, setColor] = useState('A');

  useEffect(() => {
    if (tagSequence && tagSequence.tableType) {
      setColor(tableColor(tagSequence.tableType));
    }
  }, [tagSequence]);

  const enableBlinkClassName = () => {
    if (latestTag === tagSequence.tagLabel) {
      return true;
    }

    return false;
  };
  return (
    <>
      {tagSequence && (
        <div
          key={tagSequence.tagLabel + tagSequence.updateTimestamp}
          className={`tag-sequence text-${color} background-dark-${color} ${enableBlinkClassName() ? 'blink' : ''}`}
          style={{ color: tagSequence.tableTypeForegroundColor, backgroundColor: shadeColor(tagSequence.tableTypeForegroundColor, -80) }}
        >
          {showElementByTheme(ELEMENT.TAG_SEQUENCE_YOUR_TICKET, theme) && (
                <div className="table-type-label">{getStringByTheme('currentTagSequence', lang, theme)}</div>
              )}
          {showElementByTheme(ELEMENT.TAG_SEQUENCE_TABLE_TYPE, theme) &&  tagSequence.tableTypeLabel && <div className="table-type-label">{tagSequence.tableTypeLabel}</div>}
          <div className="ticket-number">
            <div className="text">
              {showElementByTheme(ELEMENT.TAG_SEQUENCE_CALL_LOGO, theme) && (
                <CallLogo className={`sound-icon`} style={{ path: tagSequence.tableTypeForegroundColor }} />
              )}
              <div className="tag-label">{tagSequence.tagLabel}</div>
              {tagSequence.counterName && (
                <>
                  <CounterLogo className={`counter-icon logo-${color}`} style={{ path: tagSequence.tableTypeForegroundColor }} />
                  <div
                    className={`counter-name bg-${color} ${tagSequence.counterName.length > 1 ? 'long' : ''}`}
                    style={{ path: shadeColor(tagSequence.tableTypeForegroundColor, 80) }}
                  >
                    {tagSequence.counterName}
                  </div>
                </>
              )}
            </div>
            {/* <ReloadLogo className={`reload-icon logo-${color}`} /> */}
          </div>
          {showElementByTheme(ELEMENT.TAG_SEQUENCE_TIME, theme) && (
            <>
              <div
                className={`zig-zag zig-zag-${color}`}
                style={{
                  backgroundImage: `linear-gradient(-45deg, ${tagSequence.tableTypeForegroundColor} 14px, transparent 0), linear-gradient(45deg, ${tagSequence.tableTypeForegroundColor} 14px, transparent 0)`,
                }}
              >
                <div
                  style={{
                    backgroundImage: `linear-gradient(-45deg, ${shadeColor(
                      tagSequence.tableTypeForegroundColor,
                      -80
                    )} 14px, transparent 0), linear-gradient(45deg, ${shadeColor(tagSequence.tableTypeForegroundColor, -80)} 14px, transparent 0)`,
                    marginTop: '1.5px',
                    backgroundPosition: '10px 4.5px',
                    backgroundSize: '12px 20px',
                    backgroundRepeat: 'repeat-x',
                    width: '100%',
                    height: '20px',
                  }}
                ></div>
              </div>
              <div className="time">
                {!showQueueServiceTempUnavailable && (
                  <>
                    <span>{'@ '}</span>
                    <span className="time-label">
                      {tagSequence.updateTimestamp ? moment(tagSequence.updateTimestamp).locale('en').format('HH:mm') : '--:--'}
                    </span>
                  </>
                )}
              </div>
            </>
          )}

          {showTicketLabel && tagSequence.ticketLabel && (
            <div className="ticket-sequence-wrapper">
              {showElementByTheme(ELEMENT.TAG_MOBILE_ICON, theme) && tagSequence.mobileQueueAvailable && <MobileTicketIcon className={`image ${color}`} />}
              <div className="label">{getStringByTheme('ticketSequence', lang, theme)}</div>
              <div className="ticket-sequence">
                <div className="zig-zag"></div>
                <div className={`ticket text-${color}`} style={{ color: tagSequence.tableTypeForegroundColor }}>
                  {tagSequence.ticketLabel}
                </div>
                <div className={`zig-zag zig-zag-bottom`}></div>
              </div>
            </div>
          )}
          {showElementByTheme(ELEMENT.TAG_SEQUENCE_QUOTA, theme) &&
            tagSequence.quota != null &&
            !isNaN(Number(tagSequence.quota)) &&
            Number(tagSequence.quota) >= 0 && <div className="quota">{'(' + getStringByTheme('dailyQuota', lang, theme) + ' ' + tagSequence.quota + ')'}</div>}
        </div>
      )}
    </>
  );
};
