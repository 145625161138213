export const QUEUE_REQUEST_PARAMS = {
  siteId: '',
  size: 0,
  tableType: '',
  selectedDate: '',
  timeSectionId: '',
  ticketType: '',
  tags: [],
  personalData: {
    email: '',
    countryCode: '852',
    mobile: '',
    tncAgreed: false
  },
  ticketTncAgreed: false,
  nextStep: ''
}

export const RESERVATION_REQUEST_PARAMS = {
  siteId: '',
  reservationDate: '',
  selectedTime: '',
  reservationTimestamp: 0,
  size: 0,
  surname: '',
  gender: 'M',
  countryCode: '852',
  mobile: '',
  email: '',
  ticketTncAgreed: false,
  otpValidationDtoMap: {}
}