import React from 'react';
import { useSelector } from 'react-redux';
import { GuluLogo } from '../components/GuluLogo';
import { ReactComponent as LocationIcon } from '../images/location.svg';

import { showElementByTheme, getStrokeColor } from '../utils/utils';
import { ELEMENT } from '../constants/constants';

import moment from 'moment';

import '../styles/site-info.scss';
// import { THEMES } from '../constants/themes';

export const SiteInfo = ({ site, title, show, showLang, location, handleLangClick}) => {

  const theme = useSelector((state) => state.system.theme);
  const stroke = getStrokeColor(location, theme);

  return (
    <div className="site-wrapper">
      {
        site &&
        showElementByTheme(ELEMENT.SITE_DETAIL_SITE_INFO, theme) &&
        <div className={"site" + ((!site.location) ? " title-only" : "")}>
          {
            showElementByTheme(ELEMENT.SITE_INFO_SITE_LOGO, theme) &&
            <div className="site-image">
              <img src={site.imageUrl} alt="site_logo" />
            </div>
          }
          <div className="info">
            <div className="title">{site.name}</div>
            {
              site.location &&
              <div className="address">
                <LocationIcon 
                className="location-icon"
                stroke = {stroke}
                />
                <div className="text">{site.location}</div>
              </div>
            }
          </div>
        </div>
      }
      {
        title &&
        <>
          <div className="site-title">{title}</div>
          {
          showElementByTheme(ELEMENT.SITE_INFO_SITE_DATE, theme) && 
          <div className="site-date">{moment().format('YYYY-MM-DD , dddd')}</div>
          }
        </>
      }
      <GuluLogo show={show} showLang={showLang} handleLangClick={handleLangClick}/>
    </div>
  )
};
