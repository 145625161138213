import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { mergeQueueConfig } from '../utils/queueUtils';
import { setQueueConfig, setRequestParams } from '../reducers/queueSlice';
import { getAvaliableSectionList } from '../utils/queueUtils';
import { getStringByTheme } from '../utils/utils';
import { QUEUE_STEPS } from '../constants/constants';
import { PATH } from '../constants/paths';

import Header from '../components/Header';
import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';

import '../styles/queue-table-size.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';

const QueueTableSize = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  const [tableSizeList, setTableSizeList] = useState([]);

  useEffect(() => {
    document.title = getStringByTheme('queueTableSize', lang, theme);
  }, [lang]);

  useEffect(() => {
    if (!lang) {
      return;
    }

    if (
      !queueConfig ||
      (requestParams.selectedDate && !queueConfig.filterResult)
    ) {
      const newParams = _.cloneDeep(requestParams);
      newParams.siteId = params.siteId;
      post(
        API_URLS.QUEUE,
        newParams,
        (payload) => {
          const newConfig = mergeQueueConfig(queueConfig, payload);
          dispatch(setQueueConfig(newConfig));
        },
        undefined,
        store
      );
    }
  }, [params.siteId, dispatch, store, queueConfig, lang]);

  useEffect(() => {
    if (queueConfig && queueConfig.filterResult) {
      const avaliableSectionList = getAvaliableSectionList(
        queueConfig.steps,
        QUEUE_STEPS.SIZE,
        requestParams,
        queueConfig.filterResult
      );
      const tempSet = new Set();
      avaliableSectionList.forEach((section) => {
        for (let i = section.minSize; i <= section.maxSize; i++) {
          tempSet.add(i);
        }
      });
      setTableSizeList(Array.from(tempSet).sort((a, b) => a - b));
    }
  }, [queueConfig, requestParams]);

  function handleTableSizeClick(tableSize) {
    const newParams = _.cloneDeep(requestParams);
    newParams.size = tableSize;
    dispatch(setRequestParams(newParams));
  }

  function handleSiteDetailButtonClick() {
    history.push(`/${lang}` + PATH.SITE_DETAIL + params.siteId);
  }

  return (
    <LanguageWrapper>
      {queueConfig && (
        <>
          <div className="queue-wrapper">
            <Header site={queueConfig.siteInfo} showSiteInfoGuluLogo={false} />
            <div className="queue-wrapper-content queue-table-size-wrapper">
              <QueueTitle
                title={getStringByTheme('selectTableSize', lang, theme)}
              />
              <div className="number-btn-wrapper">
                {tableSizeList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        'table-size-btn' +
                        (item === requestParams.size ? ' selected' : '')
                      }
                      onClick={() => handleTableSizeClick(item)}
                    >
                      {item}
                    </div>
                  );
                })}{' '}
              </div>
            </div>
            <Header showGuluLogo />
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.SIZE} />
        </>
      )}
      {!queueConfig && (
        <div className="queue-single-button-wrapper">
          <div className="single-button" onClick={handleSiteDetailButtonClick}>
            {getStringByTheme('returnToSiteDetail', lang, theme)}
          </div>
        </div>
      )}
    </LanguageWrapper>
  );
};

export default QueueTableSize;
