import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { ReactComponent as ArrowIcon } from '../images/arrow.svg';
import { ReactComponent as ArrowButtonIcon } from '../images/arrow-button.svg';
import { ReactComponent as RatingIcon } from '../images/star.svg';
import { ReactComponent as RatingFilledIcon } from '../images/star_filled.svg';
import { ReactComponent as CheckIcon } from '../images/check.svg';
import { ReactComponent as ResetIcon } from '../images/reset.svg';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { validateEmail, validateMobile, getStringByTheme } from '../utils/utils';
import { mergeReservationConfig, generateTimeOptions } from '../utils/reservationUtils';
import { setMessage } from '../reducers/systemSlice';
import { setReservationConfig, setRequestParams, setRequestReservationSuccessfully } from '../reducers/reservationSlice';
import { resetTicket } from '../reducers/ticketSlice';
import { PATH } from '../constants/paths';
import { SURVEY_QUESTION_TYPE, DROPDOWN_TEXT_FIELD_TYPE, DELIVERY_TYPE, OTP_TYPE, RESERVATION_MODE, SERVICE_TYPE } from '../constants/constants';

import Header from '../components/Header';
import { QueueTitle } from '../components/QueueTitle';
import Calendar from '../components/Calendar';
import { BannerList } from '../components/BannerList';
import { EmailInput } from '../components/EmailInput';
import { MobileInput } from '../components/MobileInput';
import { SelectTableTypeList } from '../components/SelectTableTypeList';

import '../styles/reservation-date.scss';
import { LanguageWrapper } from '../components/LanguageWrapper';

const ReservationDate = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const reservationConfig = useSelector((state) => state.reservation.reservationConfig);
  const requestParams = useSelector((state) => state.reservation.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const theme = useSelector((state) => state.system.theme);

  const [calendarDisplayDate, setCalendarDisplayDate] = useState(moment().set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [timeOptionGroup, setTimeOptionGroups] = useState([]);
  const [surnameError, setSurnameError] = useState('');
  const [mobileError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [surveyError, setSurveyError] = useState({});
  const [valid, setValid] = useState(false);

  useEffect(() => {
    const wrapperList = document.getElementsByClassName('reservation-wrapper');
    if (wrapperList && wrapperList[0]) {
      wrapperList[0].scrollTo(0, 0);
    }
    dispatch(resetTicket());
    dispatch(setRequestReservationSuccessfully(false));
  }, []);

  useEffect(() => {
    document.title = getStringByTheme('reservationDate', lang, theme);
  }, [lang]);

  useEffect(() => {
    if (reservationConfig) {
      const validSurvey = validateSurvey(requestParams);

      if (
        ((reservationConfig.mode === RESERVATION_MODE.SERVICE && requestParams.refTableTypeId) ||
          (reservationConfig.mode === RESERVATION_MODE.SIZE && requestParams.size) ||
          (reservationConfig.mode === RESERVATION_MODE.SERVICE_WITH_SIZE && requestParams.size && requestParams.refTableTypeId) ||
          reservationConfig.mode === RESERVATION_MODE.NO_SELECTION) &&
        requestParams.reservationDate &&
        requestParams.selectedTime &&
        requestParams.gender &&
        requestParams.surname &&
        !surnameError &&
        requestParams.countryCode &&
        requestParams.mobile &&
        !mobileError &&
        (reservationConfig.deliveryType === DELIVERY_TYPE.NONE ||
          (reservationConfig.deliveryType === DELIVERY_TYPE.SMS &&
            requestParams.otpValidationDtoMap[requestParams.countryCode + requestParams.mobile]?.password?.length >= 6) ||
          (reservationConfig.deliveryType === DELIVERY_TYPE.EMAIL &&
            // requestParams.otpValidationDtoMap[requestParams.email] &&
            // requestParams.otpValidationDtoMap[requestParams.email]?.password &&
            requestParams.email)) &&
        // && (reservationConfig.deliveryType !== DELIVERY_TYPE.SMS ||
        //   (reservationConfig.deliveryType === DELIVERY_TYPE.SMS
        //     && requestParams.otpValidationDtoMap[requestParams.countryCode + requestParams.mobile]?.password?.length >= 6)
        // )
        !emailError &&
        // && (reservationConfig.deliveryType !== DELIVERY_TYPE.EMAIL ||
        //   (reservationConfig.deliveryType === DELIVERY_TYPE.EMAIL
        //     && requestParams.otpValidationDtoMap[requestParams.email]
        //     && requestParams.otpValidationDtoMap[requestParams.email]?.password
        //     && requestParams.email)
        // )
        validSurvey
      ) {
        setValid(true);
      } else {
        setValid(false);
      }
    }
  }, [requestParams]);

  useEffect(() => {
    if (!lang) {
      return;
    }

    const newParams = _.cloneDeep(requestParams);
    newParams.siteId = params.siteId;
    if (newParams.displayTableType) {
      newParams.tableType = newParams.displayTableType.refTableTypeId;
    }
    post(
      API_URLS.RESERVATION_CONFIG,
      newParams,
      (payload) => {
        const newConfig = mergeReservationConfig(reservationConfig, payload);

        let displayDate = moment();
        if (newParams.reservationDate) {
          displayDate = moment(newParams.reservationDate);
        } else if (newConfig.startTimestamp) {
          displayDate = moment(newConfig.startTimestamp);
        }
        setCalendarDisplayDate(displayDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));

        if (newParams.reservationDate && newConfig.timeSessionList && newConfig.timeInterval) {
          setTimeOptionGroups(generateTimeOptions(newConfig.timeSessionList, newConfig.timeInterval));
        }

        dispatch(setReservationConfig(newConfig));
      },
      (data) => {
        dispatch(setMessage(data.message));
        setTimeOptionGroups([]);
        newParams.selectedTime = '';
        dispatch(setRequestParams(newParams));
      },
      store
    );
  }, [requestParams.reservationDate, requestParams.size, requestParams.refTableTypeId, lang]);

  const handleSizeClick = (size) => {
    const newParams = _.cloneDeep(requestParams);
    newParams.reservationDate = null;
    if (size !== newParams.size) {
      newParams.size = size;
      dispatch(setRequestParams(newParams));
    }
  };

  const handleTableTypeClick = (tableType) => {
    const newParams = _.cloneDeep(requestParams);
    newParams.reservationDate = null;
    newParams.size = 0;
    if (tableType.refTableTypeId !== newParams.refTableTypeId) {
      newParams.refTableTypeId = tableType.refTableTypeId;
      newParams.displayTableType = tableType;
      dispatch(setRequestParams(newParams));
    }
  };

  const handleMonthClick = (displayDate) => {
    setCalendarDisplayDate(
      moment(displayDate).set({
        date: 1,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    );
  };

  const handleDateClick = (selectedDate) => {
    const newParams = _.cloneDeep(requestParams);
    if (selectedDate !== newParams.reservationDate) {
      newParams.reservationDate = selectedDate;
      newParams.selectedTime = '';
      dispatch(setRequestParams(newParams));
    }
  };

  const handleTimeClick = (selectedTime) => {
    const newParams = _.cloneDeep(requestParams);
    newParams.selectedTime = newParams.selectedTime === selectedTime ? '' : selectedTime;
    dispatch(setRequestParams(newParams));
  };

  const handleNameTitleChange = (event) => {
    const newParams = _.cloneDeep(requestParams);
    newParams.gender = event.target.value;
    dispatch(setRequestParams(newParams));
  };

  const handleSurnameChange = (event) => {
    const newParams = _.cloneDeep(requestParams);
    newParams.surname = event.target.value;
    setSurnameError(event.target.value !== '' ? '' : getStringByTheme('surnameError', lang, theme));
    dispatch(setRequestParams(newParams));
  };

  const handleCountryCodeChange = (event) => {
    const newParams = _.cloneDeep(requestParams);
    newParams.countryCode = event.target.value;
    dispatch(setRequestParams(newParams));
  };

  const handleMobileChange = (event) => {
    const newParams = _.cloneDeep(requestParams);
    newParams.mobile = event.target.value;
    setPhoneError(event.target.value === '' || validateMobile(event.target.value) ? '' : getStringByTheme('phoneError', lang, theme));
    dispatch(setRequestParams(newParams));
  };

  const handleEmailChange = (event) => {
    const newParams = _.cloneDeep(requestParams);
    newParams.email = event.target.value;
    setEmailError(event.target.value === '' || validateEmail(event.target.value) ? '' : getStringByTheme('emailError', lang, theme));
    dispatch(setRequestParams(newParams));
  };

  const sendOtp = ({ type, countryCode, mobile, email }) => {
    const newParams = _.cloneDeep(requestParams);
    let otpValidationDto = undefined;
    let otpValue = undefined;
    const postParams = {
      otpType: type,
      serviceType: SERVICE_TYPE.RESERVATION,
      siteId: params.siteId,
    };
    switch (type) {
      case OTP_TYPE.EMAIL:
        postParams.email = email;
        otpValue = email;
        break;
      case OTP_TYPE.SMS:
      case OTP_TYPE.WHATSAPP:
        postParams.countryCode = countryCode;
        postParams.mobile = mobile;
        otpValue = countryCode + mobile;
        break;
      default:
    }
    otpValidationDto = newParams.otpValidationDtoMap[otpValue];
    if (otpValidationDto) {
      postParams.id = otpValidationDto.id;
    }

    post(
      API_URLS.REQUEST_OTP,
      postParams,
      (payload) => {
        if (otpValidationDto) {
          otpValidationDto.id = payload.id;
        } else {
          newParams.otpValidationDtoMap[otpValue] = {
            id: payload.id,
            type: type,
            value: otpValue,
            password: '',
            verified: false,
          };
        }
        dispatch(setRequestParams(newParams));
      },
      undefined,
      store
    );
  };

  const handleOtpChange = ({ type, password, countryCode, mobile, email }) => {
    const newParams = _.cloneDeep(requestParams);
    let otpValue = undefined;
    switch (type) {
      case OTP_TYPE.EMAIL:
        otpValue = email;
        break;
      case OTP_TYPE.SMS:
      case OTP_TYPE.WHATSAPP:
        otpValue = countryCode + mobile;
        break;
      default:
    }
    const otpValidationDto = newParams.otpValidationDtoMap[otpValue];
    if (otpValidationDto) {
      otpValidationDto.password = password;
      otpValidationDto.error = '';
      otpValidationDto.verified = false;
      dispatch(setRequestParams(newParams));
    }
  };

  const verifyOtp = () => {
    if (valid) {
      const newParams = _.cloneDeep(requestParams);
      if (reservationConfig.deliveryType === DELIVERY_TYPE.NONE || reservationConfig.deliveryType === DELIVERY_TYPE.EMAIL) {
        handleReservation(newParams);
      } else {
        Object.keys(newParams.otpValidationDtoMap).forEach((key) => {
          if (key !== newParams.countryCode + newParams.mobile && key !== newParams.email) {
            delete newParams.otpValidationDtoMap[key];
          }
        });
        const postParams = Object.keys(newParams.otpValidationDtoMap)
          .filter((key) => key === newParams.countryCode + newParams.mobile || key === newParams.email)
          .map((key) => {
            return {
              id: newParams.otpValidationDtoMap[key].id,
              type: newParams.otpValidationDtoMap[key].type,
              value: newParams.otpValidationDtoMap[key].value,
              password: newParams.otpValidationDtoMap[key].password,
            };
          });
        if (postParams.length > 0) {
          post(
            API_URLS.VERIFY_OTP,
            postParams,
            (payload) => {
              payload.forEach((result) => {
                const otpItem = newParams.otpValidationDtoMap[result.value];
                if (otpItem) {
                  otpItem.verified = result.validate;
                  otpItem.error = result.errorMessage;
                }
              });
              dispatch(setRequestParams(newParams));
              if (Object.keys(newParams.otpValidationDtoMap).every((key) => newParams.otpValidationDtoMap[key].verified)) {
                handleReservation(newParams);
              }
            },
            (data) => {
              dispatch(setRequestParams(newParams));
              dispatch(setMessage(data.message));
            },
            store
          );
        } else {
          dispatch(setRequestParams(newParams));
        }
      }
    }
  };

  const handleReservation = (reservationParams) => {
    if (valid) {
      const newParams = _.cloneDeep(reservationParams);
      const selectedDate = moment(newParams.reservationDate);
      const selectedTime = moment(Number(newParams.selectedTime));

      newParams.reservationTimestamp = selectedDate
        .set({
          hour: selectedTime.get('hour'),
          minute: selectedTime.get('minute'),
          second: selectedTime.get('second'),
          millisecond: selectedTime.get('millisecond'),
        })
        .valueOf();
      newParams.deliveryType = reservationConfig.deliveryType;
      dispatch(setRequestParams(newParams));
      history.push(`/${lang}` + PATH.RESERVATION_PREVIEW + params.siteId);
    }
  };

  const handleAnswer = (questionId, questionType, answerKey, value, dropdownType) => {
    // console.log('questionId', questionId);
    // console.log('value', value);
    const newParams = _.cloneDeep(requestParams);
    if (!newParams.survey) {
      newParams.survey = {};
    }
    const currentAnswer = newParams.survey[questionId] ? newParams.survey[questionId] : undefined;
    if (currentAnswer) {
      switch (questionType) {
        // case SURVEY_QUESTION_TYPE.RADIO:
        // case SURVEY_QUESTION_TYPE.DROPDOWN:
        //   break;
        case SURVEY_QUESTION_TYPE.IMAGELIST:
          break;
        case SURVEY_QUESTION_TYPE.CHECKBOX:
          const tempAns = currentAnswer.answerList.find((ans) => ans.key === answerKey);
          if (tempAns) {
            currentAnswer.answerList = currentAnswer.answerList.filter((ans) => ans.key !== answerKey);
          } else {
            currentAnswer.answerList.push({ key: answerKey, value: value });
          }
          break;
        case SURVEY_QUESTION_TYPE.DROPDOWN_TEXT_FIELD:
          switch (dropdownType) {
            case DROPDOWN_TEXT_FIELD_TYPE.DROPDOWN:
              currentAnswer.dropdown = { key: answerKey, value: value };
              break;
            default:
              currentAnswer.textfield = value;
          }
          break;
        default:
          currentAnswer.key = answerKey;
          currentAnswer.value = value;
      }
    } else {
      switch (questionType) {
        // case SURVEY_QUESTION_TYPE.RADIO:
        // case SURVEY_QUESTION_TYPE.DROPDOWN:
        //   break;
        case SURVEY_QUESTION_TYPE.IMAGELIST:
          break;
        case SURVEY_QUESTION_TYPE.CHECKBOX:
          newParams.survey[questionId] = {
            questionId: questionId,
            answerList: [{ key: answerKey, value: value }],
          };
          break;
        case SURVEY_QUESTION_TYPE.DROPDOWN_TEXT_FIELD:
          const tempAnswer = {
            questionId: questionId,
          };
          switch (dropdownType) {
            case DROPDOWN_TEXT_FIELD_TYPE.DROPDOWN:
              tempAnswer.dropdown = { key: answerKey, value: value };
              break;
            default:
              tempAnswer.textfield = value;
          }
          newParams.survey[questionId] = tempAnswer;
          break;
        default:
          newParams.survey[questionId] = {
            questionId: questionId,
            key: answerKey,
            value: value,
          };
      }
    }
    dispatch(setRequestParams(newParams));
  };

  const generateChoices = (question) => {
    const currentAnswer = requestParams.survey ? requestParams.survey[question.questionId] : undefined;
    let minText = '';
    switch (question.type) {
      case SURVEY_QUESTION_TYPE.DROPDOWN:
        return (
          <div className="select-wrapper">
            <select
              name="options"
              className="picker"
              value={currentAnswer ? currentAnswer.key : ''}
              onChange={(event) => {
                handleAnswer(
                  question.questionId,
                  question.type,
                  event.target.value,
                  question.choices.find((choice) => choice.key === event.target.value)?.value
                );
              }}
            >
              <option key={'default'} disabled value="">
                {getStringByTheme('select', lang, theme)}
              </option>
              {question.choices.map((option) => {
                return (
                  <option key={option.key} value={option.key}>
                    {option.value}
                  </option>
                );
              })}
            </select>
            <ArrowIcon className="image" />
          </div>
        );
      case SURVEY_QUESTION_TYPE.RATING:
        const ratings = [];
        for (var i = 1; i <= question.maxChoiceSize; i++) {
          ratings.push(generateRating(question, i, currentAnswer));
        }
        return <div className="rating-wrapper">{ratings}</div>;
      case SURVEY_QUESTION_TYPE.RADIO:
        return (
          <div className="radio-list-wrapper">
            {question.choices.map((option) => {
              return (
                <div
                  key={question.questionId + option.key}
                  className="radio-wrapper"
                  onClick={() => {
                    handleAnswer(question.questionId, question.type, option.key, option.value);
                  }}
                >
                  <input type="radio" name={question.questionId} checked={currentAnswer ? currentAnswer.key === option.key : ''} readOnly />
                  {option.value}
                </div>
              );
            })}
          </div>
        );
      case SURVEY_QUESTION_TYPE.CHECKBOX:
        return (
          <div className="checkbox-list-wrapper">
            <div className="choice-range">{'(' + question.minChoiceSize + ' - ' + question.maxChoiceSize + ')'}</div>
            {question.choices.map((option) => {
              return (
                <div
                  key={question.questionId + option.key}
                  className="checkbox-wrapper"
                  onClick={() => {
                    handleAnswer(question.questionId, question.type, option.key, option.value);
                  }}
                >
                  <div className="checkbox">
                    {currentAnswer && currentAnswer.answerList && currentAnswer.answerList.find((answer) => option.key === answer.key) && (
                      <CheckIcon className="check-icon" />
                    )}
                  </div>
                  {option.value}
                </div>
              );
            })}
          </div>
        );
      case SURVEY_QUESTION_TYPE.DROPDOWN_TEXT_FIELD:
        return (
          <div className="drop-down-text-wrapper">
            <div className={`drop-down-text ${surveyError[question.questionId] ? 'error' : ''}`}>
              <div className="drop-down-wrapper">
                <select
                  value={currentAnswer && currentAnswer.dropdown ? currentAnswer.dropdown.key : ''}
                  onChange={(event) => {
                    handleAnswer(
                      question.questionId,
                      question.type,
                      event.target.value,
                      question.choices.find((choice) => choice.key === event.target.value).value,
                      DROPDOWN_TEXT_FIELD_TYPE.DROPDOWN
                    );
                  }}
                  className="drop-down-select"
                  name="drop-down"
                >
                  <option key={'default'} disabled value="">
                    {getStringByTheme('select', lang, theme)}
                  </option>
                  {question.choices.map((option) => {
                    return (
                      <option key={option.key} value={option.key}>
                        {option.value}
                      </option>
                    );
                  })}
                </select>
                <ArrowIcon className="image " />
              </div>
              <input
                value={currentAnswer && currentAnswer.textfield ? currentAnswer.textfield : ''}
                type="text"
                onChange={(event) => {
                  handleAnswer(question.questionId, question.type, undefined, event.target.value, DROPDOWN_TEXT_FIELD_TYPE.TEXT_FIELD);
                }}
                className="text-input"
                name="text"
                placeholder={question.answerLabel + (question.mandatory ? '*' : '')}
                max={question.maxTextSize}
              />
            </div>
          </div>
        );
      case SURVEY_QUESTION_TYPE.TEXT_AREA:
        if (question.minTextSize && question.minTextSize > 0) {
          minText = question.minTextSize + ' - ';
        }
        return (
          <div className="input-text-wrapper">
            <div className="text-limit">{'(' + minText + question.maxTextSize + getStringByTheme('characters', lang, theme) + ')'}</div>
            <textarea
              className="input-text"
              rows={4}
              maxLength={question.maxTextSize}
              placeholder={question.answerLabel + (question.mandatory ? '*' : '')}
              value={currentAnswer ? currentAnswer.value : ''}
              onChange={(event) => {
                handleAnswer(question.questionId, question.type, undefined, event.target.value);
              }}
            />
          </div>
        );
      default:
        if (question.minTextSize && question.minTextSize > 0) {
          minText = question.minTextSize + ' - ';
        }
        return (
          <div className="input-text-wrapper">
            <div className="text-limit">{'(' + minText + question.maxTextSize + getStringByTheme('characters', lang, theme) + ')'}</div>
            <input
              className="input-text"
              type="text"
              maxLength={question.maxTextSize}
              placeholder={question.answerLabel + (question.mandatory ? '*' : '')}
              value={currentAnswer ? currentAnswer.value : ''}
              onChange={(event) => {
                handleAnswer(question.questionId, question.type, undefined, event.target.value);
              }}
            />
          </div>
        );
    }
  };

  const generateRating = (question, i, currentAnswer) => {
    return currentAnswer && currentAnswer.value && currentAnswer.value >= i ? (
      <RatingFilledIcon
        key={'rating-' + i}
        className="image"
        onClick={() => {
          handleAnswer(question.questionId, question.type, undefined, i);
        }}
      />
    ) : (
      <RatingIcon
        key={'rating-' + i}
        className="image"
        onClick={() => {
          handleAnswer(question.questionId, question.type, undefined, i);
        }}
      />
    );
  };

  const validateSurvey = (params) => {
    const tempSurveyError = {};
    if (!reservationConfig || !reservationConfig.surveyDetail || !reservationConfig.surveyDetail.questionList) {
      return true;
    }
    let valid = true;
    for (var i = 0; i < reservationConfig.surveyDetail.questionList.length; i++) {
      const question = reservationConfig.surveyDetail.questionList[i];
      if (!question.mandatory) {
        continue;
      }

      if (!params.survey || !params.survey[question.questionId]) {
        valid = false;
        break;
      } else {
        const answer = params.survey[question.questionId];
        if (!answer) {
          valid = false;
        } else {
          switch (question.type) {
            case SURVEY_QUESTION_TYPE.RADIO:
            case SURVEY_QUESTION_TYPE.DROPDOWN:
              if (!answer.key || !answer.value) {
                valid = false;
              }
              break;
            case SURVEY_QUESTION_TYPE.RATING:
              if (isNaN(Number(answer.value)) || Number(answer.value) < question.minChoiceSize || Number(answer.value) > question.maxChoiceSize) {
                valid = false;
              }
              break;
            case SURVEY_QUESTION_TYPE.CHECKBOX:
              if (!answer.answerList || answer.answerList.length < question.minChoiceSize || answer.answerList.length > question.maxChoiceSize) {
                valid = false;
              }
              break;
            case SURVEY_QUESTION_TYPE.DROPDOWN_TEXT_FIELD:
              if (
                !answer.dropdown ||
                !answer.textfield ||
                !answer.dropdown.key ||
                answer.textfield.length < question.minTextSize ||
                answer.textfield.length > question.maxTextSize
              ) {
                valid = false;
              }
              break;
            default:
              if (!answer.value || answer.length < question.minTextSize || answer.length > question.maxTextSize) {
                valid = false;
              }
          }
        }
      }

      if (!valid) {
        tempSurveyError[question.questionId] = getStringByTheme('surveyError', lang, theme);
        break;
      }
    }
    setSurveyError(tempSurveyError);
    return valid;
  };

  // function handleSiteDetailButtonClick() {
  //   history.push(PATH.SITE_DETAIL + params.siteId);
  // }

  const handleReset = (questionId) => {
    const newParams = _.cloneDeep(requestParams);
    newParams.survey[questionId] = null;
    dispatch(setRequestParams(newParams));
  };

  const isSizeSelectRequired = () => {
    if (reservationConfig.mode !== RESERVATION_MODE.SIZE && reservationConfig.mode !== RESERVATION_MODE.SERVICE_WITH_SIZE) {
      return false;
    }

    if (!reservationConfig.tableSizeList || reservationConfig.tableSizeList?.length === 0) {
      return false;
    }

    return true;
  };

  const isSizeSelectMessgeShown = () => {
    if (reservationConfig.mode !== RESERVATION_MODE.SIZE && reservationConfig.mode !== RESERVATION_MODE.SERVICE_WITH_SIZE) {
      return false;
    }

    if (reservationConfig.tableSizeList?.length === 0) {
      return false;
    }

    return requestParams.size <= 0;
  };

  const isCalendarShown = () => {
    if (reservationConfig.mode === RESERVATION_MODE.SIZE) {
      return requestParams.size > 0;
    }

    if (reservationConfig.mode === RESERVATION_MODE.SERVICE) {
      return requestParams.refTableTypeId;
    }

    if (reservationConfig.mode === RESERVATION_MODE.SERVICE_WITH_SIZE) {
      return requestParams.size > 0 && requestParams.refTableTypeId;
    }

    return true;
  };

  const isTableTypeSelectShown = () => {
    if (reservationConfig.mode !== RESERVATION_MODE.SERVICE && reservationConfig.mode !== RESERVATION_MODE.SERVICE_WITH_SIZE) {
      return false;
    }

    if (!reservationConfig.tableTypeList || reservationConfig.tableTypeList?.length === 0) {
      return false;
    }

    return true;
  };

  const isTableTypeSelectMessgeShown = () => {
    if (reservationConfig.mode !== RESERVATION_MODE.SERVICE && reservationConfig.mode !== RESERVATION_MODE.SERVICE_WITH_SIZE) {
      return false;
    }

    if (reservationConfig.tableTypeList?.length === 0) {
      return false;
    }

    return !requestParams.refTableTypeId;
  };

  return (
    <LanguageWrapper>
      {reservationConfig && (
        <>
          <div className="reservation-wrapper">
            <Header site={reservationConfig.siteInfo} showPoweredBy={true} showSiteInfoGuluLogo={false} showLanguageButtons />
            {reservationConfig.rejectReason && <div className="reject-reason">{reservationConfig.rejectReason}</div>}
            {reservationConfig.startTimestamp && reservationConfig.expiredTimestamp && (
              <div className="reservation-wrapper-content reservation-date-wrapper">
                {isTableTypeSelectShown() && (
                  <SelectTableTypeList
                    title={reservationConfig.tableTypeTitle}
                    tableTypeList={reservationConfig.tableTypeList}
                    requestParams={requestParams}
                    handleTableTypeClick={handleTableTypeClick}
                  />
                )}
                {isSizeSelectRequired() && (
                  <>
                    <QueueTitle title={getStringByTheme('selectTableSize', lang, theme)} />
                    <div className="table-size-wrapper">
                      <div className="options">
                        {reservationConfig.tableSizeList.map((size, index) => {
                          return (
                            <div key={index} className={`option ${size === requestParams.size ? 'selected' : ''}`} onClick={() => handleSizeClick(size)}>
                              {size}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
                <QueueTitle title={getStringByTheme('selectDate', lang, theme)} />
                <div className="select-date-wrapper">
                  {isSizeSelectMessgeShown() && <div className="select-date-message">{getStringByTheme('requiredSelectSize', lang, theme)}</div>}
                  {isTableTypeSelectMessgeShown() && (
                    <div className="select-date-message">
                      {reservationConfig.tableTypeTitle ? reservationConfig.tableTypeTitle : getStringByTheme('queueServiceType', lang, theme)}
                    </div>
                  )}
                  {isCalendarShown() && (
                    <Calendar
                      startDate={reservationConfig.startTimestamp && moment(reservationConfig.startTimestamp).format('YYYY-MM-DD')}
                      endDate={reservationConfig.expiredTimestamp && moment(reservationConfig.expiredTimestamp).format('YYYY-MM-DD')}
                      calendarDisplayDate={calendarDisplayDate}
                      disableDateList={reservationConfig.blackoutDateList}
                      selectedDate={requestParams.reservationDate}
                      requestParams={requestParams}
                      handleDateClick={handleDateClick}
                      handleMonthClick={handleMonthClick}
                    />
                  )}
                </div>
                <>
                  <QueueTitle title={getStringByTheme('selectTime', lang, theme)} />
                  <div className="select-time-wrapper">
                    {!requestParams.reservationDate && <div className="select-time-message">{getStringByTheme('requiredSelectDate', lang, theme)}</div>}
                    {requestParams.reservationDate && (
                      <>
                        {timeOptionGroup.length === 0 || timeOptionGroup.every((group) => group.timeOptions?.every((option) => !option.haveQuota)) ? (
                          <div className="select-time-message">{getStringByTheme('noTimeOption', lang, theme)}</div>
                        ) : (
                          timeOptionGroup.length > 0 &&
                          timeOptionGroup.map((group) => {
                            return (
                              <div key={group.groupedSessionId} className="option-group">
                                <div className="group-description">{group.description}</div>
                                <div className="options">
                                  {group.timeOptions.map((option, optionIndex) => {
                                    return (
                                      <div
                                        key={optionIndex}
                                        className={`option ${!option.haveQuota ? 'disable' : ''} ${
                                          option.value === requestParams.selectedTime ? 'selected' : ''
                                        }`}
                                        onClick={() => {
                                          if (option.haveQuota) {
                                            handleTimeClick(option.value);
                                          }
                                        }}
                                      >
                                        {option.label}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })
                        )}
                      </>
                    )}
                  </div>
                </>
                {
                  <>
                    <QueueTitle title={getStringByTheme('selectPersonalInfo', lang, theme)} />
                    <div className="surname-wrapper">
                      <div className={`surname ${mobileError ? 'error' : ''}`}>
                        <div className="name-title-wrapper">
                          <select value={requestParams.gender} onChange={handleNameTitleChange} className="name-title-select" id="name-title" name="name-title">
                            <option value="M">{getStringByTheme('mr', lang, theme)}</option>
                            <option value="F">{getStringByTheme('ms', lang, theme)}</option>
                            <option value="A">{getStringByTheme('mrs', lang, theme)}</option>
                          </select>
                          <ArrowIcon className="image" />
                        </div>
                        <input
                          value={requestParams.surname}
                          type="text"
                          onChange={handleSurnameChange}
                          className="surname-input"
                          id="surname"
                          name="surname"
                          placeholder={getStringByTheme('surnameTitle', lang, theme)}
                        />
                      </div>
                      {surnameError && <div className="error-message name-error-message">{surnameError}</div>}
                    </div>
                    <MobileInput
                      deliveryType={reservationConfig.deliveryType}
                      countryCode={requestParams.countryCode}
                      mobile={requestParams.mobile}
                      handleCountryCodeChange={handleCountryCodeChange}
                      handleMobileChange={handleMobileChange}
                      mobileError={mobileError}
                      enableOtp={reservationConfig.deliveryType === DELIVERY_TYPE.SMS || reservationConfig.deliveryType === DELIVERY_TYPE.WHATSAPP}
                      sendOtp={sendOtp}
                      handleOtpChange={handleOtpChange}
                      otp={requestParams.otpValidationDtoMap[requestParams.countryCode + requestParams.mobile]}
                      // otpError={otpError}
                    />
                    {reservationConfig.deliveryType === DELIVERY_TYPE.EMAIL && (
                      <EmailInput email={requestParams.email} handleEmailChange={handleEmailChange} emailError={emailError} />
                    )}
                  </>
                }
                {reservationConfig.surveyDetail && (
                  <>
                    <QueueTitle title={reservationConfig.surveyDetail.title} />
                    <div className="survey-content">
                      {reservationConfig.surveyDetail.description && <div className="survey-description">{reservationConfig.surveyDetail.description}</div>}
                      {reservationConfig.surveyDetail.imageUrlList && <BannerList bannerList={reservationConfig.surveyDetail.imageUrlList} displayUnderLine />}
                      {reservationConfig.surveyDetail.questionList &&
                        reservationConfig.surveyDetail.questionList.map((question) => {
                          return (
                            <div key={question.questionId} className="question">
                              <div className="question-title">
                                {(question.mandatory ? '*' : '') + question.title}
                                <div className="reset-button" onClick={() => handleReset(question.questionId, question.type)}>
                                  <ResetIcon className="reset-icon" />
                                </div>
                              </div>
                              {generateChoices(question)}
                              {surveyError[question.questionId] && <div className="error-message">{surveyError[question.questionId]}</div>}
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
              </div>
            )}
            <Header showGuluLogo />
          </div>
          {reservationConfig.startTimestamp && reservationConfig.expiredTimestamp && (
            <div className="reservation-bottom-btn-wrapper">
              <div onClick={verifyOtp} className={'reservation-button next' + (!valid ? ' disable' : '')}>
                <ArrowButtonIcon className="arrow prev" />
              </div>
            </div>
          )}
        </>
      )}
      {/* {
        !reservationConfig &&
        <div className="queue-single-button-wrapper">
          <div className="single-button" onClick={handleSiteDetailButtonClick}>{getStringByTheme('returnToSiteDetail', lang, theme)}</div>
        </div>
      } */}
    </LanguageWrapper>
  );
};

export default ReservationDate;
