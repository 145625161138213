// import { useSelector } from 'react-redux';
import { ReactComponent as CheckInIcon } from '../../..//images/transferrable-check-in.svg';
import { ReactComponent as CheckOutIcon } from '../../..//images/transferrable-check-out.svg';
// import { getStringByTheme, tableColor } from '../../../utils/utils';

import moment from 'moment';

export const RecordItem = ({ item, tableType, tableTypeColor }) => {
  // const lang = useSelector((state) => state.system.lang);
  // const theme = useSelector((state) => state.system.theme);

  return (
    <div className="record-item-wrapper">
      <div className="record-detail">
        {item.tableTypeName && (
          <div className="record-table-type">{item.tableTypeName}</div>
        )}
        {item.counterName && (
          <div className="record-counter-name">{item.counterName}</div>
        )}
        <div className="record-timestamp">
          {item.checkOutTimestamp
            ? moment(item.checkOutTimestamp).locale('en').format('HH:mm')
            : moment(item.checkInTimestamp).locale('en').format('HH:mm')}
        </div>
      </div>

      <div className={`record-icon ${tableType}`} style={{color: tableTypeColor}}>
        {item.checkOutTimestamp ? <CheckOutIcon /> : <CheckInIcon />}
      </div>
    </div>
  );
};
