import { useState } from 'react';

// import { useSelector } from 'react-redux';

import { ReactComponent as ArrowIcon } from '../images/arrow.svg';
// import { getStrokeColor } from '../utils/utils';
import '../styles/drop-down.scss';

export const DropDown = ({ prefixTitle, titleClass, title, contentClass, content, open = true, location }) => {
  const [tncClick, setTncClick] = useState(open);
  // const theme = useSelector((state) => state.system.theme);
  // const stroke = getStrokeColor(location, theme);

  return (
    <>
      <div className={"drop-down-wrapper " + ((titleClass) ? titleClass : "")}>
        {prefixTitle}
        <div className="drop-down-title-wrapper" onClick={() => setTncClick(!tncClick)}>
          <div className="drop-down-title">{title}</div>
          <div className="dropdown-arrow">
              <ArrowIcon className={"image " + ((tncClick) ? "change" : "")} />
          </div>
        </div>
      </div>
      <div className={`drop-down-content ${(tncClick) ? (contentClass) ? contentClass : "" : "display-none"}`} dangerouslySetInnerHTML={{ __html: content }}></div>
    </>
  )
};