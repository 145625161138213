import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
// import * as _ from 'lodash';
import { Helmet } from 'react-helmet';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { setSite, setRejectReasonMessage } from '../reducers/siteSlice';
import {
  setQueueConfig,
  setRequestParams,
  setRequestTicketSuccessfully,
  setTagSequenceList,
  setCounterTagList,
  setCounterList,
} from '../reducers/queueSlice';
import {
  subscribeMqttTopic,
  unsubscribeMqttTopic
} from '../reducers/systemSlice';
import { resetTicket } from '../reducers/ticketSlice';
import { getQueuePageByStep, getTagLabel } from '../utils/queueUtils';
import { getStringByTheme, showElementByTheme } from '../utils/utils';
import { QUEUE_REQUEST_PARAMS } from '../constants/initialStates';
import { PATH } from '../constants/paths';
import {
  NAVIGATION,
  ELEMENT,
  PATH_LOCATION,
  COUNTER_TAG_TYPE,
  MQTT,
} from '../constants/constants';
import Header from '../components/Header';

import { QueueTitle } from '../components/QueueTitle';
import { BannerList } from '../components/BannerList';
import { DropDown } from '../components/DropDown';
import { TagSequence } from '../components/TagSequence';
import { Counter } from '../components/Counter';
// import { ReactComponent as Zigzag } from '../images/zigzag.svg';
import { ReactComponent as GetTicket } from '../images/get-ticket.svg';
// import { ReactComponent as ServiceTypeIcon } from '../images/service-type.svg';

// import { ReactComponent as ArrowIcon } from '../images/arrow.svg';
import { ReactComponent as CalendarIcon } from '../images/calendar.svg';
import { ReactComponent as QrcodeTicketIcon } from '../images/qrcode-ticket.svg';

import '../styles/site-detail.scss';
import moment from 'moment';
import { LanguageWrapper } from '../components/LanguageWrapper';

const Restaurant = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const site = useSelector((state) => state.site.site);
  const rejectReasonMessage = useSelector(
    (state) => state.site.rejectReasonMessage
  );
  const tagSequenceList = useSelector((state) => state.queue.tagSequenceList);
  const lang = useSelector((state) => state.system.lang);
  const token = useSelector((state) => state.system.token);
  const theme = useSelector((state) => state.system.theme);
  const counterTagList = useSelector((state) => state.queue.counterTagList);
  const counterList = useSelector((state) => state.queue.counterList);
  const latestTag = useSelector((state) => state.queue.latestTag);

  // const [expandTagSequenceList, setExpandTagSequenceList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showQueueServiceTempUnavailable, setShowQueueServiceTempUnavailable] =
    useState(false);
  const [queueReadOnly, setQueueReadOnly] = useState(false);
  const [currentTimeSectionId, setCurrentTimeSectionId] = useState('');

  useEffect(() => {
    return function unsubscribeMqtt() {
      tagSequenceList?.forEach((tagSequence) => {
        dispatch(
          unsubscribeMqttTopic(
            [
              MQTT.VERSION,
              params.siteId,
              MQTT.CHANNEL,
              MQTT.QUEUE,
              tagSequence.timeSessionId,
              tagSequence.tableType,
            ].join('/')
          )
        );
      });
    };
  }, []);

  useEffect(() => {
    if (lang) {
      document.title = getStringByTheme('siteTitle', lang, theme);
    }
  }, [lang]);

  useEffect(() => {
    const searchParams = new URL(document.location).searchParams;
    if (!token && searchParams.get('qrcode')) {
      window.location =
        'thegulu://web?action=' +
        process.env.PUBLIC_URL +
        PATH.SITE_DETAIL +
        params.siteId +
        '&type=CAMPAIGN';
    }
  }, [params.siteId]);

  useEffect(() => {
    if (!lang) {
      return;
    }
    const newParams = { siteId: params.siteId };
    post(
      API_URLS.SITE,
      newParams,
      (payload) => {
        if (payload.name) {
          document.title = payload.name;
        }
        setQueueReadOnly(payload.queueReadOnly);
        dispatch(setQueueConfig(undefined));
        dispatch(resetTicket());
        dispatch(setSite(payload));
        dispatch(
          setTagSequenceList({
            siteId: params.siteId,
            tagSequenceList: payload.tagList ? payload.tagList : [],
          })
        );
        setCurrentTimeSectionId(payload.currentTimeSectionId)
        const tempCounterTagList = payload.counterTagList
          ? payload.counterTagList
          : [];
        dispatch(
          setCounterTagList({
            siteId: params.siteId,
            counterTagList: tempCounterTagList,
            tagList: payload.tagList
          })
        );
        dispatch(
          setCounterList(
            payload.counterList
              ? payload.counterList.map((counter) => {
                  const tagList = tempCounterTagList
                    .filter((tag) => tag.counterName === counter)
                    .sort(
                      (tagA, tabB) =>
                        tabB.updateTimestamp - tagA.updateTimestamp
                    );
                  const tempCounter = { counterName: counter };
                  if (tagList && tagList[0]) {
                    tempCounter.tagSequence = {
                      tableType: tagList[0].tableType,
                      tagLabel: getTagLabel(
                        tagList[0].tableType,
                        tagList[0].tagNumber,
                        tagList[0].tagNumber
                      ),
                      counterName: tagList[0].counterName,
                    };
                    tempCounter.updateTimestamp = tagList[0].updateTimestamp;
                  }
                  return tempCounter;
                })
              : []
          )
        );
        if (payload.webRejectReasonDto) {
          if (payload.webRejectReasonDto.rejectReason) {
            dispatch(
              setRejectReasonMessage(payload.webRejectReasonDto.rejectReason)
            );
            setShowQueueServiceTempUnavailable(false);
          }
        } else {
          dispatch(setRejectReasonMessage(''));
          setShowQueueServiceTempUnavailable(false);
        }
        payload.tagList?.forEach((tagSequence) => {
          dispatch(
            subscribeMqttTopic(
              [
                MQTT.VERSION,
                params.siteId,
                MQTT.CHANNEL,
                MQTT.QUEUE,
                tagSequence.timeSectionId,
                tagSequence.tableType,
              ].join('/')
            )
          );
        });
        setLoading(false);
      },
      undefined,
      store
    );
  }, [params.siteId, dispatch, store, lang]);

  function handleQueueClick() {
    if (site.firstStep && !rejectReasonMessage) {
      dispatch(setRequestParams(QUEUE_REQUEST_PARAMS));
      dispatch(setRequestTicketSuccessfully(false));
      history.push(
        '/' +
          params.language +
          getQueuePageByStep(site.firstStep) +
          params.siteId
      );
    }
  }

  // function handleExpandTagSequenceListClick() {
  //   setExpandTagSequenceList(!expandTagSequenceList);
  // }

  function handleNavigationClick(navigation) {
    if (!rejectReasonMessage) {
      switch (navigation) {
        case NAVIGATION.PRE_REGISTRATION:
          handleQueueClick();
          break;
        case NAVIGATION.MOBILE_QUEUE:
          window.location =
            'https://www.thegulu.com/download.html?redirect_rest=restUrlId=' +
            params.siteId +
            ' &from=web&action=QUEUE';
          break;
        default:
      }
    }
  }
  return (
    <LanguageWrapper>
      {site && !loading && (
        <>
          <>
            <div className="site-detail-wrapper">
              <Helmet>
                <title>{site.name}</title>
                <meta name="description" content={site.name} />
                <meta property="og:title" content={site.name} />
                <meta property="og:description" content={site.name} />
              </Helmet>
              <Header
                site={site}
                // showGuluLogo={!token && showElementByTheme(ELEMENT.GULU_LOGO, theme)}
                showPoweredBy={true}
                showLanguageButtons={true}
                showSiteInfoGuluLogo={false}
                // showSiteInfoLanguageButtons={!token}
              />
              <div className="background-site-info-body">
                {rejectReasonMessage && (
                  <div className="reject-reason">{rejectReasonMessage}</div>
                )}
                {showElementByTheme(
                  ELEMENT.SITE_DETAIL_NAVIGATION_LIST_TOP,
                  theme
                ) &&
                  !params.ticketId && (
                    <div className="navigation-list">
                      {site.firstStep && (
                        <div
                          className={`navigation-button pre-reg ${
                            rejectReasonMessage ? 'disable' : ''
                          }`}
                          onClick={() => {
                            handleNavigationClick(NAVIGATION.PRE_REGISTRATION);
                          }}
                        >
                          {showElementByTheme(
                            ELEMENT.SITE_DETAIL_NAV_PRE_REG_ICON,
                            theme
                          ) && <CalendarIcon className="image pre-reg" />}
                          {showElementByTheme(
                            ELEMENT.SITE_DETAIL_NAV_QRCODE_ICON,
                            theme
                          ) && <QrcodeTicketIcon className="image qrcode" />}
                          <span>
                            {getStringByTheme('preRegistration', lang, theme)}
                          </span>
                        </div>
                      )}
                      {showElementByTheme(
                        ELEMENT.SITE_DETAIL_MOBILE_QUEUE,
                        theme
                      ) &&
                        site.mobileQueueAvailable && (
                          <div
                            className={`navigation-button request-ticket ${
                              rejectReasonMessage ? 'disable' : ''
                            }`}
                            onClick={() => {
                              handleNavigationClick(NAVIGATION.MOBILE_QUEUE);
                            }}
                          >
                            {/* <QrcodeTicketIcon className='image' /> */}
                            <img
                              className="image"
                              src={
                                process.env.PUBLIC_URL + '/images/gulu-logo.png'
                              }
                              alt="thegulu"
                            />
                            <span>
                              {getStringByTheme(
                                'mobileRequestTicket',
                                lang,
                                theme
                              )}
                            </span>
                          </div>
                        )}
                      {
                        // rejectReasonMessage &&
                        <div className="gray"></div>
                      }
                    </div>
                  )}
                {showElementByTheme(
                  ELEMENT.SITE_DETAIL_COUNTER_TAG_LIST,
                  theme,
                  params.siteId
                ) && (
                  <div className="background-site-info tag-sequence-list-wrapper">
                    <div className="title">
                      {getStringByTheme('currentTagSequence', lang, theme)}
                    </div>
                    {site.counterTagType === COUNTER_TAG_TYPE.TAG_TO_COUNTER &&
                      counterTagList &&
                      counterTagList.length > 0 && (
                        <div className="counter-tag-list">
                          {counterTagList.map((counterTag, counterTagKey) => {
                            return (
                              <TagSequence
                                key={counterTagKey}
                                tagSequence={counterTag}
                                latestTag={latestTag}
                              />
                            );
                          })}
                        </div>
                      )}
                    {site.counterTagType === COUNTER_TAG_TYPE.COUNTER_TO_TAG &&
                      counterList &&
                      counterList.length > 0 && (
                        <div className="counter-list">
                          {counterList.map((counter, counterKey) => {
                            return (
                              <Counter
                                key={counterKey}
                                counter={counter}
                                latestTag={latestTag}
                              />
                            );
                          })}
                        </div>
                      )}
                  </div>
                )}
                {showElementByTheme(
                  ELEMENT.SITE_DETAIL_TAG_SEQUENCE_LIST,
                  theme,
                  params.siteId
                ) &&
                  tagSequenceList &&
                  tagSequenceList.length > 0 && (
                    <div className="background-site-info tag-sequence-list-wrapper">
                      <div className="current-date">
                        {moment().format('YYYY-MM-DD dddd')}
                      </div>
                      {showElementByTheme(
                        ELEMENT.SITE_DETAIL_TAG_LIST_TITLE,
                        theme
                      ) && (
                        <div className="title">
                          {getStringByTheme('currentTagSequence', lang, theme)}
                        </div>
                      )}
                      <div className="tag-sequence-list">
                        {
                          // ((expandTagSequenceList) ? tagSequenceList : _.take(tagSequenceList, 3)).map((tagSequence, tagSequenceKey) => {
                          tagSequenceList.filter((tagSequence)=>tagSequence.timeSectionId === currentTimeSectionId).map((tagSequence, tagSequenceKey) => {
                            return (
                              <TagSequence
                                key={`${tagSequence.updateTimestamp}-${tagSequence.ticketUpdateTimestamp}-${tagSequenceKey}`}
                                tagSequence={tagSequence}
                                showQueueServiceTempUnavailable={
                                  showQueueServiceTempUnavailable
                                }
                              />
                            );
                          })
                        }
                      </div>
                      {/* {
                  tagSequenceList.length > 3 &&
                  <div className={"expand-button " + ((expandTagSequenceList) ? 'collapse' : 'expand')} onClick={handleExpandTagSequenceListClick}>
                    <ArrowIcon className={"drop-down-image " + ((expandTagSequenceList) ? "change" : "")} />
                    <span>{getStringByTheme((expandTagSequenceList) ? 'collapse' : 'expand', lang, theme)}</span>
                  </div>
                } */}
                    </div>
                  )}
                {showElementByTheme(ELEMENT.SITE_DETAIL_QUEUE_TITLE, theme) && (
                  <QueueTitle title={site.title} />
                )}
                {showElementByTheme(ELEMENT.SITE_DETAIL_BANNER_LIST, theme) && (
                  <BannerList bannerList={site.bannerList} displayUnderLine />
                )}
                {showElementByTheme(
                  ELEMENT.SITE_DETAIL_NAVIGATION_LIST_BOTTOM,
                  theme
                ) &&
                  !params.ticketId && (
                    <div className="navigation-list">
                      {site.firstStep && (
                        <div
                          className={`navigation-button pre-reg ${
                            rejectReasonMessage ? 'disable' : ''
                          }`}
                          onClick={() => {
                            handleNavigationClick(NAVIGATION.PRE_REGISTRATION);
                          }}
                        >
                          {showElementByTheme(
                            ELEMENT.SITE_DETAIL_NAV_PRE_REG_ICON,
                            theme
                          ) && <CalendarIcon className="image pre-reg" />}
                          {showElementByTheme(
                            ELEMENT.SITE_DETAIL_NAV_QRCODE_ICON,
                            theme
                          ) && <QrcodeTicketIcon className="image qrcode" />}
                          <span>
                            {getStringByTheme('preRegistration', lang, theme)}
                          </span>
                        </div>
                      )}
                      {showElementByTheme(
                        ELEMENT.SITE_DETAIL_MOBILE_QUEUE,
                        theme
                      ) &&
                        site.mobileQueueAvailable && (
                          <div
                            className={`navigation-button request-ticket ${
                              rejectReasonMessage ? 'disable' : ''
                            }`}
                            onClick={() => {
                              handleNavigationClick(NAVIGATION.MOBILE_QUEUE);
                            }}
                          >
                            {/* <QrcodeTicketIcon className='image' /> */}
                            <img
                              className="image"
                              src={
                                process.env.PUBLIC_URL + '/images/gulu-logo.png'
                              }
                              alt="thegulu"
                            />
                            <span>
                              {getStringByTheme(
                                'mobileRequestTicket',
                                lang,
                                theme
                              )}
                            </span>
                          </div>
                        )}
                    </div>
                  )}
                {showElementByTheme(ELEMENT.SITE_DETAIL_DETAIL_BOX, theme) &&
                  site.descriptionList &&
                  site.descriptionList.map((description, index) => {
                    return (
                      <div key={index} className={`detail-box`}>
                        <div
                          className="detail-box-header"
                          style={{ whiteSpace: 'pre' }}
                        >
                          {description.TITLE}
                        </div>
                        <div
                          className="detail-box-description"
                          dangerouslySetInnerHTML={{
                            __html: description.CONTENT,
                          }}
                        ></div>
                      </div>
                    );
                  })}
                <div className="detail-wrapper">
                  {showElementByTheme(
                    ELEMENT.SITE_DETAIL_DESCRIPTION_LIST,
                    theme
                  ) &&
                    site.descriptionList &&
                    site.descriptionList.map((description, index) => {
                      return (
                        <DropDown
                          key={index}
                          titleClass="ticket-title"
                          title={description.TITLE}
                          content={description.CONTENT}
                          location={PATH_LOCATION.SITE_DETAIL}
                        />
                      );
                    })}
                </div>
              </div>
              <Header showGuluLogo />
            </div>
            {(showElementByTheme(ELEMENT.SITE_DETAIL_BOTTOM_BUTTON, theme) && !queueReadOnly) && (
              <div className="bottom-btn-wrapper">
                <div
                  className={`queue-button ${
                    !site.firstStep || rejectReasonMessage ? 'disable' : ''
                  }`}
                  onClick={handleQueueClick}
                >
                  {showElementByTheme(
                    ELEMENT.SITE_DETAIL_BOTTOM_BUTTON_ICON,
                    theme
                  ) && <GetTicket className="bottom-btn-icon-svg" />}
                  {getStringByTheme('requestTicket', lang, theme)}
                </div>
              </div>
            )}
          </>
        </>
      )}
    </LanguageWrapper>
  );
};

export default Restaurant;
