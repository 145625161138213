import React, { useEffect } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Helmet } from 'react-helmet';

import { API_URLS } from '../constants/apiUrls';
import { setFolder } from '../reducers/siteListSlice';
import { setLang, setLoading } from '../reducers/systemSlice';
import { post } from '../utils/baseFetch';
import {
  getStringByTheme,
  getTableColorByIndex,
  showElementByTheme,
} from '../utils/utils';
import { PATH } from '../constants/paths';
import { ELEMENT, LANG } from '../constants/constants';

import { GuluLogo } from '../components/GuluLogo';
import { SiteInfo } from '../components/SiteInfo';
import { BannerList } from '../components/BannerList';
import { QueueTitle } from '../components/QueueTitle';
import { LanguageWrapper } from '../components/LanguageWrapper';

import { ReactComponent as MobileTicketIcon } from '../images/mobile-ticket.svg';

import '../styles/site-list.scss';
import moment from 'moment';

const SiteList = () => {

  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();
  const { url } = useRouteMatch();

  const folder = useSelector((state) => state.siteList.folder);
  const lang = useSelector((state) => state.system.lang);
  const token = useSelector((state) => state.system.token);
  const theme = useSelector((state) => state.system.theme);


  function handleLangClick(newLang) {
    dispatch(setLoading(true));
    switch (newLang) {
      case LANG.EN:
        moment.locale('en');
        dispatch(setLang(LANG.EN));
        redirectToNewLang(LANG.EN);
        break;
      case LANG.SC:
        moment.locale('zb-cn');
        redirectToNewLang(LANG.SC);
        dispatch(setLang(LANG.SC));
        break;
      default:
        moment.locale('zh-hk');
        redirectToNewLang(LANG.TC);
        dispatch(setLang(LANG.TC));
    }
  }

  const redirectToNewLang = (newLang) => {
    const newPath = getRedirectLangPath(newLang);
    history.push(newPath);
  };

  const getRedirectLangPath = (newLang) => {
    const currentLang = params.language;
    const redirectPath = url.replace(`/${currentLang}`, `/${newLang}`);
    return redirectPath;
  };

  useEffect(() => {

    if (!lang) {
      return
    }

    const newParams = { folderCode: params.folderCode };
    post(
      API_URLS.SITE_LIST + params.folderCode,
      newParams,
      (payload) => {
        dispatch(setFolder(payload));
      },
      undefined,
      store
    );
  }, [params.siteId, dispatch, store, lang]);

  function handleSiteClick(site) {
    history.push(`/${lang}` + PATH.SITE_DETAIL + site.siteId);
  }

  return (
    <LanguageWrapper>
      {
        folder &&
        <>
        <div className="site-list-wrapper">
          <Helmet>
            <title>{folder.name}</title>
            <meta name="description" content={folder.name} />
            <meta property="og:title" content={folder.name} />
            <meta property="og:description" content={folder.name} />
          </Helmet>
          <div className="site-list-head">
            <GuluLogo
              show={!token && showElementByTheme(ELEMENT.GULU_LOGO, theme)}
              showLang={!token}
              handleLangClick={handleLangClick}
            />
            <SiteInfo
              title={folder.name}
              show={!token}
              showLang={!token}
              handleLangClick={handleLangClick}
            />
            <BannerList bannerList={folder.bannerList} />
          </div>
          <div className="site-list-body">
            <QueueTitle title={getStringByTheme('selectSite', lang, theme)} />
            {folder.subFolderList && folder.subFolderList.length > 0 && (
              <div className="sub-folder-list">
                {folder.subFolderList.map((subFolder, subFolderIndex) => {
                  const colorClass = getTableColorByIndex(subFolderIndex);
                  return (
                    <div key={subFolderIndex} className="sub-folder">
                      <div className={`folder-name text-${colorClass}`}>
                        {subFolder.name}
                      </div>
                      <div className="site-list">
                        {subFolder.siteList &&
                          subFolder.siteList.map((site, siteIndex) => {
                            return (
                              <div
                                key={siteIndex}
                                className={`site ${colorClass}`}
                                onClick={() => {
                                  handleSiteClick(site);
                                }}
                              >
                                {showElementByTheme(
                                  ELEMENT.SITE_LIST_LOGO,
                                  theme
                                ) &&
                                  site.showIcon && (
                                    <img
                                      className="site-logo"
                                      src={site.imageUrl}
                                      alt=""
                                    />
                                  )}
                                <div className="site-info">
                                  <div className="name-wrapper">
                                    <div className="name">{site.name}</div>
                                    {site.mobileQueueAvailable && (
                                      <MobileTicketIcon className="image" />
                                    )}
                                  </div>
                                  {site.totalQuota != null &&
                                    !isNaN(Number(site.totalQuota)) &&
                                    Number(site.totalQuota) >= 0 && (
                                      <div className="quota">
                                        {'(' +
                                          getStringByTheme(
                                            'dailyQuota',
                                            lang,
                                            theme
                                          ) +
                                          ' ' +
                                          site.totalQuota +
                                          ')'}
                                      </div>
                                    )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        </>
      }
    </LanguageWrapper>
  );
};

export default SiteList;
